import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CandidateService } from 'src/app/_services/candidate.service';

@Component({
  selector: 'app-instructions',
  templateUrl: './instructions.component.html',
  styleUrls: ['./instructions.component.css']
})
export class InstructionsComponent implements OnInit {
  roomId: any;

  constructor(private router : Router,private _Activatedroute: ActivatedRoute,
     private candidateService:CandidateService) { }

  ngOnInit(): void {
    this._Activatedroute.params.subscribe(params => {
      this.roomId = params['roomId'];
     
   })
  }


  Recording(){
    this.router.navigate(['/recording',this.roomId]);
  }
}
