export class Meeting{
    options = {
        id: 'vcx_1001',
        attachMode: '',
        player: {
            'autoplay': '',
            'name': '',
            'nameDisplayMode': '',
            'frameFitMode': 'bestFit',
            'skin': 'classic',
            'class': 'player_mode',
            // 'height': '700px',
            // 'width': '700px',
            // 'minHeight': '120px',
            // 'minWidth': '160px',
            'aspectRatio': '100',
            'volume': 0,
            'media': '',
            'loader': {
                'show': true, 'url': '/img/loader.gif', 'style': 'default', 'class': ''
            },
            'backgroundImg': '/img/player-bg.gif'
        },
        toolbar: {
            'displayMode': 'auto',
            'autoDisplayTimeout': 0,
            'position': 'top',
            'skin': 'default',
            'iconset': 'default',
            'class': '',
            'buttons': {
                'play': true,
                'share': true,
                'mic': true,
                'resize': true,
                'volume': true,
                'mute': true,
                'record': true,
                'playtime': true,
                'zoom': true,
            },
            'branding': {
                'display': false,
                'clickthru': 'https://www.enablex.io',
                'target': 'new',
                'logo': 'img/enablex.png',
                'title': 'EnableX',
                'position': 'right'
            }
        }
    };
    config = {
        audio: true,
        video: true,
        data: true,
        videoSize: [320, 180, 640, 480],
        attributes: {
            name: ""
        },
  
        options: this.options
    };
   $userRecordData = {
        CandidateId: '',
        UserName: '',
        Email: '',
        RoomId: '',
        Token: '',
        StartTime: new Date(),
        EndTime:new Date(),
        RecordingURL: '',
        AssignmentId: ''
    }
  }