<section class="app-section">
  <div class="row">
    <div class="col-md-5">
      <div class="app-leftsec">
        <div class="top-logo mb-5"><a routerLink="/"><img class="tollogo" src="assets/img/tol-logo.svg" alt="Logo"></a></div>
        <div class="slider-info">
          <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide>
              <div class="item">
                <div class="sec-pic text-center"><img src="assets/img/register-slide1.png" alt="Register"></div>
                <div class="heading mb-3">Find Resources for your Project</div>
                <div class="content">
                  <p>Our IT recruitment platform offers flexibility of engagement as the clients can benefit from IT contract staff hiring for their critical projects.</p>
                </div>
              </div>
            </ng-template>  
            <ng-template carouselSlide>
              <div class="item">
                <div class="sec-pic text-center"><img src="assets/img/register-slide1.png" alt="Register"></div>
                <div class="heading mb-3">Find Resources for your Project</div>
                <div class="content">
                  <p>Our IT recruitment platform offers flexibility of engagement as the clients can benefit from IT contract staff hiring for their critical projects.</p>
                </div>
              </div>
            </ng-template>  
            <ng-template carouselSlide>
              <div class="item">
                <div class="sec-pic text-center"><img src="assets/img/register-slide1.png" alt="Register"></div>
                <div class="heading mb-3">Find Resources for your Project</div>
                <div class="content">
                  <p>Our IT recruitment platform offers flexibility of engagement as the clients can benefit from IT contract staff hiring for their critical projects.</p>
                </div>
              </div>
            </ng-template>  
          </owl-carousel-o>
        </div>
      </div>
    </div>
    <div class="col-md-7">
      <div class="app-rightsec">
        <a href="/" class="app-close"><img src="assets/img/close.svg" alt="Close"></a>
        <div class="app-form form-register">
          <form class="w-100 h-100"  [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="form-heading">Get Started Now</div>
            <div class="form-subheading">Enter your credentials to access your account</div>
            <div class="form-section mt-5">
              <div class="form-group mb-4">
                <!-- <div class="btn-radio form-check">
                  <input name="roleId" type="radio" id="client"   formControlName="roleId"  class="form-check-input" (change)="changeRole($event)" value="2">
                  <label for="client" class="form-check-label">Client</label>
                </div>
                <div class="btn-radio form-check">
                  <input name="roleId" type="radio" id="partner" formControlName="roleId" class="form-check-input" (change)="changeRole($event)" value="3"  >
                  <label for="partner" class="form-check-label">Partner</label>
                </div> -->

                <div class="btn-radio form-check">
                  <input name="roleId" type="radio" id="hireTalent"   formControlName="roleId"  class="form-check-input" (change)="changeRole($event)" value="2">
                  <label for="hireTalent" class="form-check-label">Hire Talent</label>
                </div>
                <div class="btn-radio form-check">
                  <input name="roleId" type="radio" id="deployTalent" formControlName="roleId" class="form-check-input" (change)="changeRole($event)" value="3"  >
                  <label for="deployTalent" class="form-check-label">Deploy Talent</label>
                </div> 
                <div class="btn-radio form-check">
                  <input name="roleId" type="radio" id="hireDeployTalent" formControlName="roleId" class="form-check-input" (change)="changeRole($event)" value="4"  >
                  <label for="hireDeployTalent" class="form-check-label">Hire & Deploy Talent</label>
                </div> 
               
              </div>
              <div id="first" class="inputdata activeTab">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group mb-4">
                    <mat-form-field class="field-round">
                      <mat-label>First Name</mat-label>
                      <input matInput type="text" formControlName="firstName"  class="form-control" id="fname" placeholder="Full Name"  [ngClass]="{ 'is-invalid': submitted && f['firstName'].errors }" >
                    </mat-form-field>
                     <div *ngIf="submitted && f['firstName'].errors" class="invalid-feedback">
                        <div *ngIf="f['firstName'].errors['required']">First Name is required</div>
                      </div>
                    
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group mb-4">
                      <mat-form-field class="field-round">
                      <mat-label>Last Name</mat-label>
                      <input matInput type="text" formControlName="lastName"  class="form-control" id="lname" placeholder="Last Name"  [ngClass]="{ 'is-invalid': submitted && f['lastName'].errors }" >
                    </mat-form-field>  <div *ngIf="submitted && f['lastName'].errors" class="invalid-feedback">
                        <div *ngIf="f['lastName'].errors['required']">Last Name is required</div>
                      </div>
                   </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group mb-4">
                      <mat-form-field class="field-round">
                      <mat-label>User Name</mat-label>
                      <input matInput type="text" formControlName="userName"  class="form-control" id="username" placeholder="User Name"  [ngClass]="{ 'is-invalid': submitted && f['userName'].errors }" >
                    </mat-form-field> 
                     <div *ngIf="submitted && f['userName'].errors" class="invalid-feedback">
                        <div *ngIf="f['userName'].errors['required']">User Name is required</div>
                      </div>
                  </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group mb-4">
                      <mat-form-field class="field-round">
                      <mat-label>Email</mat-label>
                      <input matInput type="email" formControlName="email"  class="form-control" id="email" placeholder="Email"  [ngClass]="{ 'is-invalid': submitted && f['email'].errors }" >
                    </mat-form-field>  <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                        <div *ngIf="f['email'].errors['required']">Email is required</div>
                        <div *ngIf="f['email'].errors['email']">Email address is invalid</div>
                      </div>
                   </div>
                  </div>     
                  <div class="col-md-6">
                    <div class="form-group mb-4">
                      <mat-form-field class="field-round">
                      <mat-label>Company Name </mat-label>
                      <input matInput type="text" id="cname" placeholder="Company Name*" formControlName="company"  [ngClass]="{ 'is-invalid': submitted && f['firstName'].errors }">
                    </mat-form-field>
                     <div *ngIf="submitted && f['company'].errors" class="invalid-feedback">
                        <div *ngIf="f['company'].errors['required']">Company Name is required</div>
                    </div>
                    </div>
                  </div>
                  <div class="col-md-6" *ngIf="role">
                    <div class="form-group mb-4">
                    <mat-form-field class="field-round">
                      <mat-label>Linked in URL *</mat-label>
                      <input matInput type="text" formControlName="linkedinProfile" id="cwebsite" placeholder="Linked in URL*">
                    </mat-form-field>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group mb-4">
                      <mat-form-field class="field-round">
                      <mat-label>Mobile number </mat-label>
                      <input matInput type="tel" id="mobileNo" placeholder="Mobile No" maxlength="10" (keypress)="validateNo($event)" formControlName="mobileNo"  [ngClass]="{ 'is-invalid': submitted && f['mobileNo'].errors }">
                    </mat-form-field>
                     <div *ngIf="submitted && f['mobileNo'].errors" class="invalid-feedback">
                        <div *ngIf="f['mobileNo'].errors['required']">Mobile No. is required</div>
                    </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group mb-4">
                    <mat-form-field class="field-round">
                      <mat-label>Select City</mat-label>
                      <mat-select required formControlName="cityId" [ngClass]="{ 'is-invalid': submitted && f['cityId'].errors }"> 
                        <mat-option>Select</mat-option>
                        <mat-option *ngFor="let city of cities" [value]="city.value">
                          {{city.text}}
                        </mat-option>
                      </mat-select>
                      <div *ngIf="submitted && f['cityId'].errors" class="invalid-feedback">
                        <div *ngIf="f['cityId'].errors['required']">City is required</div>
                      </div>
                    </mat-form-field>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <mat-form-field class="field-round mb-4">
                      <mat-label>Password</mat-label>
                      <input matInput type="password" class="form-control" formControlName="password" id="floatingPassword" placeholder="Password*"  [ngClass]="{ 'is-invalid': submitted && f['password'].errors }">
                      <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
                        <div *ngIf="f['password'].errors['required']">Password is required</div>
                      </div>
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group mb-4">
                      <mat-form-field class="field-round">
                      <mat-label>Confirm Password</mat-label>
                      <input matInput type="password" class="form-control" formControlName="confirmPassword" id="cfloatingPassword" placeholder="Confirm Password*" [ngClass]="{ 'is-invalid': submitted && f['confirmPassword'].errors }">
                      </mat-form-field> 
                      <div *ngIf="submitted && f['confirmPassword'].errors" class="invalid-feedback">
                        <div *ngIf="f['confirmPassword'].errors['required']">Confirm Password is required</div>
                        <div *ngIf="f['confirmPassword'].errors['mustMatch']">Passwords must match</div> 
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-md-12 mb-3 mt-3">
                    <div class="btn-checkbox agree-check">
                      <input name="agree" type="checkbox" id="agree" checked="checked">
                      <label for="agree" class="form-check-label"> I Agree With The <a href="#">Terms Of Use</a> And <a href="#">Privacy Policy</a></label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-link ps-0">
                      Already have an Account? <a mat-button routerLink="/login">Login</a>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="cta mb-4 text-center">
                      <button mat-button type="submit" class="btn btn-primary w-100">Register</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>