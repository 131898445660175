<section class="app-sections container">
    <div class="row">
        <div class="col-md-4" style="padding: 10px;" *ngFor="let post of data ; let i=index">

            <div class="blog-content" *ngIf="i<6 && post.yoast_head_json.og_image[0].url!=''">
                <img class="blog-img" src={{post.yoast_head_json.og_image[0].url}}>

                <div class="blog-content">
                    <h5 class="blog-head">{{post.title.rendered }}</h5>
                    <p class="blog-para" [innerHTML]="post.excerpt.rendered"></p>
                    <a class="register-btn btn btn-secondary" taget="_blank" href="{{post.link}}" target="_blank">Read
                        More</a>
                </div>
            </div>

        </div>

    </div>
</section>