import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { dateInputsHaveChanged } from '@angular/material/datepicker/datepicker-input-base';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable, map, startWith } from 'rxjs';
import { APIConfig } from 'src/app/_config/api.config';
import { ApiService } from 'src/app/_config/api.service';
import { Paging } from 'src/app/_config/paging';
import { candidates } from 'src/app/_models/candidate';
import { Search } from 'src/app/_models/candidates';
import { jobs } from 'src/app/_models/jobs';
import { CandidateService } from 'src/app/_services/candidate.service';
import { MasterService } from 'src/app/_services/master.service';

@Component({
  selector: 'app-jobs-available-action',
  templateUrl: './jobs-available-action.component.html',
})
export class JobsAvailableActionComponent implements OnInit {
  jobIds: any;
  allcandidates: any;
  allcandidatesCount: number = 0;
  candidateFilter = new Search();
  filterValue?: string;
  experimence = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60]
  tableCount: number = 0;
  technologies: any;
  role: string;
  myControl = new FormControl('');
  filteredOptions!: Observable<any[]>;
  selection = new SelectionModel<candidates>(true, []);
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  length = this.allcandidatesCount;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = Paging.pageSizeOptions;
  isLoading = false;
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;
  pageEvent!: PageEvent;
  candidateCTCData: any=[];
  technologyId?: string;
  isPublic: number
  positionType = 'Contractual';
  displayedColumns = ['select_all', 'partnerName', 'candidate_name', 'designation', 'location', 'experience', 'technology'];
  dataSource = new MatTableDataSource<candidates>();

  constructor(private apiService:ApiService,
    private _mdr: MatDialogRef<JobsAvailableActionComponent>, private candidateService: CandidateService, private router: Router,
    @Inject(MAT_DIALOG_DATA) data: any, private masterService: MasterService, private toastrService: ToastrService, private sessionService: LocalStorageService
  ) {
    
    this.jobIds = data?.id;
    this.role = data?.role;
    this.isPublic = data?.isPublic
    this.positionType = data?.positionType
    if (this.positionType == 'Permanent') {
      this.displayedColumns = ['select_all', 'partnerName', 'designation', 'location', 'current_ctc', 'exp_ctc', 'notice_period'];
    }

  }
  ngOnInit() {

    this.candidateFilter = {
      pageSize: Paging.pageSize,
      pageNumber: Paging.pageNumber,
      jobId: this.jobIds,
    }

    this.technologyId = this.sessionService.retrieve("jobApplyFilter") != null ? this.sessionService.retrieve("jobApplyFilter") : "";
    if (this.technologyId == undefined || this.technologyId =='') {
      this.technologyId = "all";
    }
    this.candidateFilter.technologyId = this.technologyId;

   // this.getallCandidates();
    this.getAlltechnologies();
  }
  ngAfterViewInit() {

    this.dataSource.paginator = this.paginator;

  }
  CloseDialog() {
    this._mdr.close(false);
    this.sessionService.clear("jobApplyFilter");
  }



  private _filter(value: string): string[] {

    if (value.length == 0 &&( this.technologyId == undefined || this.technologyId =='')) {
      this.candidateFilter.technologyId = "all";

      this.getallCandidates();
    }
    const filterValue = value.toLowerCase();
    return this.technologies.filter((option: any) => option.text.toLowerCase().includes(filterValue));
  }
  public applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
  }

  handlePageEvent(e: PageEvent) {

    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;

    this.candidateFilter.pageSize = this.pageSize;
    this.candidateFilter.pageNumber = this.pageIndex + 1;

    this.getallCandidates();
  }


  flterChange() {
    this.candidateFilter.pageSize = 10;
    this.candidateFilter.pageNumber = 1;
    this.pageIndex = 0;
    this.getallCandidates();

  }
  getAlltechnologies() {
    this.masterService.getTechnologies().subscribe(result => {
      if (result && result.Data != null) {
        this.technologies = result.Data;
        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(''),
          map((value: any) => this._filter(value || '')),
        );
      }
      if (this.technologyId != null) {
        var indexValue = this.technologies.findIndex((x: any) => x.value == this.technologyId);

        this.myControl.setValue(this.technologies[indexValue]?.text);
        this.getallCandidates();
      }
    })
  }

  getallCandidates() {
    this.isLoading = true;
    this.candidateService.getAll(this.candidateFilter).subscribe(result => {
      if (result && result.data != null) {
        this.isLoading = false;
        this.allcandidates = result.data;
        this.allcandidatesCount = result.totalCount;
        this.tableCount = this.allcandidatesCount;
        this.length = this.tableCount;
        this.dataSource = new MatTableDataSource<candidates>(this.allcandidates);
        if (this.filterValue != null) {
          this.dataSource.filter = this.filterValue.trim().toLowerCase();
        }
      }
      else
        this.isLoading = false;
    },err=>this.isLoading = false)
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;

  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
  onChange(key, e: any, data) {
    
    let obj = { candidateId: data.id, currentCtc: '', expectedCtc: '', noticePeriod: '' }
    if (key == 'ctc') {
      obj.currentCtc = e.currentTarget.value;
    }
    if (key == 'ectc') {
      obj.expectedCtc = e.currentTarget.value;
    }
    if (key == 'np') {
      obj.noticePeriod = e.currentTarget.value;
    }
    if (this.candidateCTCData.length == 0) {
      this.candidateCTCData.push(obj);
    } 
    else {
      let index = this.candidateCTCData.findIndex(x=>x.candidateId==data.id);
      if (index < 0) {
        this.candidateCTCData.push(obj);
      } else {
        if (key == 'ctc') {
          this.candidateCTCData[index].currentCtc = e.currentTarget.value;
        }
        if (key == 'ectc') {
          this.candidateCTCData[index].expectedCtc = e.currentTarget.value;
        }
        if (key == 'np') {
          this.candidateCTCData[index].noticePeriod = e.currentTarget.value;
        }
      }
    }


  }
  updateCTC(obj){
    this.apiService._post(APIConfig.UpdateCTC,obj).subscribe((x)=>{
      
    },err=>{

    })
  }
  applyJobs() {
    this.isLoading=true;
    const condidateId: any = [];
    let ctcData:any=[];


    this.selection.selected.forEach((item: any) => {
      condidateId.push(item.id);
    });
    if(this.candidateCTCData.length>0 && this.positionType=='Permanent'){
      ctcData=this.candidateCTCData.filter(x=>condidateId.indexOf(x.candidateId)>-1);
      this.updateCTC(ctcData);
    }
    
    if (condidateId.length == 0) {
      this.isLoading=false;
      this.toastrService.error("Please select atlest one candidate");
    }
    else {
      const candidateStatus = {
        candidateIds: condidateId,
        jobId: this.jobIds,
        status: 'APL'
        //status:this.role=='executive'?'CHKAVL':(this.isPublic == 2 ? 'STC':'APL')
      }
      this.candidateService.applyJob(candidateStatus).subscribe(result => {
        if (result.StatusCode != 0) {
          this.toastrService.error(result.Message);
        }
        else {
          this.toastrService.success(result.Message);
          this._mdr.close(false);

        }
      })
    }

  }
  jobClickEvent(p: any) {

    if (p.value === undefined) {
      this.candidateFilter.technologyId = "all";
    }
    else { this.candidateFilter.technologyId = p.value }

    this.sessionService.store("jobApplyFilter", this.candidateFilter.technologyId);
    this.flterChange();
  }

  addCandidate() {
    this._mdr.close(false);
    this.router.navigate(['/partner/new-candidates', this.jobIds]);
  }
}



