import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule,NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import {MatStepperModule} from '@angular/material/stepper';
import { MatGridListModule } from '@angular/material/grid-list';


const materialModules=[
  MatInputModule,
  MatSelectModule,
  MatAutocompleteModule,
  MatButtonModule,
  MatExpansionModule,
  MatTabsModule,
  MatMenuModule,
  MatSidenavModule,
  MatDatepickerModule,
  MatTableModule,
  MatTooltipModule,
  MatCheckboxModule,
  MatPaginatorModule,
  MatDialogModule,
  MatNativeDateModule,
  MatIconModule,
  MatRadioModule ,
  MatChipsModule,
  MatProgressSpinnerModule,
  MatCardModule,
  MatSlideToggleModule,
  NgxMatDatetimePickerModule, 
  NgxMatTimepickerModule,
  NgxMatMomentModule, 
  MatStepperModule,
  MatGridListModule
]
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ...materialModules
  ],exports:[...materialModules]
})
export class MaterialModule { }
