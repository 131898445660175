import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { Meta } from '@angular/platform-browser';
import { Search } from 'src/app/_models/candidates';
import { HomeService } from 'src/app/_services/home.service';
import { RenderService } from 'src/app/_services/render.service';

@Component({
  selector: 'app-find-candidates',
  templateUrl: './find-candidates.component.html',
  styleUrls: ['./find-candidates.component.css']
})
export class FindCandidatesComponent {
  search = new Search();
  candidates: any;
  totalCandidate: any;
  showCandidate=true;
  isLoading=false;
  metaName='Hire Talented IT Candidate Online - TalentOnLease';
  constructor(private meta: Meta,private homeService: HomeService,private renderSvc: RenderService, 
    private sessionService:LocalStorageService, private router:Router) {
    this.search =  this.sessionService.retrieve('candidateFilter');
    this.getallCandidates(this.search);
      }

  ngOnInit(): void {
    this.renderSvc.addLinkTag('https://talentonlease.com/find-candidates');
    this.meta.addTag({name:  'description',content: 'Hire dedicated IT candidates on TalentOnLease. Just share your Requirments, and discover experienced IT candidates tailored for your needs. Find the best fit now!' });
    this.meta.addTag({name:"keywords",content: 'hire it developers'});
  }
  ngOnDestroy(): void {
    this.meta.removeTag('name="title"');
    this.meta.removeTag('name="description"');
    this.meta.removeTag('name="keywords"');
  }
  receivedCandidateHandler(p:any) {
    this.search = p;
    this.getallCandidates(this.search);
  }
  getallCandidates(model:any){   
    this.isLoading= true;
    this.candidates=[];
    this.search.pageNumber=1;
    this.search.pageSize=50;
    this.homeService.getCandidates(this.search).subscribe(result => {
      if(result && result.data!=null && result.data.length>0){
        this.candidates = result.data;  
        this.totalCandidate=result.totalCount;
        this.showCandidate= true;
      }
      else{
        this.totalCandidate=0;
        this.showCandidate= false;
      }
      this.isLoading= false;
    })}

    CandidateDetail(candidateId:any){
      const userDetail =  this.sessionService.retrieve('userDetail');
      if(userDetail!=null && userDetail.Data !=undefined)
      {
        this.router.navigate(['/candidateDetail',candidateId]);
      }
      else{
        this.router.navigate(['/login']);
      }
    }

}
