import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { RenderService } from 'src/app/_services/render.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent {
  metaName='About Us - TalentOnLease';
  constructor(private service: RenderService,private meta:Meta) {

  }
  ngOnInit(): void {
    this.service.addLinkTag('https://talentonlease.com/about');

    this.meta.addTag({name:'description',content: 'TalentOnLease, a top IT recruitment platform, delivers skilled developers for your business needs with trust, transparency, and precision. Hire with confidence!'});
    this.meta.addTag({name:"keywords",content: 'about talentonlease'});
  }
  ngOnDestroy(): void {
    this.meta.removeTag('name="description"');
    this.meta.removeTag('name="keywords"');
  }
}
