<section class="app-searchtoolabr pb-3">
  <div class="container">
    <div class="d-flex searchtoolbar cus-scr" style="padding-top: 23px ">
      <div class="form-group m-2">
        <mat-form-field class="full-width" appearance="fill">
          <mat-label>Search</mat-label>
          
          <input type="text"
                 placeholder="Search"
                 aria-label="Search"
                 matInput    
           [formControl]="myControl"
                 [matAutocomplete]="auto" (change)="autosearch(myControl)" (keypress)="spaceValidation($event)">
                 <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                  <mat-option *ngFor="let option of filteredOptions | async" [value]="option.text" (click)="autosearch(option)">
                    {{option.text}}
                  </mat-option>
                </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="form-group m-2">
        <mat-form-field appearance="fill">
          <mat-label>Min Exp.</mat-label>
          <mat-select [(ngModel)]="searchFilter.minExp">
            <mat-option (click)="changeValues()" *ngFor="let exp of experimence" [value]="exp" >
              {{exp}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-group m-2">
        <mat-form-field appearance="fill">
          <mat-label>Max Exp.</mat-label>
          <mat-select  [(ngModel)]="searchFilter.maxExp">
            <mat-option  (click)="changeValues()" *ngFor="let exp of experimence" [value]="exp" >
              {{exp}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-group m-2">
        <mat-form-field appearance="fill">
          <mat-label>City</mat-label>
          
            <mat-select required    [(ngModel)]="searchFilter.cityId" >
              <mat-option>All</mat-option>
              <mat-option  (click)="changeValues()" *ngFor="let city of cities" [value]="city.value">
                {{city.text}}
              </mat-option>
            </mat-select>
          
        </mat-form-field>
      </div>
      <div class="form-group m-2">
        <button mat-button class="btn btn-primary"  (click)="clickHandler()">Search</button>
      </div>
    </div>
    <div class="cus-scr d-flex usefultabs ms-2 mt-3" *ngIf="isHideTech">
      <a *ngFor="let skill of technologies"  [class.active]="skill.active === true" (click)="jobClickEvent(skill)"class="item-tab">{{skill.text}}</a>
    
    </div>
  </div>
</section>
