import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { map, Observable, startWith } from 'rxjs';
import { Search } from 'src/app/_models/candidates';
import { UserService } from 'src/app/_services/user.service';
import { SearchtoolService } from './searchtool.service';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-searchtoolbar',
  templateUrl: './searchtoolbar.component.html',
  styleUrls: ['./searchtoolbar.component.css']
})
export class SearchtoolbarComponent implements OnInit {
  cities: any;
  skills:any=[];
  technologies:any=[];
  experimence:any;
  searchFilter:Search = new Search();
  selectedSkills :any=[];
  myControl = new FormControl('');
  filteredOptions!: Observable<any[]>;
  @Output() childButtonEvent = new EventEmitter();
  @Input() hidetech;
  href: string="";
  technologyValue: any=null;;
  isHideTech=true;
  constructor(private authenticationService: UserService,private toastrService: ToastrService,private dataPass: SearchtoolService,private router : Router,private sessionService: LocalStorageService) {

      this.getAllCities();
      this.getAllPrimarySkills();
      this.getAlltechnologies()
      this.experimence=[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60      ]
      
    }

 
 

    ngOnInit() {
      
      this.isHideTech=this.hidetech==true?false:true;
      this.href = this.router.url;
      if(this.href==="/find-jobs"){
        this.searchFilter =  this.sessionService.retrieve('jobFilter');
      }
      else if(this.href==="/find-candidates"){
        this.searchFilter =  this.sessionService.retrieve('candidateFilter');
      }
      
      
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.technologies.filter((option: any) => option.text.toLowerCase().includes(filterValue));
  }
  clickHandler() {
    
    if(this.searchFilter.technologyId=="" ){
      this.toastrService.error('Please select at least one filter value');
    }
    else{
      this.childButtonEvent.emit(this.searchFilter);
    }
    
  }
  autosearch(p:any){


    this.selectedSkills=  this.selectedSkills.filter((x:any)=>x!=='');
    this.searchFilter.technologyId=this.selectedSkills.join(","); 
    if(p.value!=""){
      let searchValues =null;
      if(!isNaN(parseInt(p.value))){
        searchValues =this.technologies.find((option: any) => option.text.toLowerCase()==(p.text.toLowerCase()));
      }
      else{
        searchValues =this.technologies.find((option: any) => option.text.toLowerCase()==(p.value.toLowerCase()));
      }
   if(searchValues!=null){
      this.searchFilter.technologyId= this.searchFilter.technologyId+","+searchValues.value;  
      this.technologyValue =searchValues.value;}
      else{
        this.searchFilter.technologyId= this.searchFilter.technologyId+","+p.value; 
        this.technologyValue =p.value;
      } 
  }

  if(this.isHideTech==true)
  this.childButtonEvent.emit(this.searchFilter); 
}


spaceValidation(e:any): boolean {
  const charCode = e.which ? e.which : e.keyCode;
  if (charCode ==32) {
    return false
  }
  return true
}
  jobClickEvent(p:any){
  
     var isskillExist = this.selectedSkills.includes(p.value);
     if(isskillExist)
     {
  const indexToUpdate=    this.selectedSkills.findIndex((x: any) => x ==p.value);
     // this.dataSource.data.splice(indexToUpdate, 1);
      // const index: number = this.selectedSkills.indexOf(
      //   p.value
      // );
      // if (index !== -1) {
        this.selectedSkills.splice(indexToUpdate, 1);
        p.active = false;
      //}
     }
     else
    { p.active = true;
     this.selectedSkills.push(p.value);
    }
    this.selectedSkills=  this.selectedSkills.filter((x:any)=>x!=='');
      this.searchFilter.technologyId=this.selectedSkills.length >0?this.selectedSkills.join(","):null;
      if(this.technologyValue!=null && this.technologyValue!=undefined){
        this.searchFilter.technologyId=this.searchFilter.technologyId!=null?this.searchFilter.technologyId+","+this.technologyValue:this.technologyValue;
      }
      this.childButtonEvent.emit(this.searchFilter);
    
 
  }
  changeValues(){
    if(this.isHideTech==true)
    this.dataPass.searchJobs(this.searchFilter);
  }
  getAllCities(){   
   
    this.authenticationService.getcities(0).subscribe(result => {
      if(result && result.Data!=null){
        this.cities = result.Data;  
      }
      
    })}

    getAllPrimarySkills(){   
     
    this.authenticationService.getprimarySkills().subscribe(result => {
      if(result && result.Data!=null){
        this.skills = result.Data;       
      }     
    })
    }

    getAlltechnologies(){     
      
      this.authenticationService.getTechnologies().subscribe(result => {
        if(result && result.Data!=null){
          this.technologies = result.Data;  
          this.technologies.forEach((item:any) => {
           if( this.searchFilter.technologyId!=null){
            this.selectedSkills.push(this.searchFilter.technologyId);
          const isexist=  this.selectedSkills.includes(item.value);
          if(isexist)
             item.active = true;
          else
          item.active=false;
           }
           else
            item.active = false;
          });
          this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(''),
            map((value: any) => this._filter(value || '')),
          );
        }
       
      })
      }
}
