
<div class="container"><h1> <strong>Find Jobs </strong></h1></div>

<app-searchtoolbar (childButtonEvent)="receivedJobHandler($event)"></app-searchtoolbar>

<section class="section candidate-job-sec">
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <div class="candidate-listing column-card">
          <div class="total-candidate d-flex align-items-center">
            <h2 class="total-count"><strong>Total Jobs Found</strong> <span>{{totaljobs}}</span></h2>
           
          </div>
          <div class="clising-col cus-scr">
            <div class="lising-col" *ngFor="let job of jobs">
              <a  class="collis" [class.active]="JobDetails === job"  (click)="getJobDetails(job)">
                <div class="job-tags mb-3">
                  <span>{{job?.positionType}}</span>
                  <!-- <span>{{job?.technology}}</span> -->
                </div>
                <div class="job-desc">
                  <div class="job-detail">
                    <div class="job-title">{{job?.technology == null ? job?.jobTitle : job?.technology}}</div>
                    <div class="job-comp"></div>
                    <div class="job-loct mt-2 mb-2">
                      <span class="job-exp"><i class="fa fa-briefcase"></i>  {{job?.minExp}}-{{job?.maxExp}} Years</span>
                      <span class="job-loc"><i class="fa fa-map-marker"></i> {{job?.cityName}}</span>
                    </div>
                  </div>
                </div>
                <div class="job-expert">
                 {{job.skills}}
                </div>
              </a>
              <div class="apply-job">
                <a (click)="applyJob(job.id,job?.technologyId,job?.positionType)"  class="btn btn-outline btn-sm">Apply</a>
              </div>
            </div>
          
          
          </div>
        </div>
      </div>
      <div class="col-md-8">
        
        <div class="find-job-sec column-card" *ngIf="jobdeailShow">
          <div class="head-title relative">
            <div class="d-flex flex-wrap align-items-center">
              <h1 class="title-lg mb-2 me-4">{{JobDetails?.technology == null ? JobDetails?.jobTitle : JobDetails?.technology}}</h1>
              <div class="job-type">
                <span>{{JobDetails?.positionType}}</span>
                <!-- <span>{{JobDetails?.technology}}</span> -->
              </div>
            </div>
            <h2 class="title-sm mb-2"></h2>
            <div class="job-detail d-flex flex-wrap">
              <span class="job-exp"><img src="assets/img/job-exp.svg" alt="">{{JobDetails?.minExp}}-{{JobDetails?.maxExp}}  Years</span>
              <span class="job-loc"><img src="assets/img/job-loc.svg" alt=""> {{JobDetails?.cityName}}</span>
              <span class="job-post"><img src="assets/img/job-time.svg" alt=""> Posted : 2 Hrs. ago</span>
            </div>
            <div class="job-cta">
              <a (click)="applyJob(JobDetails.id,JobDetails?.technologyId,JobDetails?.positionType)"  class="btn btn-primary">Apply Job</a>
            </div>
          </div>
          <hr>
          <h3>Job Description:</h3>
          <p>{{JobDetails?.jobDescription}}</p>
        
          <h3>Required Skill:</h3>
          <div class="skills-sec">
            <span >{{JobDetails?.skills}}</span>
           
          </div>
          
          <hr>
          
          <div class="cta">
            <a (click)="applyJob(JobDetails.id,JobDetails?.technologyId,JobDetails?.positionType)" class="btn btn-primary">Apply Job</a>
          </div>
        </div>
        <div class="find-job-sec column-card" *ngIf="!jobdeailShow">
          No Jobs Found
          </div>

      </div>
    </div>
  </div>
</section>
