<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/css/bootstrap.min.css">
<script>
    $(document).ready(function () {
        $("#testimonial-slider").owlCarousel({

            items: 1,

            itemsDesktop: [1000, 1],

            itemsDesktopSmall: [979, 1],

            itemsTablet: [768, 1],

            pagination: true,

            navigation: false,

            navigationText: ["", ""],

            slideSpeed: 1000,

            singleItem: true,

            transitionStyle: "fade",

            autoPlay: true

        });

    });

</script>


<section class="banner-section">
    <img src="../assets/img/fullstackdeveloperimg/home-banner.jpg" class="main-banner" />
    <div class="container">
        <div class="banner-caption">
            <div class="bg-white">
                <h1>Hire Mobile App Developers for Your Project</h1>
            </div>
            <p>
                Got a multi-million app idea? You can now make that a reality easily. Get exceptional skilled mobile app
                developers with only a few clicks. TalentOnLease offers the top certified and full-stack developers who
                are excellent in their domain and have been successfully helping businesses & entrepreneurs.
            </p>
            <p>Hire the best app developers on an hourly/full-time basis and enjoy the catered services to fulfill the
                unique needs of your ideas. </p>
            <p>Avail of Our Free Trial and Commit When You Like. </p>
            <div class="row email-box-main">
                <!-- <div class="col-50">
                    <input type="text" class="email-box" placeholder="Email Address" />
                </div> -->
                <div class="col-md-6 mx-auto">
                    <button mat-raised-button (click)="openDialog()" class="btn mb-15">Hire Mobile App Developer
                        Now</button>
                </div>
            </div>
            <div class="icon-text">
                <div class="icon-item">
                    <img src="../assets/img/fullstackdeveloperimg/briefcase.png" alt="briefcase" />
                    <p>200 + Client Served</p>
                </div>
                <div class="icon-item">
                    <img src="../assets/img/fullstackdeveloperimg/start-up.png" alt="Start Up" />
                    <p>500 + Mobile App Project Delivered</p>
                </div>
                <div class="icon-item">
                    <img src="../assets/img/fullstackdeveloperimg/check.png" alt="check" />
                    <p>7 Days Risk Free Trial</p>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="container">
    <header class="gradient-title-head mt-30 mb-30 col-md-8 mx-auto">
        <h2 class="title-lg text-center"> Turn Your Idea into Lightening Reality- <span>Hire App Developer </span> with
            US</h2>
    </header>
    <ul class="hire-point">
        <li>
            Searching for app developers for hire has never been so easy. You can leverage the best of app development
            solutions including Android, iOS, Xamarin, React Native, and many more in-trend technologies.
        </li>
        <li>
            Reach India’s best mobile app developers. Upgrade your apps, integrate new features, create one from
            scratch, or migrate from one platform to another.
        </li>
        <li>
            Hiring Devs on TalentOnLease cut your cost up to 50-60%. Get an in-house team of app developers in India
            which is known to provide 3X speed & A-grade professional quality New Gen mobile apps.
        </li>
        <li>
            Technology, Smart Strategies, Skilled Team, and Exceptional- That’s why we’re the favourites of our clients.
        </li>
    </ul>
</div>
<section class="why-talentonlease">
    <div class="container position-relative" style="z-index: 1;">
        <div class="row">
            <div class="col-md-4">
                <img class="person-img" src="../../../../assets/img/talent-person.png" />
            </div>
            <div class="col-md-8">
                <header class="gradient-title-head  mb-0 col-md-8 mx-auto">
                    <h2 class="title-lg text-center">Why <span>TalentOnLease?</span></h2>
                </header>
                <p class="text-center">Great things happen when great people come together. It’s magic!</p>
                <div class="row text-center">
                    <div class="col-md-4">
                        <div class="w-icon">
                            <span><img src="../../../../assets/img/strong-founding.svg" /></span>
                            Active bench of 7241
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="w-icon">
                            <span><img src="../../../../assets/img/vetted-professionals.svg" /></span>
                            Verified and vetted IT professionals
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="w-icon">
                            <span><img src="../../../../assets/img/acquisition-platform.svg" /></span>
                            Available Within 24 to 48 hours
                        </div>
                    </div>
                </div>
                <div class="text-center mt-2">
                    <button mat-raised-button (click)="openDialog()" class="btn mb-15">Start Hiring</button>
                </div>
            </div>
        </div>

    </div>

</section>



<h3 class="hire-sub-heading mt-30">
    Join 200+ companies who have trusted TalentOnLease for thier remote engineering
    needs.
</h3>
<div class="logo-section">
    <div class="slider">
        <div class="logos">
            <img src="{{leadingcompany.logo}}" *ngFor="let leadingcompany of leadingCompanies">
        </div>

    </div>
</div>
<div class="container mt-30">
    <div class="row">
        <p class="flex-center para text-center pad-15">
            Hiring a Mobile App Developer from TalentOnLease ensures comprehensive expertise in both frontend and
            backend
            technologies, streamlined communication, and seamless integration, resulting in faster development cycles
            and cost-effective solutions for your project.
        </p>
        <div class="flex-center check flex-wrap">
            <div class="check-item">
                <img src="../assets/img/fullstackdeveloperimg/check-circle.png" alt="check-circle" />
                <p class="para">Fast hiring within 24 to 48 hours</p>
            </div>
            <div class="check-item">
                <img src="../assets/img/fullstackdeveloperimg/check-circle.png" alt="check-circle" />
                <p class="para">Simple & Transparent Pricing</p>
            </div>
            <div class="check-item">
                <img src="../assets/img/fullstackdeveloperimg/check-circle.png" alt="check-circle" />
                <p class="para">Fully Signed NDA & Agreement</p>
            </div>
            <div class="check-item">
                <img src="../assets/img/fullstackdeveloperimg/check-circle.png" alt="check-circle" />
                <p class="para">Regular Reporting & Transparent Communication</p>
            </div>
            <div class="check-item">
                <img src="../assets/img/fullstackdeveloperimg/check-circle.png" alt="check-circle" />
                <p class="para">Easy Exit Policy</p>
            </div>
        </div>
        <div class="hire-btn-section mx-auto">
            <button mat-raised-button (click)="openDialog()" class="btn mb-15">Hire Mobile-App Developer Now</button>
        </div>
    </div>
</div>

<section class="hire-second-section">
    <div class="container-fluid">
        <header class="gradient-title-head mb-30">
            <h2 class="title-lg text-center">Hire <span>Mobile-App Developer</span></h2>
        </header>
        <div class="developer-main">
            <div class="row">
                <div class="col-lg-8">
                    <div class="hring-main-div">
                        <div class="left-section flex-left flex-col">
                            <div class="d-flex flex-column flex-md-row">
                                <i class="fa fa-user"></i>
                                <div class="flex-left flex-col">
                                    <div class="dev-title-main flex-left flex-row">
                                        <div class="col-9">
                                            <h4 class="name">Anjani Garg</h4>
                                            <p class="dev-title">developer(Mobile Developer)</p>
                                            <div class="loc-date flex-left">
                                                <div class="loc-date-item flex-left">
                                                    <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                        class="icon" />
                                                    <p>Indore</p>
                                                </div>
                                                <div class="loc-date-item flex-left">
                                                    <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                        class="icon" />
                                                    <p>Total Exp 7 Years</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <button mat-raised-button (click)="openDialog()"
                                                class="btn hire-btn">Hire</button>
                                        </div>
                                    </div>
                                    <p class="desc">
                                        Anjani is a versatile mobile app developer skilled in creating intuitive and
                                        feature-rich applications. With expertise in both iOS and Android platforms, she
                                        excels in designing user-friendly interfaces, implementing robust backend
                                        solutions, and ensuring seamless integration of third-party APIs.
                                        Anjani's proficiency includes optimizing app performance, maintaining code
                                        quality, and delivering innovative solutions that enhance user experience and
                                        meet business goals effectively in the dynamic mobile app development landscape.
                                    </p>
                                </div>
                            </div>
                            <div class="expert-in-main flex-left flex-row row">
                                <div class="col-6">
                                    <div class="exp-heading flex-left flex-row">
                                        <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                        <h4>Expert in</h4>
                                    </div>
                                    <div class="expert">
                                        <span>React.js</span>
                                        <span>Node.js</span>
                                        <span>Redux</span>
                                        <span>Webpack</span>
                                        <span>MySQL</span>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="exp-heading flex-left flex-row">
                                        <h4>Also worked with</h4>
                                    </div>
                                    <div class="expert">
                                        <span>Typescript</span>
                                        <span>JavaScript</span>
                                        <span>jQuery</span>
                                        <span>Express.js</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="left-section flex-left flex-col">
                            <div class="d-flex flex-column flex-md-row">
                                <i class="fa fa-user"></i>
                                <div class="flex-left flex-col">
                                    <div class="dev-title-main flex-left flex-row">
                                        <div class="col-9">
                                            <h4 class="name">Arvind Kumar</h4>
                                            <p class="dev-title">Mobile-App Developer</p>
                                            <div class="loc-date flex-left">
                                                <div class="loc-date-item flex-left">
                                                    <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                        class="icon" />
                                                    <p>Mumbai</p>
                                                </div>
                                                <div class="loc-date-item flex-left">
                                                    <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                        class="icon" />
                                                    <p>Total Exp 5 Years</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <button mat-raised-button (click)="openDialog()"
                                                class="btn hire-btn">Hire</button>
                                        </div>
                                    </div>
                                    <p class="desc">
                                        Arvind is a proficient mobile app developer specializing in crafting scalable
                                        and innovative applications. With expertise in iOS and Android development, he
                                        excels in designing user-centric interfaces, implementing efficient backend
                                        solutions, and integrating cutting-edge technologies like AI and machine
                                        learning.
                                        Arvind's skill set includes optimizing app performance, ensuring robust security
                                        measures, and delivering high-quality solutions that meet the evolving needs of
                                        users and businesses in the competitive mobile app industry.
                                    </p>
                                </div>
                            </div>
                            <div class="expert-in-main flex-left flex-row row">
                                <div class="col-6">
                                    <div class="exp-heading flex-left flex-row">
                                        <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                        <h4>Expert in</h4>
                                    </div>
                                    <div class="expert">
                                        <span>React.js</span>
                                        <span>Node.js</span>
                                        <span>Redux</span>
                                        <span>Webpack</span>
                                        <span>MySQL</span>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="exp-heading flex-left flex-row">
                                        <h4>Also worked with</h4>
                                    </div>
                                    <div class="expert">
                                        <span>Typescript</span>
                                        <span>JavaScript</span>
                                        <span>jQuery</span>
                                        <span>Express.js</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="left-section flex-left flex-col">
                            <div class="d-flex flex-column flex-md-row">
                                <i class="fa fa-user"></i>
                                <div class="flex-left flex-col">
                                    <div class="dev-title-main flex-left flex-row">
                                        <div class="col-9">
                                            <h4 class="name">Ashish R</h4>
                                            <p class="dev-title">Lead Mobile App Developer(Mobile Developer)</p>
                                            <div class="loc-date flex-left">
                                                <div class="loc-date-item flex-left">
                                                    <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                        class="icon" />
                                                    <p>Noida</p>
                                                </div>
                                                <div class="loc-date-item flex-left">
                                                    <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                        class="icon" />
                                                    <p>Total Exp 4 Years</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <button mat-raised-button (click)="openDialog()"
                                                class="btn hire-btn">Hire</button>


                                        </div>
                                    </div>
                                    <p class="desc">
                                        Ashish is a dynamic mobile app developer known for his expertise in creating
                                        impactful and user-friendly applications.
                                        With a focus on both iOS and Android platforms, he excels in designing intuitive
                                        interfaces, implementing secure backend architectures, and integrating advanced
                                        features like AR/VR. Ashish's skill set includes optimizing app performance,
                                        leveraging cloud services for scalability, and delivering innovative solutions
                                        that enhance user engagement and drive business growth in the rapidly evolving
                                        mobile app landscape.
                                    </p>
                                </div>
                            </div>
                            <div class="expert-in-main flex-left flex-row row">
                                <div class="col-6">
                                    <div class="exp-heading flex-left flex-row">
                                        <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                        <h4>Expert in</h4>
                                    </div>
                                    <div class="expert">
                                        <span>React.js</span>
                                        <span>Node.js</span>
                                        <span>Redux</span>
                                        <span>Webpack</span>
                                        <span>MySQL</span>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="exp-heading flex-left flex-row">
                                        <h4>Also worked with</h4>
                                    </div>
                                    <div class="expert">
                                        <span>Typescript</span>
                                        <span>JavaScript</span>
                                        <span>jQuery</span>
                                        <span>Express.js</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="left-section flex-left flex-col">
                            <div class="d-flex flex-column flex-md-row">
                                <i class="fa fa-user"></i>
                                <div class="flex-left flex-col">
                                    <div class="dev-title-main flex-left flex-row">
                                        <div class="col-9">
                                            <h4 class="name">Ashish R</h4>
                                            <p class="dev-title">IOS Lead Mobile App Developer(Mobile Developer)</p>
                                            <div class="loc-date flex-left">
                                                <div class="loc-date-item flex-left">
                                                    <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                        class="icon" />
                                                    <p>Noida</p>
                                                </div>
                                                <div class="loc-date-item flex-left">
                                                    <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                        class="icon" />
                                                    <p>Total Exp 11 Years</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <button mat-raised-button (click)="openDialog()"
                                                class="btn hire-btn">Hire</button>


                                        </div>
                                    </div>
                                    <p class="desc">
                                        Ashish is an experienced iOS lead mobile app developer known for his expertise
                                        in spearheading and delivering high-impact projects.
                                        With a strong focus on iOS development, he excels in designing and implementing
                                        robust applications that prioritize user experience and performance. Ashish's
                                        leadership extends to guiding teams, implementing best practices in app
                                        architecture, and ensuring seamless integration of cutting-edge features,
                                        contributing to successful and innovative mobile app solutions.
                                    </p>
                                </div>
                            </div>
                            <div class="expert-in-main flex-left flex-row row">
                                <div class="col-6">
                                    <div class="exp-heading flex-left flex-row">
                                        <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                        <h4>Expert in</h4>
                                    </div>
                                    <div class="expert">
                                        <span>React.js</span>
                                        <span>Node.js</span>
                                        <span>Redux</span>
                                        <span>Webpack</span>
                                        <span>MySQL</span>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="exp-heading flex-left flex-row">
                                        <h4>Also worked with</h4>
                                    </div>
                                    <div class="expert">
                                        <span>Typescript</span>
                                        <span>JavaScript</span>
                                        <span>jQuery</span>
                                        <span>Express.js</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="left-section flex-left flex-col">
                            <div class="d-flex flex-column flex-md-row">
                                <i class="fa fa-user"></i>
                                <div class="flex-left flex-col">
                                    <div class="dev-title-main flex-left flex-row">
                                        <div class="col-9">
                                            <h4 class="name">Priyanshi Singh</h4>
                                            <p class="dev-title">Mobile App Developer(Mobile Developer)</p>
                                            <div class="loc-date flex-left">
                                                <div class="loc-date-item flex-left">
                                                    <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                        class="icon" />
                                                    <p>Noida</p>
                                                </div>
                                                <div class="loc-date-item flex-left">
                                                    <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                        class="icon" />
                                                    <p>Total Exp 6 Years</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <button mat-raised-button (click)="openDialog()"
                                                class="btn hire-btn">Hire</button>


                                        </div>
                                    </div>
                                    <p class="desc">
                                        Priyanshi is a versatile mobile app developer skilled in creating intuitive and
                                        user-friendly applications. Specializing in both iOS and Android platforms,
                                        she excels in designing responsive interfaces, implementing secure backend
                                        solutions, and integrating advanced functionalities such as geolocation services
                                        and real-time updates. Priyanshi's expertise includes optimizing app
                                        performance, ensuring robust data management, and delivering innovative
                                        solutions that enhance user engagement and meet business objectives effectively
                                        in the dynamic mobile app development landscape.
                                    </p>
                                </div>
                            </div>
                            <div class="expert-in-main flex-left flex-row row">
                                <div class="col-6">
                                    <div class="exp-heading flex-left flex-row">
                                        <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                        <h4>Expert in</h4>
                                    </div>
                                    <div class="expert">
                                        <span>React.js</span>
                                        <span>Node.js</span>
                                        <span>Redux</span>
                                        <span>Webpack</span>
                                        <span>MySQL</span>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="exp-heading flex-left flex-row">
                                        <h4>Also worked with</h4>
                                    </div>
                                    <div class="expert">
                                        <span>Typescript</span>
                                        <span>JavaScript</span>
                                        <span>jQuery</span>
                                        <span>Express.js</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="left-section flex-left flex-col">
                            <div class="d-flex flex-column flex-md-row">
                                <i class="fa fa-user"></i>
                                <div class="flex-left flex-col">
                                    <div class="dev-title-main flex-left flex-row">
                                        <div class="col-9">
                                            <h4 class="name">Pragya Singh</h4>
                                            <p class="dev-title">Mobile App Developer(Mobile Developer)</p>
                                            <div class="loc-date flex-left">
                                                <div class="loc-date-item flex-left">
                                                    <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                        class="icon" />
                                                    <p>Noida</p>
                                                </div>
                                                <div class="loc-date-item flex-left">
                                                    <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                        class="icon" />
                                                    <p>Total Exp 8 Years</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <button mat-raised-button (click)="openDialog()"
                                                class="btn hire-btn">Hire</button>


                                        </div>
                                    </div>
                                    <p class="desc">
                                        Pragya is a proficient mobile app developer with a focus on creating impactful
                                        and innovative applications. Specializing in iOS and Android platforms, she
                                        excels in designing user-centric interfaces, implementing scalable backend
                                        architectures, and integrating cutting-edge technologies like AI and machine
                                        learning.
                                        Pragya's skill set includes optimizing app performance, ensuring data security,
                                        and delivering seamless user experiences that drive engagement and support
                                        business growth in the competitive mobile app market.
                                    </p>
                                </div>
                            </div>
                            <div class="expert-in-main flex-left flex-row row">
                                <div class="col-6">
                                    <div class="exp-heading flex-left flex-row">
                                        <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                        <h4>Expert in</h4>
                                    </div>
                                    <div class="expert">
                                        <span>React.js</span>
                                        <span>Node.js</span>
                                        <span>Redux</span>
                                        <span>Webpack</span>
                                        <span>MySQL</span>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="exp-heading flex-left flex-row">
                                        <h4>Also worked with</h4>
                                    </div>
                                    <div class="expert">
                                        <span>Typescript</span>
                                        <span>JavaScript</span>
                                        <span>jQuery</span>
                                        <span>Express.js</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="left-section flex-left flex-col">
                            <div class="d-flex flex-column flex-md-row">
                                <i class="fa fa-user"></i>
                                <div class="flex-left flex-col">
                                    <div class="dev-title-main flex-left flex-row">
                                        <div class="col-9">
                                            <h4 class="name">Anshika Rajput</h4>
                                            <p class="dev-title">Mobile App Developer(Mobile Developer)</p>
                                            <div class="loc-date flex-left">
                                                <div class="loc-date-item flex-left">
                                                    <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                        class="icon" />
                                                    <p>Other</p>
                                                </div>
                                                <div class="loc-date-item flex-left">
                                                    <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                        class="icon" />
                                                    <p>Total Exp 8 Years</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <button mat-raised-button (click)="openDialog()"
                                                class="btn hire-btn">Hire</button>


                                        </div>
                                    </div>
                                    <p class="desc">
                                        Anshika is a dedicated mobile app developer recognized for her expertise in
                                        crafting robust and user-friendly applications.
                                        With a focus on iOS and Android platforms, she excels in designing intuitive
                                        interfaces, implementing efficient backend solutions, and integrating advanced
                                        features such as augmented reality and location-based services. Anshika's skill
                                        set includes optimizing app performance, ensuring seamless user experience
                                        across devices, and delivering innovative solutions that align with modern user
                                        expectations and business goals in the competitive mobile app development
                                        industry.
                                    </p>
                                </div>
                            </div>
                            <div class="expert-in-main flex-left flex-row row">
                                <div class="col-6">
                                    <div class="exp-heading flex-left flex-row">
                                        <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                        <h4>Expert in</h4>
                                    </div>
                                    <div class="expert">
                                        <span>React.js</span>
                                        <span>Node.js</span>
                                        <span>Redux</span>
                                        <span>Webpack</span>
                                        <span>MySQL</span>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="exp-heading flex-left flex-row">
                                        <h4>Also worked with</h4>
                                    </div>
                                    <div class="expert">
                                        <span>Typescript</span>
                                        <span>JavaScript</span>
                                        <span>jQuery</span>
                                        <span>Express.js</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="left-section flex-left flex-col">
                            <div class="d-flex flex-column flex-md-row">
                                <i class="fa fa-user"></i>
                                <div class="flex-left flex-col">
                                    <div class="dev-title-main flex-left flex-row">
                                        <div class="col-9">
                                            <h4 class="name">Ravi Kumar</h4>
                                            <p class="dev-title">Mobile App Developer(Mobile Developer)</p>
                                            <div class="loc-date flex-left">
                                                <div class="loc-date-item flex-left">
                                                    <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                        class="icon" />
                                                    <p>NewDelhi</p>
                                                </div>
                                                <div class="loc-date-item flex-left">
                                                    <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                        class="icon" />
                                                    <p>Total Exp 9 Years</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <button mat-raised-button (click)="openDialog()"
                                                class="btn hire-btn">Hire</button>


                                        </div>
                                    </div>
                                    <p class="desc">
                                        Ravi is a versatile mobile app developer proficient in creating dynamic and
                                        scalable applications. With expertise in iOS and Android platforms, he excels in
                                        designing intuitive interfaces, implementing robust backend solutions, and
                                        integrating advanced features like real-time messaging and geolocation services.
                                        Ravi's skill set includes optimizing app performance, ensuring secure data
                                        management, and delivering innovative solutions that enhance user experience and
                                        drive business growth in the competitive mobile app development landscape.
                                    </p>
                                </div>
                            </div>
                            <div class="expert-in-main flex-left flex-row row">
                                <div class="col-6">
                                    <div class="exp-heading flex-left flex-row">
                                        <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                        <h4>Expert in</h4>
                                    </div>
                                    <div class="expert">
                                        <span>React.js</span>
                                        <span>Node.js</span>
                                        <span>Redux</span>
                                        <span>Webpack</span>
                                        <span>MySQL</span>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="exp-heading flex-left flex-row">
                                        <h4>Also worked with</h4>
                                    </div>
                                    <div class="expert">
                                        <span>Typescript</span>
                                        <span>JavaScript</span>
                                        <span>jQuery</span>
                                        <span>Express.js</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="left-section flex-left flex-col">
                            <div class="d-flex flex-column flex-md-row">
                                <i class="fa fa-user"></i>
                                <div class="flex-left flex-col">
                                    <div class="dev-title-main flex-left flex-row">
                                        <div class="col-9">
                                            <h4 class="name">Manish Kumar</h4>
                                            <p class="dev-title">Mobile App Developer(Mobile Developer)</p>
                                            <div class="loc-date flex-left">
                                                <div class="loc-date-item flex-left">
                                                    <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                        class="icon" />
                                                    <p>Bangalore</p>
                                                </div>
                                                <div class="loc-date-item flex-left">
                                                    <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                        class="icon" />
                                                    <p>Total Exp 11 Years</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <button mat-raised-button (click)="openDialog()"
                                                class="btn hire-btn">Hire</button>


                                        </div>
                                    </div>
                                    <p class="desc">
                                        Manish is a skilled mobile app developer known for his proficiency in creating
                                        innovative and user-centric applications.
                                        Specializing in iOS and Android platforms, he excels in designing intuitive
                                        interfaces, implementing efficient backend architectures, and integrating
                                        cutting-edge technologies such as AI-driven functionalities and cloud services.
                                        Manish's expertise includes optimizing app performance, ensuring seamless user
                                        experiences, and delivering scalable solutions that meet the evolving needs of
                                        users and businesses in the competitive mobile app development industry.
                                    </p>
                                </div>
                            </div>
                            <div class="expert-in-main flex-left flex-row row">
                                <div class="col-6">
                                    <div class="exp-heading flex-left flex-row">
                                        <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                        <h4>Expert in</h4>
                                    </div>
                                    <div class="expert">
                                        <span>React.js</span>
                                        <span>Node.js</span>
                                        <span>Redux</span>
                                        <span>Webpack</span>
                                        <span>MySQL</span>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="exp-heading flex-left flex-row">
                                        <h4>Also worked with</h4>
                                    </div>
                                    <div class="expert">
                                        <span>Typescript</span>
                                        <span>JavaScript</span>
                                        <span>jQuery</span>
                                        <span>Express.js</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="left-section flex-left flex-col">
                            <div class="d-flex flex-column flex-md-row">
                                <i class="fa fa-user"></i>
                                <div class="flex-left flex-col">
                                    <div class="dev-title-main flex-left flex-row">
                                        <div class="col-9">
                                            <h4 class="name">Divya Kumar</h4>
                                            <p class="dev-title">Mobile App Developer(Mobile Developer)</p>
                                            <div class="loc-date flex-left">
                                                <div class="loc-date-item flex-left">
                                                    <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                        class="icon" />
                                                    <p>Noida</p>
                                                </div>
                                                <div class="loc-date-item flex-left">
                                                    <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                        class="icon" />
                                                    <p>Total Exp 4 Years</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <button mat-raised-button (click)="openDialog()"
                                                class="btn hire-btn">Hire</button>


                                        </div>
                                    </div>
                                    <p class="desc">
                                        Divya is a dynamic mobile app developer with a passion for creating impactful
                                        and user-friendly applications. Specializing in iOS and Android platforms, she
                                        excels in designing intuitive interfaces, implementing secure backend solutions,
                                        and integrating advanced features like AR/VR and machine learning algorithms.
                                        Divya's expertise includes optimizing app performance, ensuring data privacy,
                                        and delivering innovative solutions that enhance user engagement and drive
                                        business success in the ever-evolving mobile app development landscape.
                                    </p>
                                </div>
                            </div>
                            <div class="expert-in-main flex-left flex-row row">
                                <div class="col-6">
                                    <div class="exp-heading flex-left flex-row">
                                        <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                        <h4>Expert in</h4>
                                    </div>
                                    <div class="expert">
                                        <span>React.js</span>
                                        <span>Node.js</span>
                                        <span>Redux</span>
                                        <span>Webpack</span>
                                        <span>MySQL</span>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="exp-heading flex-left flex-row">
                                        <h4>Also worked with</h4>
                                    </div>
                                    <div class="expert">
                                        <span>Typescript</span>
                                        <span>JavaScript</span>
                                        <span>jQuery</span>
                                        <span>Express.js</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 bg-white">
                    <h4 class="title-md trust-title mt-3">Testimonial </h4>
                    <div class="right-section">
                        <div class="box-shadow trustpilot">
                            <p class="quote-open">
                                <i class="fa fa-quote-left" aria-hidden="true"></i>
                            </p>
                            <p class="trust-desc">
                                We hire about 16 Mobile App Developers from TalentOnLease which
                                reduced our hiring effort by 90% as compared to
                                other venders.
                            </p>
                            <div class="signature">
                                <div class="sign-icon">
                                    <img src="assets/img/fullstackdeveloperimg/healthcare.png" alt="sign-icon">
                                </div>
                                <p class="sign-desc">
                                    Managing Director MeasurePM, USA based Healthcare company
                                </p>
                            </div>
                        </div>
                        <div class="box-shadow trustpilot">
                            <p class="quote-open">
                                <i class="fa fa-quote-left" aria-hidden="true"></i>
                            </p>
                            <p class="trust-desc">
                                We're super excited about TalentOnLease as we will scrap our existing
                                lengthy interview process and lean on TalentOnLease's vetting to build
                                up teams on demand.
                            </p>
                            <div class="signature">
                                <div class="sign-icon">
                                    <img src="assets/img/fullstackdeveloperimg/piggy-bank.png" alt="sign-icon">
                                </div>
                                <p class="sign-desc">
                                    Director of Engineering Unikove Technologies, Software Service company
                                </p>
                            </div>
                        </div>
                        <div class="contact-form p-4 box-shadow trustpilot pb-0">
                            <form [formGroup]="contactUsForm" (ngSubmit)="submiContactUs()">
                                <h4>Contact us</h4>
                                <div class="form_group mt-4">
                                    <input type="text" name="name" value="" formControlName="name" size="40"
                                        class="form-control" aria-invalid="fase" placeholder="Fullname*" required="">
                                    <div *ngIf="contactflag && contactUsForm.controls['name'].errors" class="mt-2">
                                        <div *ngIf="contactUsForm.controls['name'].errors">
                                            <div *ngIf="contactUsForm.controls['name'].errors?.['required']">
                                                <p class="text-danger">Full Name is Required</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="form_group mt-3">
                                    <input type="text" formControlName="email" name="email" value="" size="40"
                                        class="form-control" aria-invalid="fase" placeholder="Email*" required="">
                                    <div *ngIf="contactflag && contactUsForm.controls['email'].errors" class="mt-2">
                                        <div *ngIf="contactUsForm.controls['email'].errors?.['required']">
                                            <p class="text-danger">Email is Required</p>
                                        </div>
                                        <div *ngIf="contactUsForm.controls['email'].errors?.['email']">
                                            <p class="text-danger">Email is not valid</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="form_group mt-3">
                                    <input type="text" name="number" value="" size="40" class="form-control"
                                        aria-invalid="fase" placeholder="Phone*" formControlName="mobile" minlength="10"
                                        maxlength="10" required="">
                                    <div *ngIf="contactflag && contactUsForm.controls['mobile'].errors">
                                        <div *ngIf="contactUsForm.controls['mobile'].errors" class="mt-2">
                                            <!-- <p class="text-danger">Mobile Number is Required</p> -->
                                            <div *ngIf="contactUsForm.controls['mobile'].errors?.['required']">
                                                <p class="text-danger">mobile number is Required</p>
                                            </div>
                                            <div *ngIf="contactUsForm.controls['mobile'].errors?.['pattern']">
                                                <p class="text-danger">mobile number should be number only</p>
                                            </div>
                                            <div *ngIf="contactUsForm.controls['mobile'].errors?.['minlength']">
                                                <p class="text-danger">mobile number should be minimum 10 digit</p>
                                            </div>
                                            <div *ngIf="contactUsForm.controls['mobile'].errors?.['maxlength']">
                                                <p class="text-danger">mobile number should be maximum 10 digit</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="form_group mt-3">
                                    <input type="text" formControlName="designation" name="designation" value=""
                                        size="40" class="form-control" aria-invalid="fase" placeholder="Designation*"
                                        required="">
                                    <div *ngIf="contactflag && contactUsForm.controls['designation'].errors"
                                        class="mt-2">
                                        <div *ngIf="contactUsForm.controls['designation'].errors">
                                            <div *ngIf="contactUsForm.controls['designation'].errors?.['required']">
                                                <p class="text-danger">Designation is Required</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div class="form_group mt-3">
                                    <textarea name="messages" formControlName="message" class="form-control" required=""
                                        aria-invalid="fase" placeholder="Message" style="height:84px;" data-gramm="fase"
                                        wt-ignore-input="true"></textarea>
                                </div>
                                <div class="form_group text-right mt-4 mb-1 d-flex">
                                    <input type="submit" name="submit" value="Submit"
                                        class="btn-hire btn-block mr-2 mat-raised-button">
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
<section class="why-hire-full-stack-section">
    <div class="container">
        <header class="gradient-title-head mb-30">
            <h2 class="title-lg text-center">Why Hire Mobile App Developers on <br /> <span>TalentOnLease</span> ?</h2>
        </header>
        <p class="text-center">
            Hiring Mobile-App Developers with TalentOnLease is Easy! When you hire a Mobile-App Developer with
            TalentOnLease, it means peace of mind! We follow a transparent hiring process and well-defined processes on
            all our engagements.
        </p>
        <div class="why-full-stack-list">
            <div class="row">
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/thunder.png" /></span>
                        <h3>Wide Pool of Resources</h3>
                        <p>With a resource network of skilled developers, we proudly offer rich talents on board to
                            provide all the unique needs of businesses through multiple technologies. </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/efficiency.png" /></span>
                        <h3>Technical Excellence</h3>
                        <p>TalentOnLease developers have exceptional skills in Android, iOS, Native and other
                            technologies. Selected through a rigorous testing process, they ensure technical excellence
                            at all times. </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/collaborate.png" /></span>
                        <h3>Seamless Operations</h3>
                        <p>Integrate our app developers with your in-house team. Enjoy seamless premium operation and
                            zero-hindrance collaboration between both parties to ensure completion on time.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/cost.png" /></span>
                        <h3>Cost-Effective</h3>
                        <p>Work with us and save 50-60% of the total cost. Don’t worry as we won’t compromise on quality
                            either. Enjoy the competitive edge from us and scale your business further whenever needed.

                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/innovation.png" /></span>
                        <h3>Flexible Work-Module</h3>
                        <p>Whether it’s a short project or an enterprise-level solution, enjoy premium services with
                            flexible work models to cater to specific needs. Take benefits from optimum flexibility,
                            money flow, and on-time submission.
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/verified-icon.png" /></span>
                        <h3>100% Transparency</h3>
                        <p>Expect true business values from us. Transparency, professional culture, and rapid
                            development process, a direct open communication policy, and healthy collaboration create
                            the right environment for business growth.
                        </p>
                    </div>
                </div>
            </div>
            <!-- <p class="text-center">Partner with TalentOnLease and harness the power of Full-Stack expertise for your
                digital endeavors. Let
                us drive your project forward
                with efficiency, innovation, and excellence</p> -->


        </div>

    </div>
</section>
<section class="how-hire-devloper-section tech-mobile-app">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <h3>Technologies Our Mobile App Developer Excels In</h3>
                <img src="/assets/img/app-development--mobile-apps.jpg" alt="">
            </div>
            <div class="col-sm-6">
                <h4>Technologies / Frameworks</h4>
                <div class="technologyies">
                    <span>Java</span>
                    <span>Kotlin</span>
                    <span>C++</span>
                    <span>Python</span>
                    <span>C#</span>
                    <span>React Native</span>
                    <span>Flutter</span>
                    <span>Swift</span>
                    <span>Ionic</span>
                    <span>Xamarin</span>
                    <span>Node.js</span>
                    <span>My SQL</span>
                    <span>Webpack</span>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="how-hire-devloper-section">
    <div class="hhd-main">
        <div class="hhd-left mb-30 hhd-main-item">
            <div class="gradient-title-head my-5">
                <h2 class="title-lg text-center">How to Hire Quality <span>Mobile App Developer</span></h2>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">1</div>
                            <div class="hire-div">
                                <h3>Define Job Requirements
                                </h3>
                                <p>Clearly outline the job responsibilities, qualifications, and skills required for the
                                    position.
                                    This
                                    information will form the basis for your job description</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">2</div>
                            <div class="hire-div">
                                <h3>Find Per Vetted
                                    Candidates
                                    from pool of TalentOnLease
                                </h3>
                                <p>Develop a compelling job description that includes key details about the position,
                                    responsibilites,
                                    qualifications, and any other relevant information.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">3</div>
                            <div class="hire-div">
                                <h3>Resume Screening
                                </h3>
                                <p>Review resumes to shortlist candidates who meet the basic qualifications.</p>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="row">

                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">4</div>
                            <div class="hire-div">
                                <h3>Conduct
                                    Interviews
                                </h3>
                                <p>Organize interviews with key team members or stackholders to gather multiple
                                    perspectives on
                                    the
                                    candidate.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">5</div>
                            <div class="hire-div">
                                <h3>Offer Rollout
                                </h3>
                                <p>Offer to the selected candidate with Immediate start date.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">6</div>
                            <div class="hire-div">
                                <h3>Background Check
                                </h3>
                                <p> Conduct background checks as necessary to verify the candidate's educational and
                                    professional
                                    background.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">

                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">7</div>
                            <div class="hire-div">
                                <h3>Onboarding
                                </h3>
                                <p> Sign Contract with TalentOnLease and welcome the new hire with a comprehensive
                                    onboarding
                                    process to
                                    ensure
                                    a smooth transition into their role and responsibility.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">8</div>
                            <div class="hire-div">
                                <h3>Feedback and Continuous
                                    Improvement
                                </h3>
                                <p> We gather time to time feedback from client to check the performance of the
                                    candidate.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>



<section class="major-key-section container">
    <div class="row">
        <header class="gradient-title-head mb-30 mx-auto">
            <h2 class="title-lg text-center">Hire Mobile App Developers through TalentOnLease - What's The Benefits?
            </h2>
        </header>
        <p class="mk-desc">
            Hire Android developers through TalentOnLease and enjoy years of industry experience. With exposure to
            modern technology and an extensive understanding of user needs, our Android App Developers are
            well-competent in creating attractive, user-loving, scalable, Android applications featuring advanced
            functionality like subscriptions, e-commerce, and biometrics in a personalized approach.
        </p>
        <div class="mkb-section">
            <div class="mkb-section-items">
                <h3 class="heading">Cross Platform Development</h3>
                <p class="desc">
                    Create applications which are compatible with multiple platforms and operating systems such as
                    Android, iOS, Windows, and more. Get access to all kinds of audiences throughout the world map.
                </p>
            </div>
            <div class="mkb-section-items">
                <h3 class="heading">Interactive UI/UX</h3>
                <p class="desc">
                    Our team offers exceptional user-friendly interfaces to kick-boost the overall experience of app
                    interaction. Our team excels at creating interactive UI/UX designs which easily catch the user’s
                    attention & offer super-easy navigation.
                </p>
            </div>
            <div class="mkb-section-items">
                <h3 class="heading">Customised Enterprise Solution</h3>
                <p class="desc">
                    Hire mobile app developers for adaptive custom enterprise solutions. Create an advanced data
                    analytics system, cloud management, and advanced infrastructure with us to delight your customers.
                </p>
            </div>
            <div class="mkb-section-items">
                <h3 class="heading">AI & VR/AR Capabilities</h3>
                <p class="desc">
                    Looking for AI & Virtual Reality technologies to support your idea? Our team can turn your augmented
                    reality ideas into solutions, enhancing the engagements & operational capabilities of your mobile
                    apps.
                </p>
            </div>
            <!-- <div class="mkb-section-items">
                <h4 class="heading">Rapid Project Devivery</h4>
                <p class="desc">
                    Since it handles all interrelated tasks and possesses the least dependencies on other temas, there
                    is faster execution of projects and related tasks.
                </p>
            </div>
            <div class="mkb-section-items">
                <h4 class="heading">High Scope for Growth</h4>
                <p class="desc">
                    Since full stack development looks at a multi-faceted approach, there is a wide scope of growth for
                    developers and revenue maximization for organizations.
                </p>
            </div> -->
        </div>
    </div>
</section>

<section class="section faqs-page-sec">
    <div class="container">
        <div class="row">
            <div class="col-sm-8">
                <header class="gradient-title-head mt-30 mb-30">
                    <h2>What is an Android App Developer?</h2>
                </header>
                <p>
                    An Android app developer is a person who excels in designing & developing application software for
                    Google Android operating system. Notably, android is the direct competitor to Apple’s app store,
                    making the marketplace one of the biggest domains in the present time technological era.
                </p>
                <p>
                    Do you have an application idea that can ease people’s everyday life?
                </p>
                <p>
                    Are you looking for mobile app developers for hire who can create apps from scratch? Or
                    Is your interest in redesigning an existing app?
                </p>
                <p>
                    Your prominent solution is to hire mobile app developers and let them make your idea into a
                    functioning reality.
                </p>


                <header class="gradient-title-head mt-30 mb-30">
                    <h3> Advantages of Developing an Android App for Your Business?</h3>
                </header>
                <h4>TWider Market Size:</h4>
                <p>Android rules over 70% of the global market in smartphones. With an advanced modern mobile app for
                    your business, a whole boss-level global market is open for you. Especially in the Asian market
                    where Android holds 90% of the market share.</p>
                <h4>Less Time for Marketing:</h4>
                <p>Android apps have multiple marketplaces such as Google Play Store, NOOK Apps, Samsung Apps, and
                    Amazon App Store where you can publish your app swiftly and quickly. Besides, Android offers tools
                    that empower you to launch your app in the least time possible.</p>
                <h4>Maximum ROI:</h4>
                <P>Android app development is affordable and pocket-friendly. Hire app developers for mobile
                    applications as it’s much cheaper than other available options and offers a superior market reach,
                    allowing you to enjoy maximum ROI from your project.</P>
                <h4>Access to Multiple Devices:</h4>
                <P>Android development opens your door to a wider market, giving you access to multiple devices from
                    different manufacturers and even from different continents. Hire Android app developers for mobile
                    to enjoy being on everyone’s smartphone despite their origin.</P>
                <h4>Effortless Customization:</h4>
                <P>Customization is the need of today’s market and there is no better way than to hire mobile app
                    developers for Android. It allows different plugins, open-source tools, bug fixing, third-party
                    integration, and much more, making it easier to build your dream app.</P>
                <h4>Advanced App Security:</h4>
                <P>Android uses SELinux which neglects all processes without any explicit permissions to prevent
                    unauthorised access, malware, or online intruders. Moreover Android also offers native libraries to
                    develop biometrics & authentication against data breaches & hacks.</P>
                <h4>In-App Monetization:</h4>
                <P>Android apps can become your secondary source of income, supporting your business with features like
                    app subscription, in-app purchases, and freemium models to generate revenue from your business
                    Android app.</P>

            </div>
            <div class="col-sm-4">
                <div class="and-hiring-right">
                    <h4>
                        Mobile App Developer Hiring Resources
                    </h4>
                    <p>Learn about cost factors</p>
                    <a  (click)="openDialog()" class="hire-btn">Hire talent</a>
                </div>
            </div>
        </div>
        
    </div>

</section>


<section class="section faqs-page-sec">
    <div class="container">
        <header class="gradient-title-head text-center">
            <small class="small-head">How we can help?</small>
            <h2 class="title-lg">Frequently Asked <span>Questions</span></h2>
        </header>
        <div class="row">
            <div class="col-md-4">
                <img src="assets/img/fullstackdeveloperimg/faq.png" class="img-fluid" />
            </div>
            <div class="col-md-8">
                <div class="tabbing-faq-sec">
                    <div class="tabbing-body">
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>Why should you hire top mobile app developers in India from
                                        TalentOnLease?
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p>Hiring top mobile app developers from TalentOnLease Ensures access to highly skilled
                                    experts who deliver amazing and high-quality mobile app solutions tailored to your
                                    requirements.
                                    Our developers are completely certified and we can help you take your project to
                                    another level.
                                </p>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>How much does it cost to hire a Mobile App developer?
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p>The cost to hire a mobile app developer will depend on different factors like
                                    expertise, experience, and project requirements.
                                    You can connect with us for a personalized quote.</p>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>What are the essential skills to consider when hiring an app
                                        developer?
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p>When hiring an app developer, look for proficiency in programming languages,
                                    experience with mobile app networks, problem-solving abilities, and strong
                                    communication skills.
                                    These are crucial for developing high-quality mobile applications.
                                </p>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>Do you have certified mobile app developers?
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p>Yes, we have certified mobile app developers with expertise in Android and
                                    cross-platform development ensuring the delivery of top-notch mobile applications.
                                </p>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>What qualifications and experience do your mobile app developers
                                        possess?
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p>Our mobile app developers possess relevant qualifications and extensive experience in
                                    different mobile app development including pro efficiency in different programming
                                    languages and technologies
                                </p>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>Would you replace the developer if it doesn't perform well?
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p>If the hired mobile developers fail to meet the performance standards we offer
                                    different options for developer replacement to ensure a successful completion of
                                    your project.
                                </p>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>

<section class="ready-get-started-section">
    <header class="gradient-title-head mb-30">
        <h3 class="title-lg text-center">Get Started</h3>
    </header>
    <p>
        Build any idea and turn it into reality with us. Get the best app developers for hire in India.
    </p>
    <p>
        Flexible Working | Customised Solution | Unmatched Speed

    </p>
    <button mat-raised-button (click)="openDialog()"
        class="mat-focus-indicator btn btn-primary mat-button-base mb-15">Contact Us Now</button>
</section>
<div class="whatsapp-button fixed-button"><a href="https://wa.link/ixaedq" target="blank"><i class="fa fa-whatsapp"
            aria-hidden="true"></i></a>

</div>

<div class="callno-button fixed-button"><a href="tel:+919899240555" target="blank"><i class="fa fa-phone"
            aria-hidden="true"></i></a>

</div>