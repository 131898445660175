<section class="app-section">
  <div class="row">
    <div class="col-md-5">
      <div class="app-leftsec">
        <div class="top-logo mb-5"><a routerLink="/"><img class="tollogo" [src]="logoUrl" alt="Logo"></a></div>
        <div class="slider-info">
          <div class="item">
            <div class="sec-pic text-center"><img src="assets/img/register-pic.png" alt="Register"></div>
            <div class="heading mb-3">Making available Right Resources at Right Time and at Right Cost</div>
            <div class="content">
              <p>TalentOnLease works with a wide range of partners (IT Services/Solution firms) to fulfil these
                requirements.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-7">
      <div class="app-rightsec">
        <a routerLink="/" class="app-close"><img src="assets/img/close.svg" alt="Close"></a>
        <div class="app-form">
          <div class="w-100 h-100">
            <div class="form-heading">Welcome Back!</div>
            <div class="form-subheading">Enter your credentials to Login your account</div>
            <div class="form-section mt-5">
              <div class="form-group mb-4">

                <div class="btn-radio form-check">
                  <input name="login_type" type="radio" id="loginemail" checked="checked" class="form-check-input"
                    value="loginemail" (change)="changeRole($event)">
                  <label for="loginemail" class="form-check-label">Login via Email</label>
                </div>
                <!-- <div class="btn-radio form-check">
                  <input name="login_type" type="radio" id="loginotp" class="form-check-input" value="loginotp"
                    (change)="changeRole($event)">
                  <label for="loginotp" class="form-check-label">Login via OTP</label>
                </div> -->
              </div>
              <form class="w-100 h-100" *ngIf="role&& phoneDiv" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div id="first" class="inputdata activeTab">
                  <div class="form-group mb-4">
                    <mat-form-field class="field-round">
                      <mat-label>Email ID </mat-label>
                      <input matInput type="email" formControlName="userName" placeholder="name@example.com"
                        [ngClass]="{ 'is-invalid': submitted && f['userName'].errors }" />
                    </mat-form-field>
                    <div *ngIf="submitted && f['userName'].errors" class="invalid-feedback ">
                      <div *ngIf="f['userName'].errors['required']">Email is required</div>
                      <div *ngIf="f['userName'].errors['email']">Email address is invalid</div>
                    </div>
                  </div>
                  <div class="form-group mb-4">
                    <mat-form-field class="field-round">
                      <mat-label>Password</mat-label>
                      <input matInput type="password" formControlName="password" id="floatingPassword"
                        placeholder="Password" [ngClass]="{ 'is-invalid': submitted && f['password'].errors }" />
                    </mat-form-field>
                    <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
                      <div *ngIf="f['password'].errors['required']">Password is required</div>
                    </div>
                  </div>
                  <div class="form-group mb-4" *ngIf="isOTPVerified">
                    <mat-form-field class="field-round">
                      <mat-label>One Time Password</mat-label>
                      <input #otp matInput type="password" formControlName="otp" id="floatingotp"
                        placeholder="OTP" [ngClass]="{ 'is-invalid': submitted && f['otp'].errors }" />
                    </mat-form-field>
                    <div *ngIf="submitted && f['otp'].errors" class="invalid-feedback">
                      <div *ngIf="f['otp'].errors['required']">OTP is required</div>
                    </div>
                  </div>
                  <div class="mb-3 form-link">
                    <a mat-button routerLink="/forgetPassword">Forgot Your Password?</a>
                  </div>
                  <section>
                  <div class="cta mt-5 mb-4" *ngIf="!isOTPVerified">
                    <button mat-button class="btn btn-primary w-100" style="color: white;" [disabled]="isSubmit">
                      <svg *ngIf="isSubmit" style="display: block;
                      float: left;
                      margin-top: -7px;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="55px" height="55px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                        <circle cx="75" cy="50" fill="#ffffff" r="5">
                          <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1"
                            dur="0.7194244604316546s" repeatCount="indefinite" begin="-0.6594724220623501s"></animate>
                          <animate attributeName="fill" values="#ffffff;#ffffff;#ffffff;#ffffff;#ffffff"
                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.7194244604316546s"
                            begin="-0.6594724220623501s"></animate>
                        </circle>
                        <circle cx="71.65063509461098" cy="62.5" fill="#ffffff" r="5">
                          <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1"
                            dur="0.7194244604316546s" repeatCount="indefinite" begin="-0.5995203836930456s"></animate>
                          <animate attributeName="fill" values="#ffffff;#ffffff;#ffffff;#ffffff;#ffffff"
                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.7194244604316546s"
                            begin="-0.5995203836930456s"></animate>
                        </circle>
                        <circle cx="62.5" cy="71.65063509461096" fill="#ffffff" r="5">
                          <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1"
                            dur="0.7194244604316546s" repeatCount="indefinite" begin="-0.539568345323741s"></animate>
                          <animate attributeName="fill" values="#ffffff;#ffffff;#ffffff;#ffffff;#ffffff"
                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.7194244604316546s"
                            begin="-0.539568345323741s"></animate>
                        </circle>
                        <circle cx="50" cy="75" fill="#ffffff" r="5">
                          <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1"
                            dur="0.7194244604316546s" repeatCount="indefinite" begin="-0.47961630695443647s"></animate>
                          <animate attributeName="fill" values="#ffffff;#ffffff;#ffffff;#ffffff;#ffffff"
                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.7194244604316546s"
                            begin="-0.47961630695443647s"></animate>
                        </circle>
                        <circle cx="37.50000000000001" cy="71.65063509461098" fill="#ffffff" r="5">
                          <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1"
                            dur="0.7194244604316546s" repeatCount="indefinite" begin="-0.4196642685851319s"></animate>
                          <animate attributeName="fill" values="#ffffff;#ffffff;#ffffff;#ffffff;#ffffff"
                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.7194244604316546s"
                            begin="-0.4196642685851319s"></animate>
                        </circle>
                        <circle cx="28.34936490538903" cy="62.5" fill="#ffffff" r="5">
                          <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1"
                            dur="0.7194244604316546s" repeatCount="indefinite" begin="-0.3597122302158273s"></animate>
                          <animate attributeName="fill" values="#ffffff;#ffffff;#ffffff;#ffffff;#ffffff"
                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.7194244604316546s"
                            begin="-0.3597122302158273s"></animate>
                        </circle>
                        <circle cx="25" cy="50" fill="#ffffff" r="5">
                          <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1"
                            dur="0.7194244604316546s" repeatCount="indefinite" begin="-0.2997601918465228s"></animate>
                          <animate attributeName="fill" values="#ffffff;#ffffff;#ffffff;#ffffff;#ffffff"
                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.7194244604316546s"
                            begin="-0.2997601918465228s"></animate>
                        </circle>
                        <circle cx="28.34936490538903" cy="37.50000000000001" fill="#ffffff" r="5">
                          <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1"
                            dur="0.7194244604316546s" repeatCount="indefinite" begin="-0.23980815347721823s"></animate>
                          <animate attributeName="fill" values="#ffffff;#ffffff;#ffffff;#ffffff;#ffffff"
                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.7194244604316546s"
                            begin="-0.23980815347721823s"></animate>
                        </circle>
                        <circle cx="37.499999999999986" cy="28.34936490538904" fill="#ffffff" r="5">
                          <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1"
                            dur="0.7194244604316546s" repeatCount="indefinite" begin="-0.17985611510791366s"></animate>
                          <animate attributeName="fill" values="#ffffff;#ffffff;#ffffff;#ffffff;#ffffff"
                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.7194244604316546s"
                            begin="-0.17985611510791366s"></animate>
                        </circle>
                        <circle cx="49.99999999999999" cy="25" fill="#ffffff" r="5">
                          <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1"
                            dur="0.7194244604316546s" repeatCount="indefinite" begin="-0.11990407673860912s"></animate>
                          <animate attributeName="fill" values="#ffffff;#ffffff;#ffffff;#ffffff;#ffffff"
                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.7194244604316546s"
                            begin="-0.11990407673860912s"></animate>
                        </circle>
                        <circle cx="62.5" cy="28.349364905389034" fill="#ffffff" r="5">
                          <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1"
                            dur="0.7194244604316546s" repeatCount="indefinite" begin="-0.05995203836930456s"></animate>
                          <animate attributeName="fill" values="#ffffff;#ffffff;#ffffff;#ffffff;#ffffff"
                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.7194244604316546s"
                            begin="-0.05995203836930456s"></animate>
                        </circle>
                        <circle cx="71.65063509461096" cy="37.499999999999986" fill="#ffffff" r="5">
                          <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1"
                            dur="0.7194244604316546s" repeatCount="indefinite" begin="0s"></animate>
                          <animate attributeName="fill" values="#ffffff;#ffffff;#ffffff;#ffffff;#ffffff"
                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.7194244604316546s" begin="0s">
                          </animate>
                        </circle>
                      </svg>
                      {{isSubmit?'Please wait': 'Get OTP'}}
                      </button>
                  </div>
                  <div class=" mt-5 mb-4" *ngIf="isOTPVerified">
                    <button mat-button class="btn-otp-login btn btn-primary" style="color: white;" [disabled]="isSubmit">
                      <svg *ngIf="isSubmit" style="display: block;
                      float: left;
                      margin-top: -7px;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="55px" height="55px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                        <circle cx="75" cy="50" fill="#ffffff" r="5">
                          <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1"
                            dur="0.7194244604316546s" repeatCount="indefinite" begin="-0.6594724220623501s"></animate>
                          <animate attributeName="fill" values="#ffffff;#ffffff;#ffffff;#ffffff;#ffffff"
                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.7194244604316546s"
                            begin="-0.6594724220623501s"></animate>
                        </circle>
                        <circle cx="71.65063509461098" cy="62.5" fill="#ffffff" r="5">
                          <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1"
                            dur="0.7194244604316546s" repeatCount="indefinite" begin="-0.5995203836930456s"></animate>
                          <animate attributeName="fill" values="#ffffff;#ffffff;#ffffff;#ffffff;#ffffff"
                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.7194244604316546s"
                            begin="-0.5995203836930456s"></animate>
                        </circle>
                        <circle cx="62.5" cy="71.65063509461096" fill="#ffffff" r="5">
                          <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1"
                            dur="0.7194244604316546s" repeatCount="indefinite" begin="-0.539568345323741s"></animate>
                          <animate attributeName="fill" values="#ffffff;#ffffff;#ffffff;#ffffff;#ffffff"
                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.7194244604316546s"
                            begin="-0.539568345323741s"></animate>
                        </circle>
                        <circle cx="50" cy="75" fill="#ffffff" r="5">
                          <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1"
                            dur="0.7194244604316546s" repeatCount="indefinite" begin="-0.47961630695443647s"></animate>
                          <animate attributeName="fill" values="#ffffff;#ffffff;#ffffff;#ffffff;#ffffff"
                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.7194244604316546s"
                            begin="-0.47961630695443647s"></animate>
                        </circle>
                        <circle cx="37.50000000000001" cy="71.65063509461098" fill="#ffffff" r="5">
                          <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1"
                            dur="0.7194244604316546s" repeatCount="indefinite" begin="-0.4196642685851319s"></animate>
                          <animate attributeName="fill" values="#ffffff;#ffffff;#ffffff;#ffffff;#ffffff"
                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.7194244604316546s"
                            begin="-0.4196642685851319s"></animate>
                        </circle>
                        <circle cx="28.34936490538903" cy="62.5" fill="#ffffff" r="5">
                          <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1"
                            dur="0.7194244604316546s" repeatCount="indefinite" begin="-0.3597122302158273s"></animate>
                          <animate attributeName="fill" values="#ffffff;#ffffff;#ffffff;#ffffff;#ffffff"
                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.7194244604316546s"
                            begin="-0.3597122302158273s"></animate>
                        </circle>
                        <circle cx="25" cy="50" fill="#ffffff" r="5">
                          <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1"
                            dur="0.7194244604316546s" repeatCount="indefinite" begin="-0.2997601918465228s"></animate>
                          <animate attributeName="fill" values="#ffffff;#ffffff;#ffffff;#ffffff;#ffffff"
                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.7194244604316546s"
                            begin="-0.2997601918465228s"></animate>
                        </circle>
                        <circle cx="28.34936490538903" cy="37.50000000000001" fill="#ffffff" r="5">
                          <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1"
                            dur="0.7194244604316546s" repeatCount="indefinite" begin="-0.23980815347721823s"></animate>
                          <animate attributeName="fill" values="#ffffff;#ffffff;#ffffff;#ffffff;#ffffff"
                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.7194244604316546s"
                            begin="-0.23980815347721823s"></animate>
                        </circle>
                        <circle cx="37.499999999999986" cy="28.34936490538904" fill="#ffffff" r="5">
                          <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1"
                            dur="0.7194244604316546s" repeatCount="indefinite" begin="-0.17985611510791366s"></animate>
                          <animate attributeName="fill" values="#ffffff;#ffffff;#ffffff;#ffffff;#ffffff"
                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.7194244604316546s"
                            begin="-0.17985611510791366s"></animate>
                        </circle>
                        <circle cx="49.99999999999999" cy="25" fill="#ffffff" r="5">
                          <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1"
                            dur="0.7194244604316546s" repeatCount="indefinite" begin="-0.11990407673860912s"></animate>
                          <animate attributeName="fill" values="#ffffff;#ffffff;#ffffff;#ffffff;#ffffff"
                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.7194244604316546s"
                            begin="-0.11990407673860912s"></animate>
                        </circle>
                        <circle cx="62.5" cy="28.349364905389034" fill="#ffffff" r="5">
                          <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1"
                            dur="0.7194244604316546s" repeatCount="indefinite" begin="-0.05995203836930456s"></animate>
                          <animate attributeName="fill" values="#ffffff;#ffffff;#ffffff;#ffffff;#ffffff"
                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.7194244604316546s"
                            begin="-0.05995203836930456s"></animate>
                        </circle>
                        <circle cx="71.65063509461096" cy="37.499999999999986" fill="#ffffff" r="5">
                          <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1"
                            dur="0.7194244604316546s" repeatCount="indefinite" begin="0s"></animate>
                          <animate attributeName="fill" values="#ffffff;#ffffff;#ffffff;#ffffff;#ffffff"
                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.7194244604316546s" begin="0s">
                          </animate>
                        </circle>
                      </svg>
                      {{isSubmit?'Please wait': 'Login'}}
                      </button>
                    &nbsp; &nbsp;<button type="button" (click)="resendOTP()" mat-button class="btn-otp-login btn btn-outline btn-sm" style="color: white;border: 1px solid;
                    border-color: #b8b8b8;" [disabled]="isSubmit">Resend OTP</button>
                  </div>
                </section>
                  <div class="cta mt-5 mb-4 text-center" *ngIf="false">
                    <button mat-button class="btn btn-primary w-100" style="color: white;" [disabled]="isSubmit">
                      <svg *ngIf="isSubmit" style="display: block;
                      float: left;
                      margin-top: -7px;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="55px" height="55px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                        <circle cx="75" cy="50" fill="#ffffff" r="5">
                          <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1"
                            dur="0.7194244604316546s" repeatCount="indefinite" begin="-0.6594724220623501s"></animate>
                          <animate attributeName="fill" values="#ffffff;#ffffff;#ffffff;#ffffff;#ffffff"
                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.7194244604316546s"
                            begin="-0.6594724220623501s"></animate>
                        </circle>
                        <circle cx="71.65063509461098" cy="62.5" fill="#ffffff" r="5">
                          <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1"
                            dur="0.7194244604316546s" repeatCount="indefinite" begin="-0.5995203836930456s"></animate>
                          <animate attributeName="fill" values="#ffffff;#ffffff;#ffffff;#ffffff;#ffffff"
                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.7194244604316546s"
                            begin="-0.5995203836930456s"></animate>
                        </circle>
                        <circle cx="62.5" cy="71.65063509461096" fill="#ffffff" r="5">
                          <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1"
                            dur="0.7194244604316546s" repeatCount="indefinite" begin="-0.539568345323741s"></animate>
                          <animate attributeName="fill" values="#ffffff;#ffffff;#ffffff;#ffffff;#ffffff"
                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.7194244604316546s"
                            begin="-0.539568345323741s"></animate>
                        </circle>
                        <circle cx="50" cy="75" fill="#ffffff" r="5">
                          <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1"
                            dur="0.7194244604316546s" repeatCount="indefinite" begin="-0.47961630695443647s"></animate>
                          <animate attributeName="fill" values="#ffffff;#ffffff;#ffffff;#ffffff;#ffffff"
                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.7194244604316546s"
                            begin="-0.47961630695443647s"></animate>
                        </circle>
                        <circle cx="37.50000000000001" cy="71.65063509461098" fill="#ffffff" r="5">
                          <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1"
                            dur="0.7194244604316546s" repeatCount="indefinite" begin="-0.4196642685851319s"></animate>
                          <animate attributeName="fill" values="#ffffff;#ffffff;#ffffff;#ffffff;#ffffff"
                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.7194244604316546s"
                            begin="-0.4196642685851319s"></animate>
                        </circle>
                        <circle cx="28.34936490538903" cy="62.5" fill="#ffffff" r="5">
                          <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1"
                            dur="0.7194244604316546s" repeatCount="indefinite" begin="-0.3597122302158273s"></animate>
                          <animate attributeName="fill" values="#ffffff;#ffffff;#ffffff;#ffffff;#ffffff"
                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.7194244604316546s"
                            begin="-0.3597122302158273s"></animate>
                        </circle>
                        <circle cx="25" cy="50" fill="#ffffff" r="5">
                          <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1"
                            dur="0.7194244604316546s" repeatCount="indefinite" begin="-0.2997601918465228s"></animate>
                          <animate attributeName="fill" values="#ffffff;#ffffff;#ffffff;#ffffff;#ffffff"
                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.7194244604316546s"
                            begin="-0.2997601918465228s"></animate>
                        </circle>
                        <circle cx="28.34936490538903" cy="37.50000000000001" fill="#ffffff" r="5">
                          <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1"
                            dur="0.7194244604316546s" repeatCount="indefinite" begin="-0.23980815347721823s"></animate>
                          <animate attributeName="fill" values="#ffffff;#ffffff;#ffffff;#ffffff;#ffffff"
                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.7194244604316546s"
                            begin="-0.23980815347721823s"></animate>
                        </circle>
                        <circle cx="37.499999999999986" cy="28.34936490538904" fill="#ffffff" r="5">
                          <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1"
                            dur="0.7194244604316546s" repeatCount="indefinite" begin="-0.17985611510791366s"></animate>
                          <animate attributeName="fill" values="#ffffff;#ffffff;#ffffff;#ffffff;#ffffff"
                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.7194244604316546s"
                            begin="-0.17985611510791366s"></animate>
                        </circle>
                        <circle cx="49.99999999999999" cy="25" fill="#ffffff" r="5">
                          <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1"
                            dur="0.7194244604316546s" repeatCount="indefinite" begin="-0.11990407673860912s"></animate>
                          <animate attributeName="fill" values="#ffffff;#ffffff;#ffffff;#ffffff;#ffffff"
                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.7194244604316546s"
                            begin="-0.11990407673860912s"></animate>
                        </circle>
                        <circle cx="62.5" cy="28.349364905389034" fill="#ffffff" r="5">
                          <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1"
                            dur="0.7194244604316546s" repeatCount="indefinite" begin="-0.05995203836930456s"></animate>
                          <animate attributeName="fill" values="#ffffff;#ffffff;#ffffff;#ffffff;#ffffff"
                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.7194244604316546s"
                            begin="-0.05995203836930456s"></animate>
                        </circle>
                        <circle cx="71.65063509461096" cy="37.499999999999986" fill="#ffffff" r="5">
                          <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1"
                            dur="0.7194244604316546s" repeatCount="indefinite" begin="0s"></animate>
                          <animate attributeName="fill" values="#ffffff;#ffffff;#ffffff;#ffffff;#ffffff"
                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.7194244604316546s" begin="0s">
                          </animate>
                        </circle>
                      </svg>
                      {{isSubmit?'Please wait': 'Login'}}
                    </button>
                  </div>
                  <div class="secondcta text-center" *ngIf="!signupDisabled">
                    Don't you have an Account? <a mat-button routerLink="/register">Register Now</a>
                  </div>
                </div>
              </form>
              <form class="w-100 h-100" *ngIf="!role&& phoneDivOTP" [formGroup]="loginPhone"
                (ngSubmit)="loginViaPhone()">
                <div id="second" class="inputdata activeTab">
                  <div class="form-group mb-4">
                    <mat-form-field class="field-round">
                      <mat-label>Email</mat-label>
                      <!-- <input matInput type="email"  id="mobilenumber" formControlName="phoneNo" maxlength="100" (keypress)="validateNo($event)"  placeholder="Enter Email"  [ngClass]="{ 'is-invalid': viaphonesubmitted && ph['phoneNo'].errors }"/>    -->
                      <input matInput type="email" id="mobilenumber" formControlName="phoneNo" maxlength="100"
                        placeholder="Enter Email"
                        [ngClass]="{ 'is-invalid': viaphonesubmitted && ph['phoneNo'].errors }" />
                    </mat-form-field>
                    <!-- <div *ngIf="viaphonesubmitted && ph['phoneNo'].errors" class="invalid-feedback">
                        <div *ngIf="ph['phoneNo'].errors['required']">Phone Number is required</div>
                        <div *ngIf="ph['phoneNo'].errors['pattern']">Please, Enter 10 digit Mobile Number.</div> 
                        </div>  -->
                  </div>
                  <div class="cta mt-5 mb-4 text-center">
                    <button type="submit" class="btn btn-primary w-100">Login</button>
                  </div>
                  <div class="secondcta text-center">
                    Don't you have an Account? <a routerLink="/register">Register Now</a>
                  </div>
                </div>
              </form>

              <form class="w-100 h-100" *ngIf="!phoneDivOTP" [formGroup]="frmOtp" (ngSubmit)="frmSubmit()">
                <div id="first" class="inputdata activeTab">
                  <div class="form-group mb-4">
                    <mat-form-field class="field-round">
                      <mat-label>Email</mat-label>
                      <!-- <input matInput type="email"  id="mobilenumber" formControlName="phoneNo" maxlength="100" (keypress)="validateNo($event)"  placeholder="Enter Email"  [ngClass]="{ 'is-invalid': viaphonesubmitted && ph['phoneNo'].errors }"/>    -->
                      <input matInput type="email" readonly id="mobilenumber" formControlName="emailOrMobileNo"
                        maxlength="100" placeholder="Enter Email"
                        [ngClass]="{ 'is-invalid': viaphonesubmitted && ph['phoneNo'].errors }" />
                    </mat-form-field>
                    <!-- <div *ngIf="viaphonesubmitted && ph['phoneNo'].errors" class="invalid-feedback">
                    <div *ngIf="ph['phoneNo'].errors['required']">Phone Number is required</div>
                    <div *ngIf="ph['phoneNo'].errors['pattern']">Please, Enter 10 digit Mobile Number.</div> 
                    </div>  -->
                  </div>
                  <div class="form-group mb-4">
                    <mat-form-field class="field-round">
                      <mat-label>OTP </mat-label>
                      <input matInput type="text" formControlName="otp" placeholder="Enter OTP here"
                        [ngClass]="{ 'is-invalid': submitted && frmOtp.controls['otp'].errors }" />
                    </mat-form-field>
                    <!-- <div *ngIf="submitted && photp['otp'].errors" class="invalid-feedback ">
                    <div *ngIf="photp['otp'].errors['required']">OTP is required</div>
                
                </div> -->
                  </div>

                  <div class="cta mt-5 mb-4 text-center">
                    <button mat-button class="btn btn-primary w-100">Submit</button>
                  </div>

                </div>
              </form>
              <form class="w-100 h-100" *ngIf="!phoneDiv" [formGroup]="loginFormOtp" (ngSubmit)="onotpSubmit()">
                <div id="first" class="inputdata activeTab">

                  <div class="form-group mb-4">
                    <mat-form-field class="field-round">
                      <mat-label>OTP </mat-label>
                      <input matInput type="text" formControlName="otp" placeholder="otp"
                        [ngClass]="{ 'is-invalid': submitted && photp['otp'].errors }" />
                    </mat-form-field>
                    <div *ngIf="submitted && photp['otp'].errors" class="invalid-feedback ">
                      <div *ngIf="photp['otp'].errors['required']">OTP is required</div>

                    </div>
                  </div>
                  <div class="form-group mb-4">
                    <mat-form-field class="field-round">
                      <mat-label>Password</mat-label>
                      <input matInput type="password" formControlName="password" id="floatingPassword"
                        placeholder="Password" [ngClass]="{ 'is-invalid': submitted && photp['password'].errors }" />
                    </mat-form-field>
                    <div *ngIf="submitted && photp['password'].errors" class="invalid-feedback">
                      <div *ngIf="photp['password'].errors['required']">password is required</div>
                    </div>
                  </div>
                  <div class="form-group mb-4">
                    <mat-form-field class="field-round">
                      <mat-label>Confirm Password</mat-label>
                      <input matInput type="password" class="form-control" formControlName="confirmPassword"
                        id="cfloatingPassword" placeholder="Confirm Password*"
                        [ngClass]="{ 'is-invalid': submitted && photp['confirmPassword'].errors }">
                    </mat-form-field>
                    <div *ngIf="submitted && photp['confirmPassword'].errors" class="invalid-feedback">
                      <div *ngIf="photp['confirmPassword'].errors['required']">Confirm Password is required</div>
                      <div *ngIf="photp['confirmPassword'].errors['mustMatch']">Passwords must match</div>
                    </div>

                  </div>
                  <div class="cta mt-5 mb-4 text-center">
                    <button mat-button class="btn btn-primary w-100">Submit</button>
                  </div>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>