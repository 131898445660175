import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { AppRoles } from 'src/app/_config/api.config';
import { Paging } from 'src/app/_config/paging';
import { UserDetails } from 'src/app/_models/user';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.css']
})

export class BaseComponent{
  userDetail:UserDetails=new UserDetails();
  currentModuleName='';
 
  constructor(public sessionService:LocalStorageService) {
    try {
      let usr = this.sessionService.retrieve('userDetail');

    if (usr != null && usr.Data != undefined) {
      this.userDetail.id=usr.Data?.userDetails?.id;
      this.userDetail.email = usr.Data?.userDetails?.email;
      this.userDetail.firstName=usr.Data?.userDetails?.firstName ;
      this.userDetail.lastName=usr.Data?.userDetails?.lastName ;
      const role = usr.Data?.roles;
      this.userDetail.role = role[0].code.toLowerCase();//there only single role application 
      this.currentModuleName=AppRoles.filter(x=>x.code.toLowerCase()==role[0].code.toLowerCase())[0]?.route;
    }
    } catch (error) {
      
    }
    
  }
  _getRole(){

  }
}
