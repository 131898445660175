<div class="section pt-0 instructions-sec">
  <div class="container">
    <header>
      <h1>Instructions</h1>
      <hr>
    </header>
    <ol>
      <li>Please ensure you are in a well lit space and your face is clearly visible on this camera.</li>
      <li>Please ensure you have good internet connectivity on your phone/laptop before initiate the process.</li>
      <li>Please speak clearly and sufficiently loudly to ensure your voice is recorded properly.</li>
      <li>Press the stop button only after completing all question. Once you press stop you can not go back.</li>
      <li>Question will; appear on screen. Start answering after reading the question.</li>
      <li>There is a time limit for each question. Please be mindful of that.</li>
    </ol>
    <div class="btn-checkbox agree-check">
      <input name="agree" type="checkbox" id="agree" checked="checked">
      <label for="agree" class="form-check-label">I  accept the <a href="#">Terms and Conditions</a></label>
    </div>
    <div class="form-group m-2">
      <button mat-button class="btn btn-primary" (click)="Recording()" >Start recording your session</button>
    </div>
  </div>
</div>
