<ng-container *ngIf="!isApplied">
<div class="main-div container" *ngIf="onNoClick==false && onYesClick==false">
    <h1><img style="width: 223px;" src="https://talentonlease.com/assets/img/tol-logo.svg" /></h1>
    We appreciate your interest in TalentOnLease and hope to have the opportunity to collaborate in the future
    <hr>
    <div class="heading-section heading-section-white" style="text-align: center;">
        <br> 
        <button class="btn-yes" (click)="_applycandidate()">Yes</button>
        &nbsp;
        &nbsp;
        <button class="btn-no" (click)="onClickNo()">NO</button>
    </div>
</div>

<div class="main-div container" *ngIf="onNoClick || onYesClick">
    <h1>
        <img style="width: 223px;" src="https://talentonlease.com/assets/img/tol-logo.svg" /></h1>
        <mat-icon style="color: #0fde0f; font-size: 32px;">check_circle</mat-icon>
   <p style="color: #074a07;">  
    {{onNoClick?'Thank you for letting us know about your current availability status. We understand that you are unable to pursue the Position at this time.' 
    : 'Thank you for confirming your availability for the position. Our team will review your profile and connect with you shortly to discuss the next steps in the process' }}
</p>
    <hr>
</div>
</ng-container>
<div class="main-div container" *ngIf="isApplied">
    <h1>
        <img style="width: 223px;" src="https://talentonlease.com/assets/img/tol-logo.svg" /></h1>
        <mat-icon style="color: #0fde0f; font-size: 32px;">check_circle</mat-icon>
   <p> Profile already been submitted to the client.</p>
    <hr>
</div>






