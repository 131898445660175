<section class="section">
    <div class="container">

<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:center;line-height:normal;'><strong><u><span style="font-size:19px;font-family:Roboto;color:#111111;">Privacy Policy</span></u></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:normal;'><span style="font-size:16px;font-family:Roboto;color:#111111;">&nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:normal;'><strong><span style="font-size:16px;font-family:Roboto;color:#111111;">Introduction</span></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:normal;'><span style="font-size:16px;font-family:Roboto;color:#111111;">&nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:normal;'><span style="font-size:16px;font-family:Roboto;color:#111111;">TalentOnLease Private Limited (&ldquo;we&rdquo; or &ldquo;us&rdquo; or &ldquo;our&rdquo; or &ldquo;TalentOnLease&rdquo;) respects the privacy of our users (&ldquo;user&rdquo; or &ldquo;you&rdquo;). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website [</span><a href="https://talentonlease.com/"><em><span style="font-size:16px;font-family:Roboto;">https://talentonlease.com/</span></em></a><span style="font-size:16px;font-family:Roboto;color:#111111;">] including any other media form, media channel, mobile website, or mobile application related or connected thereto (collectively, the &ldquo;Site&rdquo;). Please read this privacy policy carefully. If you do not agree with the terms of this&nbsp;</span><span style="font-size:16px;font-family:Roboto;">privacy policy, please do not access the site.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:normal;'><span style="font-size:16px;font-family:Roboto;color:black;background:white;">By using our Services and/or registering for an account with us, you are accepting this Privacy Policy, you are consenting to our collection, use, disclosure, retention, transfer and protection of your information and personal information as described in this Privacy Policy. If you Choose not to provide the information we require, you may not be able to take advantage of many of our features of the website and our Services.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:normal;'><span style="font-size:16px;font-family:Roboto;color:#111111;">&nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:normal;'><strong><span style="font-size:16px;font-family:Roboto;color:#111111;">Collection of your Information</span></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:normal;'><span style="font-size:16px;font-family:Roboto;color:#111111;">&nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:normal;'><span style="font-size:16px;font-family:Roboto;color:#111111;">We may collect information about you in a variety of ways. The information we may collect on the Site includes:</span></p>
<ul type="disc" style="margin-bottom:0in;">
    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;color:#111111;text-align:justify;line-height:normal;'><span style="font-size:16px;font-family:     Roboto;">Personal Data</span></li>
    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;color:#111111;text-align:justify;line-height:normal;'><span style="font-size:16px;font-family:     Roboto;">Demographic and other personally identifiable information (such as your name and email address) that you voluntarily give to us when choosing to participate in various activities related to the Site.</span></li>
    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;color:#111111;text-align:justify;line-height:normal;'><span style="font-size:16px;font-family:     Roboto;">Derivative Data</span></li>
    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;color:#111111;text-align:justify;line-height:normal;'><span style="font-size:16px;font-family:     Roboto;">Information our servers automatically collect when you access the Site, such as your IP address, your browser type, your operating system, your access times, and the pages you have viewed directly before and after accessing the Site.</span></li>
</ul>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:normal;'><strong><span style="font-size:16px;font-family:Roboto;color:#111111;">Use of your Information</span></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:normal;'><span style="font-size:16px;font-family:Roboto;color:#111111;">&nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:normal;'><span style="font-size:16px;font-family:Roboto;color:#111111;">Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Site to:</span></p>
<ul type="disc" style="margin-bottom:0in;">
    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;color:#111111;text-align:justify;line-height:normal;'><span style="font-size:16px;font-family:     Roboto;">Compile data and analysis for use internally or with third parties.</span></li>
    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;color:#111111;text-align:justify;line-height:normal;'><span style="font-size:16px;font-family:     Roboto;">Create and manage your account.</span></li>
    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;color:#111111;text-align:justify;line-height:normal;'><span style="font-size:16px;font-family:     Roboto;">Deliver targeted services and other information regarding services and the Site to you.</span></li>
</ul>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:normal;'><strong><span style="font-size:16px;font-family:Roboto;color:#111111;">Disclosure of your Information</span></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:normal;'><span style="font-size:16px;font-family:Roboto;color:#111111;">&nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:normal;'><span style="font-size:16px;font-family:Roboto;color:#111111;">In some situations, we may target our Services to your interests by sharing your Personal Information with businesses, non-profits, or individuals outside of TalentOnLease in order to provide them to you. We only share personal information with those who need it in order to achieve the stated goals, keeping disclosure to a minimum. Your information may be disclosed as follows:</span></p>
<ul type="disc" style="margin-bottom:0in;">
    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;color:#111111;text-align:justify;line-height:normal;'><span style="font-size:16px;font-family:     Roboto;">By Law or to Protect Rights</span></li>
    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;color:#111111;text-align:justify;line-height:normal;'><span style="font-size:16px;font-family:     Roboto;">Third-Party Service Providers/Customers</span></li>
</ul>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:normal;'><strong><span style="font-size:16px;font-family:Roboto;color:#111111;">Retention</span></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:normal;'><span style="font-size:16px;font-family:Roboto;color:#111111;">We will only keep your personal data for as long as it takes to accomplish the specific goals outlined in this policy, unless:</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:normal;'><span style="font-size:16px;font-family:Roboto;color:#111111;">&nbsp;</span></p>
<ul style="list-style-type: disc;">
    <li><span style="font-family:Roboto;">the record&apos;s keeping is mandated or permitted by law; or</span></li>
    <li><span style="font-family:Roboto;">You have given permission for the record to be kept.</span></li>
</ul>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:normal;'><span style="font-size:16px;font-family:Roboto;color:#111111;">&nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:normal;'><span style="font-size:16px;font-family:Roboto;color:#111111;">We will uphold our non-disclosure duties for the duration of retention and refrain from sharing or selling your personal information.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:normal;'><span style="font-size:16px;font-family:Roboto;color:#111111;">At our discretion, we may keep your personal data in both physical and electronic records.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:normal;'><strong><span style="font-size:16px;font-family:Roboto;color:#111111;">&nbsp;</span></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:normal;'><strong><span style="font-size:16px;font-family:Roboto;color:#111111;">Third-Party Websites</span></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:normal;'><span style="font-size:16px;font-family:Roboto;color:#111111;">&nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:normal;'><span style="font-size:16px;font-family:Roboto;color:#111111;">The Site may contain links to third-party websites and applications of interest, including advertisements and external services that are not affiliated with us. Once you have used these links to leave the Site, any information you provide to these third parties is not covered by this Privacy Policy, and we cannot guarantee the safety and privacy of your information. Before visiting and providing any information to any third-party websites, you should inform yourself of the privacy policies and practices (if any) of the third party responsible for that website, and should take those steps necessary to, in your discretion, protect the privacy of your information. We are not responsible for the content or privacy and security practices and policies of any third parties, including other sites, services or applications that may be linked to or from the Site.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:normal;'><span style="font-size:16px;font-family:Roboto;color:#111111;">&nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:normal;'><strong><span style="font-size:16px;font-family:Roboto;color:#111111;">Security of your Information</span></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:normal;'><span style="font-size:16px;font-family:Roboto;color:#111111;">&nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:normal;'><span style="font-size:16px;font-family:Roboto;color:#111111;">We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse. Any information disclosed online is vulnerable to interception and misuse by unauthorized parties. Therefore, we cannot guarantee complete security if you provide personal information.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:normal;'><span style="font-size:16px;font-family:Roboto;color:#111111;">&nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:normal;'><strong><span style="font-size:16px;font-family:Roboto;color:#111111;">Contact Information of Data Privacy Officer</span></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:normal;'><span style="font-size:16px;font-family:Roboto;color:#111111;">&nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:115%;border:none;'><span style="font-size:16px;line-height:115%;font-family:Roboto;">If a User has any questions concerning this policy or anything related to any of the foregoing, please write to our Data Privacy Officer.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.75in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;text-indent:-.75in;line-height:115%;border:none;'><span style="font-size:16px;line-height:115%;font-family:Roboto;">Details of the Data Privacy Officer&nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.75in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;text-indent:-.75in;line-height:115%;border:none;'><span style="font-size:16px;line-height:115%;font-family:Roboto;">Name &ndash; Mr. Pervesh Dhingra</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.75in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;text-indent:-.75in;line-height:115%;border:none;'><span style="font-size:16px;line-height:115%;font-family:Roboto;">E-mail ID -&nbsp;</span><a href="mailto:pervesh.dhingra@talentonlease.com"><span style="font-size:16px;line-height:115%;font-family:Roboto;">pervesh.dhingra@talentonlease.com</span></a></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:normal;'><span style="font-size:16px;font-family:Roboto;color:#111111;">&nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:normal;'><strong><span style="font-size:16px;font-family:Roboto;color:#111111;">Policy Changes</span></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:normal;'><span style="font-size:16px;font-family:Roboto;color:#111111;">&nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:normal;'><span style="font-size:16px;font-family:Roboto;color:#111111;">We reserve the right to change this Privacy Policy at any time. If we make material changes to this policy, we may notify you on our Website or by email (sent to the email address specified in your account), prior to the change becoming effective. We encourage you to periodically review this page for the latest information on our privacy practices.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;'>&nbsp;</p>



    </div>
</section>