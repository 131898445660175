
import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class SharedService {
    public loaderSvc:Subject<boolean>= new Subject<boolean>();
    constructor() {

    }
    showLoader(val) {
        this.loaderSvc.next(val);
    }

}