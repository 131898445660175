import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: 'root' })
export class ApiService {
    constructor(private _httpClient: HttpClient) {

    }

    _get(url: string, data: any):Observable<any> {
        return this._httpClient.get(`${environment.apiUrl}${url}`,{ params: data });
    }

    _post(url: string, postData: any,params:{}={}):Observable<any> {
        return this._httpClient.post(`${environment.apiUrl}${url}`, postData,{params});
    }

    _put(url: string, postData: any,params:{}={}):Observable<any> {
        return this._httpClient.put(`${environment.apiUrl}${url}`, postData,{params});
    }

    
    _delete(url: string, data: any):Observable<any> {
        return this._httpClient.delete(`${environment.apiUrl}${url}`,{ params: data });
    }


    downLoadFile(data: any, type: string,fileName:string) {
        let blob = new Blob([data], { type: type});
        let url = window.URL.createObjectURL(blob);         
        let downloadLink = document.createElement('a');
        downloadLink.href =url;
        downloadLink.setAttribute('download', fileName);
        document.body.appendChild(downloadLink);
        downloadLink.click();
       
    }
}