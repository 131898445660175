<!--header start-->
<header id="top" class="header" *ngIf="router.url !== '/login'&& router.url !== '/register'&& router.url !== '/forgetPassword'&& router.url !== '/recording'">
    <nav class="navbar navbar-expand-lg" [class.open]="isOpen">
      <div class="container">
        <a class="navbar-brand" routerLink="/"> 
          <!-- <img class="tollogo" src="../../../../../assets/img/digi/logo.png" alt="Logo"> -->
          <img class="tollogo" [src]="siteLogo" alt="Logo">
        </a>
        <button class="navbar-toggler" type="button" [class.active]="isActive" (click)="toggleClasses()" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto">
            <!--<li class="nav-item">
              <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" routerLink="/">Home</a>
            </li>-->
            <li class="nav-item">
              <a class="nav-link" routerLinkActive="active" routerLink="/find-candidates" (click)="toggleClasses()">Find Candidates</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLinkActive="active" routerLink="/find-jobs" (click)="toggleClasses()">Find Jobs</a>
            </li>         
            <li class="nav-item" *ngIf="homePageUrl!='index'">
              <a class="nav-link" href="https://talentonlease.com/blogs">Blogs</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLinkActive="active" routerLink="/about" (click)="toggleClasses()">About Us</a>
            </li>
            <li class="nav-item" *ngIf="homePageUrl!='index'">
              <a class="nav-link pdr-15">Hire <span></span></a>
              <ul class="sub-menu">
                <li>
                  <a class="nav-link" routerLinkActive="active" routerLink="/hire-full-stack-developer">Hire Full Stack Developer</a>
                </li>
                <li>
                  <a class="nav-link" routerLinkActive="active" routerLink="/hire-java-developer">Hire Java Developer</a>
                </li>
                <li>
                  <a class="nav-link" routerLinkActive="active" routerLink="/hire-dot-net-developer">Hire Dot NET Developer</a>
                </li>
                <li>
                  <a class="nav-link" routerLinkActive="active" routerLink="/hire-mean-stack-developer">Hire Mean Stack Developer</a>
                </li>
                <li>
                  <a class="nav-link" routerLinkActive="active" routerLink="/hire-mern-stack-developer">Hire Mern Stack Developer</a>
                </li>
                <li>
                  <a class="nav-link" routerLinkActive="active" routerLink="/hire-react-js-developer">Hire ReactJS Developer</a>
                </li>
                <li>
                  <a class="nav-link" routerLinkActive="active" routerLink="/hire-angular-developer">Hire Angular Developer</a>
                </li>
                <li>
                  <a class="nav-link" routerLinkActive="active" routerLink="/hire-mobile-app-developer">Hire Mobile App Developer</a>
                </li>
                <li>
                  <a class="nav-link" routerLinkActive="active" routerLink="/hire-python-developer">Hire Python App Developer</a>
                </li>
                <li>
                  <a class="nav-link" routerLinkActive="active" routerLink="/hire-software-developer">Hire Software Developer</a>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLinkActive="active" routerLink="/contact">Contact Us</a>
            </li>
          </ul>
          <div class="right-links">
            <ul  *ngIf="userId==0">
              <!-- <li class="top-tel"><a href="tel:09876543210" class="d-flex align-items-center"><img src="assets/img/india-flag.png" alt="India Flag"> +91 98765 43210</a></li> -->
             
              <li><a class="login-btn" routerLink="/login">Login</a></li>
              <li *ngIf="signupDisabled"><a routerLink="/register"  class="register-btn btn btn-secondary">Register Now</a></li>
              <li><a class="login-btn" target="_blank" href="https://www.escindia.in/membership-certifica/talentonlease-pvt-ltd/">
                <img src="https://www.escindia.in/wp-content/uploads/2023/06/esc-member-1-768x768.png" class="esc-logo"/></a></li>
            </ul>
            <ul *ngIf="userId!=0">
              <li>
                <div class="user-sec">
                  <div class="desk-nav">
                    <button class="loginbtn" mat-button [matMenuTriggerFor]="beforeMenu">
                      <span class="pic-col"><img src="assets/img/pic1.jpg" alt="User"></span> 
                      <span class="name-col">{{userName}}</span>
                    </button>
                    <mat-menu #beforeMenu="matMenu" xPosition="before">
                      <a mat-menu-item routerLinkActive="active" *ngIf="currectRole!='superadmin'" (click)="dashboardClick()"><i class="fa fa-tachometer" aria-hidden="true"></i> &nbsp;Dashboard</a>
                      <a mat-menu-item routerLinkActive="active" *ngIf="currectRole!='superadmin'" routerLink="/my-profile" ><i class="fa fa-user-circle" aria-hidden="true"></i> &nbsp;My Profie</a>
                      <a mat-menu-item routerLinkActive="active" (click)="logOut()"><i class="fa fa-sign-out" aria-hidden="true"></i> &nbsp;Logout</a>
                    </mat-menu>
                  </div>

                  <!-- <a class="loginbtn" (click)="dashboardClick()"><span class="pic-col"><img src="assets/img/pic1.jpg" alt="User"></span> <span class="name-col">{{userName}}</span></a> -->
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </header>
  <!--header end-->