
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KMDTJN82"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
    <div class="app-loader" *ngIf="isLoading">
        <div class="spinner">
            <div class="double-bounce1 mat-bg-primary" style="background: #fcc02e"></div>
            <div class="double-bounce2 mat-bg-accent" style="background: #03a9f4"></div>
          </div>
      </div>

<router-outlet></router-outlet>
