import { Component, ElementRef, OnInit, Inject, Renderer2 } from '@angular/core';
import { HomeService } from 'src/app/_services';
import { RenderService } from 'src/app/_services/render.service';
import { MatDialog } from '@angular/material/dialog';
import { ContactDialog } from '../../shared/contact-dialog/contact-dialog-component';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { ContactusService } from 'src/app/_services/contactus.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-full-stack-developer',
  templateUrl: './full-stack-developer.component.html',
  styleUrls: ['./full-stack-developer.component.css']

})
export class FullStackDeveloperComponent implements OnInit {
  contactUsForm:any;
  frmInq:any;
  contactflag: boolean = false;
  contactflag1: boolean = false;
  metaName='Hire Full Stack Developer | Find Skilled IT Professionals';
  constructor(private service: RenderService,private homeService:HomeService, private dialog: MatDialog,private meta:Meta,
    private fb: FormBuilder, private router: Router, private contactusService: ContactusService,
    ) { }

  leadingCompanies=[];
  ngOnInit(): void {
    this.service.addLinkTag('https://talentonlease.com/hire-full-stack-developer');

    this.meta.addTag({name:'description',content: 'Hire dedicated Full-Stack developers on our platform and elevate your team with skilled IT professionals. Take your business to the next level with TalentOnLease. Explore top talent now!'});

    this.getallbanners();
    this.meta.addTag({name:'robots',content: 'index, follow'});
    this.meta.addTag({name:"keywords",content: 'hire full stack developer'});
    this.meta.addTag({name:"keywords",content: 'hire dedicated full stack developer'});

    this.contactUsForm = this.fb.group({
      name: ["", [Validators.required]],
      email: ["", [Validators.required,Validators.email]],
      mobile: ["", [Validators.required,Validators.minLength(10),Validators.maxLength(10), Validators.pattern('[0-9]*')]],
      message: [""],
      designation: ["", [Validators.required]],
      organization: [""],
      subject:['SEO Search :']
    })
  }

  ngOnDestroy(): void {
    this.meta.removeTag('name="description"');
    this.meta.removeTag('name="robots"');
    this.meta.removeTag('name="keywords"');

  }


  openDialog() {
   let dialogRef = this.dialog.open(ContactDialog, {
    data:{type:'N'},
      width: '700px',
    });
    dialogRef.afterClosed().subscribe(res=>{
      console.log(res)
    })
  }

  
  submiContactUs() {
    this.contactflag = true
    if (this.contactUsForm.invalid) {
      return;
    }
   

    let json = this.contactUsForm.value;
    this.contactUsForm.patchValue({subject:'SEO Search: '});

    this.contactusService.submitContactUs(json).subscribe(result => {
      this.router.navigate(['/thank-you']);
      if (result) {
        console.log(result)
      }
      else {
        console.log("Something went wrong contact page!")
      }
    })
    
  }

  getallbanners(){   
    
    this.homeService.getBanner().subscribe(result => {   
      if(result && result.Data!=null){
      //  this.banners = result.Data;
      //  this.totalCandidatesCount= this.banners?.totalCandidatesCount;
      //  this.totalClientCount= this.banners?.totalClientCount;
      //  this.totalPartnerCount= this.banners?.totalPartnerCount;
      //  this.totaljobsPositionCount= this.banners?.totaljobsPositionCount;
       this.leadingCompanies=  result.Data?.leadingCompany;
      
      
      }
    })}

}
