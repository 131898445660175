<section class="app-section">
    <div class="row">
      <div class="col-md-5">
        <div class="app-leftsec">
          <div class="top-logo mb-5"><a  routerLink="/"><img class="tollogo" src="assets/img/tol-logo.svg" alt="Logo"></a></div>
          <div class="slider-info">
            <div class="item">
              <div class="sec-pic text-center"><img src="assets/img/register-pic.png" alt="Register"></div>
              <div class="heading mb-3">Making available Right Resources at Right Time and at Right Cost</div>
              <div class="content">
                <p>TalentOnLease works with a wide range of partners (IT Services/Solution firms) to fulfil these requirements.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-7">
        <div class="app-rightsec">
          <a href="/" class="app-close"><img src="assets/img/close.svg" alt="Close"></a>
          <div class="app-form">
            <div class="w-100 h-100">
              <div class="form-heading">Forgot Password</div>
              <div class="form-subheading">Enter your credentials for  your account</div>
              <div class="form-section mt-5">
                <form class="w-100 h-100"  *ngIf="phoneDiv" [formGroup]="loginPhone" (ngSubmit)="loginViaPhone()">
                  <div id="second" class="inputdata activeTab">
                    <div class="form-group mb-4">
                      <mat-form-field class="field-round">
                        <mat-label>Email</mat-label>
                        <input matInput type="tel"  id="mobilenumber" formControlName="phoneNo"  placeholder="Enter Email"  [ngClass]="{ 'is-invalid': viaphonesubmitted && ph['phoneNo'].errors }"/>            
                      </mat-form-field>
                      <!-- <div *ngIf="viaphonesubmitted && ph['phoneNo'].errors" class="invalid-feedback">
                        <div *ngIf="ph['phoneNo'].errors['required']">Phone Number is required</div>
                        <div *ngIf="ph['phoneNo'].errors['pattern']">Please, Enter 10 digit Mobile Number.</div> 
                        </div>  -->
                 </div>
                 <!-- <div class="mb-3 form-link">
                  <a mat-button routerLink="/login">Login</a>
                </div> -->
                  <div class="cta mt-5 mb-4 text-center">
                    <button type="submit" class="btn btn-primary w-100">Submit</button>
                  </div>
                  <div class="secondcta text-center">
                    Don't you have an Account? <a routerLink="/register">Register Now</a>
                  </div>
                </div>
                </form>
                <form class="w-100 h-100"  *ngIf="!phoneDiv" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <div id="first" class="inputdata activeTab">
                    
                    <div class="form-group mb-4">
                        <mat-form-field class="field-round">
                          <mat-label>OTP </mat-label>
                          <input matInput type="text"  formControlName="otp" placeholder="otp" [ngClass]="{ 'is-invalid': submitted && f['otp'].errors }"/>            
                        </mat-form-field>
                        <div *ngIf="submitted && f['otp'].errors" class="invalid-feedback ">
                          <div *ngIf="f['otp'].errors['required']">OTP is required</div>
                      
                      </div>
                      </div>
                    <div class="form-group mb-4">
                      <mat-form-field class="field-round">
                        <mat-label>Password</mat-label>
                        <input matInput type="password" formControlName="password" id="floatingPassword" placeholder="Password" [ngClass]="{ 'is-invalid': submitted && f['password'].errors }"/>                  
                      </mat-form-field>
                      <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
                        <div *ngIf="f['password'].errors['required']">password is required</div>
                    </div>
                      </div>
                        <div class="form-group mb-4">
                          <mat-form-field class="field-round">
                          <mat-label>Confirm Password</mat-label>
                          <input matInput type="password" class="form-control" formControlName="confirmPassword" id="cfloatingPassword" placeholder="Confirm Password*" [ngClass]="{ 'is-invalid': submitted && f['confirmPassword'].errors }">
                          </mat-form-field> 
                          <div *ngIf="submitted && f['confirmPassword'].errors" class="invalid-feedback">
                            <div *ngIf="f['confirmPassword'].errors['required']">Confirm Password is required</div>
                            <div *ngIf="f['confirmPassword'].errors['mustMatch']">Passwords must match</div> 
                          </div>
                        
                      </div>
                      <div class="cta mt-5 mb-4 text-center">
                        <button mat-button class="btn btn-primary w-100">Submit</button>
                      </div>
                   
                    </div>
                    </form>
              </div>
            </div>
          </div>
        </div>
       
      </div>
    </div>
  </section>