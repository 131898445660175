import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchtoolService {
  child1Subject: any;

  constructor() {
   }

   searchJobs(value: any){
    this.child1Subject.next(value);
}
}
