<app-searchtoolbar (childButtonEvent)="receivedCandidateHandler($event)"></app-searchtoolbar>

<section class="section candidate-job-sec">
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <div class="candidate-steps column-card">
          <header class="title-head mb-5">
            <h1 class="title-lg">Find Resources for your Project Requirements</h1>
            <p>Our IT recruitment platform offers flexibility of engagement as the clients can benefit from IT contract staff hiring for their critical projects.</p>
          </header>
          <div class="steps-col">
            <div class="step d-flex">
              <div class="sec-pic"><img src="assets/img/step1.svg" alt="Steps"></div>
              <div class="content">
                <span class="sico">Step 1</span>
                <p>Create your Profile on the TalentOnLease Platform</p>
              </div>
            </div>
            <div class="step d-flex">
              <div class="sec-pic"><img src="assets/img/step2.svg" alt="Steps"></div>
              <div class="content">
                <span class="sico">Step 2</span>
                <p>Share Details of the Project and Skill Set Required</p>
              </div>
            </div>
            <div class="step d-flex">
              <div class="sec-pic"><img src="assets/img/step3.svg" alt="Steps"></div>
              <div class="content">
                <span class="sico">Step 3</span>
                <p>Find Resources from the Database of TOL</p>
              </div>
            </div>
            <div class="step d-flex">
              <div class="sec-pic"><img src="assets/img/step4.svg" alt="Steps"></div>
              <div class="content">
                <span class="sico">Step 4</span>
                <p>Assess Skills</p>
              </div>
            </div>
            <div class="step d-flex">
              <div class="sec-pic"><img src="assets/img/step5.svg" alt="Steps"></div>
              <div class="content">
                <span class="sico">Step 5</span>
                <p>Sign a Contract and Start Engagement</p>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="find-candidate-sec">
          <div class="total-candidate d-flex align-items-center mb-4">
            <div class="total-count"><strong>Total Candidates Found</strong> <span>{{totalCandidate}}</span></div>
            
          </div>
          <div class="candidate-listing-sec cus-scr">
            
            <div class="row" *ngIf="showCandidate">
              <div class="col-md-6"  *ngFor="let candidate of candidates">
                <div class="column-card">
                  <a (click)="CandidateDetail(candidate.id)"class="list-sec d-flex">
                    <div class="sec-pic"><img src="assets/img/pic1.jpg" alt=""></div>
                    <div class="list-user">
                      <div class="uname">{{candidate.fullName}} <span>  <i   [ngClass]="[ candidate.isVerified ? 'verify-ico verified' : 'verify-ico']" >&nbsp;</i></span></div>
                      <div class="udesg">{{candidate.designation}} </div>
                      <div class="uterm d-flex flex-wrap">
                        <span class="uyrs"><img src="assets/img/job-exp-blk.svg" alt=""> {{candidate.experience}}  Years</span>
                        <span class="uloc"><img src="assets/img/job-loc-blk.svg" alt=""> {{candidate.cityName}} </span>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
              <div class="row" *ngIf="!showCandidate"> No Candidate Found</div>
             
          
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

