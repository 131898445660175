import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MustMatch } from 'src/app/_helper/must-match.validator';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {

  loginForm!: FormGroup;
  loginPhone!: FormGroup;
  loading = false;
  submitted = false;
  viaphonesubmitted=false;
  error = '';
  isLoggedIn = false;
  phoneDiv= true;
  user:any;
  constructor(private formBuilder: FormBuilder, private router: Router,
    private route: ActivatedRoute,
    private authenticationService: UserService,private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      emailOrMobileNo: [''],
      password: ['', Validators.required],
      otp: ['', Validators.required],
      confirmPassword: ['',Validators.required],
     },{
     validator: MustMatch('password', 'confirmPassword'),
    });

    this.loginPhone = this.formBuilder.group({
     // phoneNo: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]]
     phoneNo: ['', [Validators.required,Validators.email]]
    })
  }

  get f() { return this.loginForm.controls; }
  get ph(){return this.loginPhone.controls;}
  onSubmit() {
    
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.submitted = false;
    var email= this.loginPhone.controls['phoneNo'].value;
    this.user=this.loginForm.value;
    this.user.emailOrMobileNo=email;
    this.authenticationService.forgetPassword(this.loginForm.value)
    
    .subscribe({
      next: (res: any) => {  
        if(res.StatusCode == 200 ){
          this.toastrService.success(res.Message); 
          this.router.navigate(['/home']);
        }
        else{
          this.toastrService.error(res.Message);   
          //this.router.navigate(['/home']);
        }
      
      },
      error: error => {
        this.toastrService.error("user name and password is not correct");
      
      }


    });
     
    
  }

  loginViaPhone(){
  this.viaphonesubmitted=true;
    if (this.loginPhone.invalid) {
      return;
    }
    this.viaphonesubmitted=false;
    var phoneNo= this.loginPhone.controls['phoneNo'].value;
    this.authenticationService.loginViaPhone(phoneNo).subscribe(result => {
      if(result){
        if(result.StatusCode == 200 ){
          this.toastrService.success(result.Message); 
          this.phoneDiv= false;
        }
        else{
          this.toastrService.error(result.Message);   
      
        }
      
      }
    })}
}
