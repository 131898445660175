<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/css/bootstrap.min.css">
<script>
    $(document).ready(function () {
        $("#testimonial-slider").owlCarousel({

            items: 1,

            itemsDesktop: [1000, 1],

            itemsDesktopSmall: [979, 1],

            itemsTablet: [768, 1],

            pagination: true,

            navigation: false,

            navigationText: ["", ""],

            slideSpeed: 1000,

            singleItem: true,

            transitionStyle: "fade",

            autoPlay: true

        });

    });

</script>


<section class="banner-section">
    <img src="../assets/img/fullstackdeveloperimg/home-banner.jpg" class="main-banner" />
    <div class="container">
        <div class="banner-caption">
            <div class="bg-white">
               
                <h1>Hire the Top 3% of Full Stack Developers</h1>
            </div>
            <p>
                Hire Full Stack Developers to elevate your digital presence.
                Transform your idea into unparalleled web and mobile
                applications, enhancing top-tier features and functionalities. Our
                dedicated Full Stack developers team adept acros the entire spectrum
                of cutting-edge front-end and back-end technologies. Partner with us
                to unleash innovation and achieve excellence in every aspect of
                development.
            </p>
            <div class="row email-box-main">
                <div class="col-md-6 mx-auto">
                    <button mat-raised-button (click)="openDialog()" class="btn mb-15">Hire Full Stack Developer Now</button>
                </div>
            </div>
            <div class="icon-text">
                <div class="icon-item">
                    <img src="../assets/img/fullstackdeveloperimg/briefcase.png" alt="briefcase" />
                    <p>200 + Client Served</p>
                </div>
                <div class="icon-item">
                    <img src="../assets/img/fullstackdeveloperimg/start-up.png" alt="Start Up" />
                    <p>500 + Fullstack Project Delivered</p>
                </div>
                <div class="icon-item">
                    <img src="../assets/img/fullstackdeveloperimg/check.png" alt="check" />
                    <p>7 Days Risk Free Trial</p>
                </div>
            </div>
        </div>
    </div>
</section>
 <div class="container">
    <header class="gradient-title-head mt-30 mb-30 col-md-8 mx-auto">
        <h2 class="title-lg text-center">Hire Dedicated Full Stack Developer for Fastest <span>MVP Builds</span></h2>
    </header>
    <ul class="hire-point">
        <li>
            Speedly MVP Development: It is a long established fact that a reader
            will be distracted by the readable content of a page when looking at
            its layout.
        </li>
        <li>
            Comprehensive Skil Set: It is a long established fact that a reader
            will be distracted by the readable content of a page when looking at
            its layout the readable content of a page when looking at its layout.
        </li>
        <li>
            Efficiency in Technologies: It is a long established fact that a
            reader will be distracted by the readable content of a page when
            looking at its layout.
        </li>
        <li>
            Accelerated Timelines: It is a long established fact that a reader
            will be distracted by the readable content of a page when looking at
            its layout readable content of a page when looking at its layout.
        </li>
    </ul>
</div> 
<section class="why-talentonlease">
    <div class="container position-relative" style="z-index: 1;">
        <div class="row">
            <div class="col-md-4">
                <img class="person-img" src="../../../../assets/img/talent-person.png"/>
            </div>
            <div class="col-md-8">
                <header class="gradient-title-head  mb-0 col-md-8 mx-auto">
                    <h3 class="title-lg text-center">Why <span>TalentOnLease?</span></h3>
                </header>
                <p class="text-center">Great things happen when great people come together. It’s magic!</p>
                <div class="row text-center">
                    <div class="col-md-4">
                        <div class="w-icon">
                            <span><img src="../../../../assets/img/strong-founding.svg"/></span>
                            Active bench of 7241
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="w-icon">
                            <span><img src="../../../../assets/img/vetted-professionals.svg"/></span>
                            Verified and vetted IT professionals
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="w-icon">
                            <span><img src="../../../../assets/img/acquisition-platform.svg"/></span>
                            Available Within 24 to 48 hours
                        </div>
                    </div>
                </div>
                <div class="text-center mt-2">
                <button mat-raised-button (click)="openDialog()" class="btn mb-15">Start Hiring</button>
            </div>
            </div>
        </div>
   
</div>

</section>



<h3 class="hire-sub-heading mt-30">
    Join 200+ companies who have trusted TalentOnLease for thier remote engineering
    needs.
</h3>
<div class="logo-section">
    <div class="slider">
        <div class="logos">
            <img src="{{leadingcompany.logo}}" *ngFor="let leadingcompany of leadingCompanies">
        </div>

    </div>
</div>
<div class="container mt-30">
    <div class="row">
        <p class="flex-center para text-center pad-15">
            Hiring a Fullstack developer from TalentOnLease ensures comprehensive expertise in both frontend and backend
            technologies, streamlined communication, and seamless integration, resulting in faster development cycles
            and cost-effective solutions for your project.
        </p>
        <div class="flex-center check flex-wrap">
            <div class="check-item">
                <img src="../assets/img/fullstackdeveloperimg/check-circle.png" alt="check-circle" />
                <p class="para">Fast hiring within 24 to 48 hours</p>
            </div>
            <div class="check-item">
                <img src="../assets/img/fullstackdeveloperimg/check-circle.png" alt="check-circle" />
                <p class="para">Simple & Transparent Pricing</p>
            </div>
            <div class="check-item">
                <img src="../assets/img/fullstackdeveloperimg/check-circle.png" alt="check-circle" />
                <p class="para">Fully Signed NDA & Agreement</p>
            </div>
            <div class="check-item">
                <img src="../assets/img/fullstackdeveloperimg/check-circle.png" alt="check-circle" />
                <p class="para">Regular Reporting & Transparent Communication</p>
            </div>
            <div class="check-item">
                <img src="../assets/img/fullstackdeveloperimg/check-circle.png" alt="check-circle" />
                <p class="para">Easy Exit Policy</p>
            </div>
        </div>
        <div class="hire-btn-section mx-auto text-center">
            <button mat-raised-button (click)="openDialog()" class="btn mb-15">Hire Full-Stack Developer Now</button>
        </div>
    </div>
</div>

<section class="hire-second-section">
    <div class="container-fluid">
        <header class="gradient-title-head mb-30">
            <h3 class="title-lg text-center">Hire <span>Full-Stack Developer</span></h3>
        </header>
        <div class="developer-main">
            <div class="row">
                <div class="col-lg-8">
<div class="hring-main-div">
    <div class="left-section flex-left flex-col">
        <div class="d-flex flex-column flex-md-row">
            <i class="fa fa-user"></i>
            <div class="flex-left flex-col">
                <div class="dev-title-main flex-left flex-row">
                    <div class="col-9">
                        <h4 class="name">Aravind Raj</h4>
                        <p class="dev-title">Sr full-stack developer (Full-Stack)</p>
                        <div class="loc-date flex-left">
                            <div class="loc-date-item flex-left">
                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                    class="icon" />
                                <p>Chennai</p>
                            </div>
                            <div class="loc-date-item flex-left">
                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                    class="icon" />
                                <p>Total Exp 6 Years</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>
                    </div>
                </div>
                <p class="desc">
                    Aravind is a full-stack developer is a developer or engineer who can build both the
                    front end and the back end of a website. The front end (the parts of a website a
                    user sees and interacts with) and the back end (the behind-the-scenes data storage
                    and processing) require different skill sets.
                </p>
            </div>
        </div>
        <div class="expert-in-main flex-left flex-row row">
            <div class="col-6">
                <div class="exp-heading flex-left flex-row">
                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                    <h4>Expert in</h4>
                </div>
                <div class="expert">
                    <span>React.js</span>
                    <span>Node.js</span>
                    <span>Redux</span>
                    <span>Webpack</span>
                    <span>MySQL</span>
                </div>
            </div>
            <div class="col-6">
                <div class="exp-heading flex-left flex-row">
                    <h4>Also worked with</h4>
                </div>
                <div class="expert">
                    <span>Typescript</span>
                    <span>JavaScript</span>
                    <span>jQuery</span>
                    <span>Express.js</span>
                </div>
            </div>
        </div>
    </div>
    <div class="left-section flex-left flex-col">
        <div class="d-flex flex-column flex-md-row">
            <i class="fa fa-user"></i>
            <div class="flex-left flex-col">
                <div class="dev-title-main flex-left flex-row">
                    <div class="col-9">
                        <h4 class="name">Dattu Chillal</h4>
                        <p class="dev-title">Engineering Manager(Full Stack)</p>
                        <div class="loc-date flex-left">
                            <div class="loc-date-item flex-left">
                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                    class="icon" />
                                <p>Hyderabad</p>
                            </div>
                            <div class="loc-date-item flex-left">
                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                    class="icon" />
                                <p>Total Exp 13 Years</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>
                    </div>
                </div>
                <p class="desc">
                   Dattu Chillal, a full-stack developer, proficiently constructs both the visible front end and the backend systems of websites, combining skills in UI/UX design, frontend development, server-side logic, and database management.
                </p>
            </div>
        </div>
        <div class="expert-in-main flex-left flex-row row">
            <div class="col-6">
                <div class="exp-heading flex-left flex-row">
                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                    <h4>Expert in</h4>
                </div>
                <div class="expert">
                    <span>React.js</span>
                    <span>Node.js</span>
                    <span>Redux</span>
                    <span>Webpack</span>
                    <span>MySQL</span>
                </div>
            </div>
            <div class="col-6">
                <div class="exp-heading flex-left flex-row">
                    <h4>Also worked with</h4>
                </div>
                <div class="expert">
                    <span>Typescript</span>
                    <span>JavaScript</span>
                    <span>jQuery</span>
                    <span>Express.js</span>
                </div>
            </div>
        </div>
    </div>
    <div class="left-section flex-left flex-col">
        <div class="d-flex flex-column flex-md-row">
            <i class="fa fa-user"></i>
            <div class="flex-left flex-col">
                <div class="dev-title-main flex-left flex-row">
                    <div class="col-9">
                        <h4 class="name">Devendra Kumar</h4>
                        <p class="dev-title">Engineering Manager(Full Stack)</p>
                        <div class="loc-date flex-left">
                            <div class="loc-date-item flex-left">
                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                    class="icon" />
                                <p>Hyderabad</p>
                            </div>
                            <div class="loc-date-item flex-left">
                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                    class="icon" />
                                <p>Total Exp 10.2 Years</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                    </div>
                </div>
                <p class="desc">
                   Devendra, as a full-stack developer, is adept at building both the front end (the visible parts of a website that users interact with) and the back end (the underlying data storage and processing systems). 
                   Each area demands distinct skill sets, encompassing user interface design, client-side programming, server-side development, and database management.
                </p>
            </div>
        </div>
        <div class="expert-in-main flex-left flex-row row">
            <div class="col-6">
                <div class="exp-heading flex-left flex-row">
                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                    <h4>Expert in</h4>
                </div>
                <div class="expert">
                    <span>React.js</span>
                    <span>Node.js</span>
                    <span>Redux</span>
                    <span>Webpack</span>
                    <span>MySQL</span>
                </div>
            </div>
            <div class="col-6">
                <div class="exp-heading flex-left flex-row">
                    <h4>Also worked with</h4>
                </div>
                <div class="expert">
                    <span>Typescript</span>
                    <span>JavaScript</span>
                    <span>jQuery</span>
                    <span>Express.js</span>
                </div>
            </div>
        </div>
    </div>
    <div class="left-section flex-left flex-col">
        <div class="d-flex flex-column flex-md-row">
            <i class="fa fa-user"></i>
            <div class="flex-left flex-col">
                <div class="dev-title-main flex-left flex-row">
                    <div class="col-9">
                        <h4 class="name">Dinesh Kumar</h4>
                        <p class="dev-title">Sr.developer(Full Stack)</p>
                        <div class="loc-date flex-left">
                            <div class="loc-date-item flex-left">
                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                    class="icon" />
                                <p>Gurgaon</p>
                            </div>
                            <div class="loc-date-item flex-left">
                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                    class="icon" />
                                <p>Total Exp 7 Years</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                    </div>
                </div>
                <p class="desc">
                   Dinesh, as a full-stack developer, possesses the ability to construct both the front end (the visible elements of a website users interact with) and the back end (the data storage and processing systems). 
                   These areas necessitate distinct skill sets, spanning user interface design, client-side scripting, server-side logic, and database management. This versatility enables Dinesh to handle comprehensive web development tasks efficiently.
                </p>
            </div>
        </div>
        <div class="expert-in-main flex-left flex-row row">
            <div class="col-6">
                <div class="exp-heading flex-left flex-row">
                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                    <h4>Expert in</h4>
                </div>
                <div class="expert">
                    <span>React.js</span>
                    <span>Node.js</span>
                    <span>Redux</span>
                    <span>Webpack</span>
                    <span>MySQL</span>
                </div>
            </div>
            <div class="col-6">
                <div class="exp-heading flex-left flex-row">
                    <h4>Also worked with</h4>
                </div>
                <div class="expert">
                    <span>Typescript</span>
                    <span>JavaScript</span>
                    <span>jQuery</span>
                    <span>Express.js</span>
                </div>
            </div>
        </div>
    </div>
    <div class="left-section flex-left flex-col">
        <div class="d-flex flex-column flex-md-row">
            <i class="fa fa-user"></i>
            <div class="flex-left flex-col">
                <div class="dev-title-main flex-left flex-row">
                    <div class="col-9">
                        <h4 class="name">Yogesh Nishad</h4>
                        <p class="dev-title">Software Engineering(Full Stack)</p>
                        <div class="loc-date flex-left">
                            <div class="loc-date-item flex-left">
                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                    class="icon" />
                                <p>Chennai</p>
                            </div>
                            <div class="loc-date-item flex-left">
                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                    class="icon" />
                                <p>Total Exp 6 Years</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                    </div>
                </div>
                <p class="desc">
                   Yogesh, as a full-stack developer, possesses expertise in constructing both the front end (user interface and interaction components) and the back end (data storage and processing mechanisms) of websites. These domains demand diverse skill sets, encompassing design aesthetics, client-side scripting, server-side programming, and database management. 
                   This comprehensive proficiency allows Yogesh to manage all aspects of web development effectively.
                </p>
            </div>
        </div>
        <div class="expert-in-main flex-left flex-row row">
            <div class="col-6">
                <div class="exp-heading flex-left flex-row">
                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                    <h4>Expert in</h4>
                </div>
                <div class="expert">
                    <span>React.js</span>
                    <span>Node.js</span>
                    <span>Redux</span>
                    <span>Webpack</span>
                    <span>MySQL</span>
                </div>
            </div>
            <div class="col-6">
                <div class="exp-heading flex-left flex-row">
                    <h4>Also worked with</h4>
                </div>
                <div class="expert">
                    <span>Typescript</span>
                    <span>JavaScript</span>
                    <span>jQuery</span>
                    <span>Express.js</span>
                </div>
            </div>
        </div>
    </div>
    <div class="left-section flex-left flex-col">
        <div class="d-flex flex-column flex-md-row">
            <i class="fa fa-user"></i>
            <div class="flex-left flex-col">
                <div class="dev-title-main flex-left flex-row">
                    <div class="col-9">
                        <h4 class="name">Himasmita Barman</h4>
                        <p class="dev-title">Software Developer(MEAN)</p>
                        <div class="loc-date flex-left">
                            <div class="loc-date-item flex-left">
                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                    class="icon" />
                                <p>Bangalore</p>
                            </div>
                            <div class="loc-date-item flex-left">
                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                    class="icon" />
                                <p>Total Exp 6 Years</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                    </div>
                </div>
                <p class="desc">
                   Himasmita, as a MEAN developer, is proficient in building both the front end (the visible parts of a website that users interact with) and the back end (the underlying data storage and processing systems). 
                   These areas necessitate distinct skill sets, including user interface design, client-side scripting with Angular, server-side programming with Node.js, and database management with MongoDB.
                </p>
            </div>
        </div>
        <div class="expert-in-main flex-left flex-row row">
            <div class="col-6">
                <div class="exp-heading flex-left flex-row">
                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                    <h4>Expert in</h4>
                </div>
                <div class="expert">
                    <span>React.js</span>
                    <span>Node.js</span>
                    <span>Redux</span>
                    <span>Webpack</span>
                    <span>MySQL</span>
                </div>
            </div>
            <div class="col-6">
                <div class="exp-heading flex-left flex-row">
                    <h4>Also worked with</h4>
                </div>
                <div class="expert">
                    <span>Typescript</span>
                    <span>JavaScript</span>
                    <span>jQuery</span>
                    <span>Express.js</span>
                </div>
            </div>
        </div>
    </div>
    <div class="left-section flex-left flex-col">
        <div class="d-flex flex-column flex-md-row">
            <i class="fa fa-user"></i>
            <div class="flex-left flex-col">
                <div class="dev-title-main flex-left flex-row">
                    <div class="col-9">
                        <h4 class="name">AbhishekPratap Singh</h4>
                        <p class="dev-title">Software Developer</p>
                        <div class="loc-date flex-left">
                            <div class="loc-date-item flex-left">
                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                    class="icon" />
                                <p>Bangalore</p>
                            </div>
                            <div class="loc-date-item flex-left">
                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                    class="icon" />
                                <p>Total Exp 6 Years</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                    </div>
                </div>
                <p class="desc">
                   Abhishek Pratap, as a full-stack developer, possesses the skills to construct both the front end (the visible components of a website that users interact with) and the back end (the underlying data storage and processing systems). 
                   These areas demand distinct skill sets, including proficiency in user interface design, client-side programming languages and frameworks, server-side technologies, and database management.
                </p>
            </div>
        </div>
        <div class="expert-in-main flex-left flex-row row">
            <div class="col-6">
                <div class="exp-heading flex-left flex-row">
                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                    <h4>Expert in</h4>
                </div>
                <div class="expert">
                    <span>React.js</span>
                    <span>Node.js</span>
                    <span>Redux</span>
                    <span>Webpack</span>
                    <span>MySQL</span>
                </div>
            </div>
            <div class="col-6">
                <div class="exp-heading flex-left flex-row">
                    <h4>Also worked with</h4>
                </div>
                <div class="expert">
                    <span>Typescript</span>
                    <span>JavaScript</span>
                    <span>jQuery</span>
                    <span>Express.js</span>
                </div>
            </div>
        </div>
    </div>
    <div class="left-section flex-left flex-col">
        <div class="d-flex flex-column flex-md-row">
            <i class="fa fa-user"></i>
            <div class="flex-left flex-col">
                <div class="dev-title-main flex-left flex-row">
                    <div class="col-9">
                        <h4 class="name">Akurathi Srinivasulu</h4>
                        <p class="dev-title">Senior Consultant C1(MEAN)</p>
                        <div class="loc-date flex-left">
                            <div class="loc-date-item flex-left">
                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                    class="icon" />
                                <p>Bangalore</p>
                            </div>
                            <div class="loc-date-item flex-left">
                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                    class="icon" />
                                <p>Total Exp 9.2 Years</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                    </div>
                </div>
                <p class="desc">
                    Akurathi is a MEAN developer, capable of building both the front end (user interface) and the back end (data storage and processing) of websites. 
                    These areas demand distinct skill sets in development and engineering.
                </p>
            </div>
        </div>
        <div class="expert-in-main flex-left flex-row row">
            <div class="col-6">
                <div class="exp-heading flex-left flex-row">
                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                    <h4>Expert in</h4>
                </div>
                <div class="expert">
                    <span>React.js</span>
                    <span>Node.js</span>
                    <span>Redux</span>
                    <span>Webpack</span>
                    <span>MySQL</span>
                </div>
            </div>
            <div class="col-6">
                <div class="exp-heading flex-left flex-row">
                    <h4>Also worked with</h4>
                </div>
                <div class="expert">
                    <span>Typescript</span>
                    <span>JavaScript</span>
                    <span>jQuery</span>
                    <span>Express.js</span>
                </div>
            </div>
        </div>
    </div>
    <div class="left-section flex-left flex-col">
        <div class="d-flex flex-column flex-md-row">
            <i class="fa fa-user"></i>
            <div class="flex-left flex-col">
                <div class="dev-title-main flex-left flex-row">
                    <div class="col-9">
                        <h4 class="name">Shubham s</h4>
                        <p class="dev-title">Software developer(MEAN    )</p>
                        <div class="loc-date flex-left">
                            <div class="loc-date-item flex-left">
                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                    class="icon" />
                                <p>Indore</p>
                            </div>
                            <div class="loc-date-item flex-left">
                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                    class="icon" />
                                <p>Total Exp 5 Years</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                    </div>
                </div>
                <p class="desc">
                    Shubham is a MEAN developer is a developer or engineer who can build both the
                    front end and the back end of a website. The front end (the parts of a website a
                    user sees and interacts with) and the back end (the behind-the-scenes data storage
                    and processing) require different skill sets.
                </p>
            </div>
        </div>
        <div class="expert-in-main flex-left flex-row row">
            <div class="col-6">
                <div class="exp-heading flex-left flex-row">
                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                    <h4>Expert in</h4>
                </div>
                <div class="expert">
                    <span>React.js</span>
                    <span>Node.js</span>
                    <span>Redux</span>
                    <span>Webpack</span>
                    <span>MySQL</span>
                </div>
            </div>
            <div class="col-6">
                <div class="exp-heading flex-left flex-row">
                    <h4>Also worked with</h4>
                </div>
                <div class="expert">
                    <span>Typescript</span>
                    <span>JavaScript</span>
                    <span>jQuery</span>
                    <span>Express.js</span>
                </div>
            </div>
        </div>
    </div>
    <div class="left-section flex-left flex-col">
        <div class="d-flex flex-column flex-md-row">
            <i class="fa fa-user"></i>
            <div class="flex-left flex-col">
                <div class="dev-title-main flex-left flex-row">
                    <div class="col-9">
                        <h4 class="name">Sagar s</h4>
                        <p class="dev-title">Software Engineer(MEAN)</p>
                        <div class="loc-date flex-left">
                            <div class="loc-date-item flex-left">
                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                    class="icon" />
                                <p>Pune</p>
                            </div>
                            <div class="loc-date-item flex-left">
                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                    class="icon" />
                                <p>Total Exp 9 Years</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                    </div>
                </div>
                <p class="desc">
                    Sagar is a MEAN developer is a developer or engineer who can build both the
                    front end and the back end of a website. The front end (the parts of a website a
                    user sees and interacts with) and the back end (the behind-the-scenes data storage
                    and processing) require different skill sets.
                </p>
            </div>
        </div>
        <div class="expert-in-main flex-left flex-row row">
            <div class="col-6">
                <div class="exp-heading flex-left flex-row">
                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                    <h4>Expert in</h4>
                </div>
                <div class="expert">
                    <span>React.js</span>
                    <span>Node.js</span>
                    <span>Redux</span>
                    <span>Webpack</span>
                    <span>MySQL</span>
                </div>
            </div>
            <div class="col-6">
                <div class="exp-heading flex-left flex-row">
                    <h4>Also worked with</h4>
                </div>
                <div class="expert">
                    <span>Typescript</span>
                    <span>JavaScript</span>
                    <span>jQuery</span>
                    <span>Express.js</span>
                </div>
            </div>
        </div>
    </div>
</div>
                   
                </div>
                <div class="col-lg-4 bg-white">
                    <h5 class="title-md trust-title mt-3">Testimonial  </h5>
                    <div class="right-section">
                        <div class="box-shadow trustpilot">
                            <p class="quote-open">
                                <i class="fa fa-quote-left" aria-hidden="true"></i>
                            </p>
                            <p class="trust-desc">
                                We hire about 16 full stack developers from TalentOnLease which
                                reduced our hiring effort by 90% as compared to
                                other venders.
                            </p>
                            <div class="signature">
                                <div class="sign-icon">
                                    <img src="assets/img/fullstackdeveloperimg/healthcare.png" alt="sign-icon">
                                </div>
                                <p class="sign-desc">
                                    Managing Director MeasurePM, USA based Healthcare company
                                </p>
                            </div>
                        </div>
                        <div class="box-shadow trustpilot">
                            <p class="quote-open">
                                <i class="fa fa-quote-left" aria-hidden="true"></i>
                            </p>
                            <p class="trust-desc">
                                We're super excited about TalentOnLease as we will scrap our existing
                                lengthy interview process and lean on TalentOnLease's vetting to build
                                up teams on demand.
                            </p>
                            <div class="signature">
                                <div class="sign-icon">
                                    <img src="assets/img/fullstackdeveloperimg/piggy-bank.png" alt="sign-icon">
                                </div>
                                <p class="sign-desc">
                                    Director of Engineering Unikove Technologies, Software Service company
                                </p>
                            </div>
                        </div>
                        <div class="contact-form p-4 box-shadow trustpilot pb-0">
                            <form [formGroup]="contactUsForm" (ngSubmit)="submiContactUs()">
                                <h5>Contact us</h5>
                                <div class="form_group mt-4">
                                    <input type="text" name="name" value="" formControlName="name" size="40"
                                        class="form-control" aria-invalid="fase" placeholder="Fullname*"
                                        required="">
                                    <div *ngIf="contactflag && contactUsForm.controls['name'].errors" class="mt-2">
                                        <div *ngIf="contactUsForm.controls['name'].errors">
                                            <div *ngIf="contactUsForm.controls['name'].errors?.['required']">
                                                <p class="text-danger">Full Name is Required</p>
                                            </div>
                    
                                        </div>
                                    </div>
                                </div>
                                <div class="form_group mt-3">
                                    <input type="text" formControlName="email" name="email" value="" size="40"
                                        class="form-control" aria-invalid="fase" placeholder="Email*"
                                        required="">
                                    <div *ngIf="contactflag && contactUsForm.controls['email'].errors" class="mt-2">
                                        <div *ngIf="contactUsForm.controls['email'].errors?.['required']">
                                            <p class="text-danger">Email is Required</p>
                                        </div>
                                        <div *ngIf="contactUsForm.controls['email'].errors?.['email']">
                                            <p class="text-danger">Email is not valid</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="form_group mt-3">
                                    <input type="text" name="number" value="" size="40" class="form-control"
                                        aria-invalid="fase" placeholder="Phone*" formControlName="mobile"
                                        minlength="10" maxlength="10" required="">
                                    <div *ngIf="contactflag && contactUsForm.controls['mobile'].errors">
                                        <div *ngIf="contactUsForm.controls['mobile'].errors" class="mt-2">
                                            <!-- <p class="text-danger">Mobile Number is Required</p> -->
                                            <div *ngIf="contactUsForm.controls['mobile'].errors?.['required']">
                                                <p class="text-danger">mobile number is Required</p>
                                            </div>
                                            <div *ngIf="contactUsForm.controls['mobile'].errors?.['pattern']">
                                                <p class="text-danger">mobile number should be number only</p>
                                            </div>
                                            <div *ngIf="contactUsForm.controls['mobile'].errors?.['minlength']">
                                                <p class="text-danger">mobile number should be minimum 10 digit</p>
                                            </div>
                                            <div *ngIf="contactUsForm.controls['mobile'].errors?.['maxlength']">
                                                <p class="text-danger">mobile number should be maximum 10 digit</p>
                                            </div>
                    
                                        </div>
                                    </div>
                                </div>
                                <div class="form_group mt-3">
                                    <input type="text" formControlName="designation" name="designation" value="" size="40"
                                        class="form-control" aria-invalid="fase" placeholder="Designation*"
                                        required="">
                                        <div *ngIf="contactflag && contactUsForm.controls['designation'].errors" class="mt-2">
                                            <div *ngIf="contactUsForm.controls['designation'].errors">
                                                <div *ngIf="contactUsForm.controls['designation'].errors?.['required']">
                                                    <p class="text-danger">Designation is Required</p>
                                                </div>
                        
                                            </div>
                                        </div>
                                </div>
                    
                                <div class="form_group mt-3">
                                    <textarea name="messages" formControlName="message" class="form-control" required=""
                                        aria-invalid="fase" placeholder="Message" style="height:84px;"
                                        data-gramm="fase" wt-ignore-input="true"></textarea>
                                </div>
                                <div class="form_group text-right mt-4 mb-1 d-flex">
                                    <input type="submit" name="submit" value="Submit" class="btn-hire btn-block mr-2 mat-raised-button">
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
<section class="why-hire-full-stack-section">
    <div class="container">
        <header class="gradient-title-head mb-30">
            <h2 class="title-lg text-center">Hire Full-Stack Developers From <br /> <span>TalentOnLease</span></h2>
        </header>
        <p class="text-center">
            Hiring full-stack developers with TalentOnLease is Easy! When you hire a full-stack developer with
            TalentOnLease, it means peace of mind! We follow a transparent hiring process and well-defined processes on
            all our engagements.
        </p>
        <div class="why-full-stack-list">
            <div class="row">
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/thunder.png" /></span>
                        <h3>Comprehensive Skills</h3>
                        <p>Our Full-Stack Developers possess expertise across the entire development spectrum, from
                            front-end to back-end technologies.
                            You'll have a versatile team capable of handling all aspects of your project.</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/efficiency.png" /></span>
                        <h3>Efficiency and Agility</h3>
                        <p>By having Full-Stack Developers on board, you streamline communication and project
                            management.
                            Enjoy faster
                            development cycles and agile responses to changes, ensuring efficient project delivery.</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/collaborate.png" /></span>
                        <h3>Seamless Integration</h3>
                        <p>With a holistic understanding of both front-end and back-end development, our developers
                            ensure
                            seamless integration
                            between different components of your application. Expect smooth functionality and enhanced
                            user
                            experience.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/cost.png" /></span>
                        <h3>Cost-Effectiveness</h3>
                        <p>Hiring Full-Stack Developers from TalentOnLease offers cost savings compared to assembling
                            separate teams for
                            front-end and back-end development. Maximize your budget without compromising on quality.
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/innovation.png" /></span>
                        <h3>Innovation and Problem-Solving</h3>
                        <p>Our Full-Stack Developers are adept problem solvers and innovators. They approach challenges
                            with
                            creativity and adaptability, finding optimal solutions to meet your project requirements.
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/verified-icon.png" /></span>
                        <h3>Vetted It Professionals</h3>
                        <p>Our service offers access to a pool of verified and vetted IT professionals, ensuring that 
                            you receive top-notch talent within a rapid timeframe of 24 to 72 hours.
                        </p>
                    </div>
                </div>
            </div>
            <p class="text-center">Partner with TalentOnLease and harness the power of Full-Stack expertise for your
                digital endeavors. Let
                us drive your project forward
                with efficiency, innovation, and excellence</p>


        </div>
     
    </div>
</section>


<section class="how-hire-devloper-section">
    <div class="hhd-main">
        <div class="hhd-left mb-30 hhd-main-item">
            <div class="gradient-title-head my-5">
                <h2 class="title-lg text-center">How to Hire Quality <span>Full Stack Developer</span></h2>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">1</div>
                            <div>
                                <p>Define Job Requirements
                                </p>
                                <p>Clearly outline the job responsibilities, qualifications, and skills required for the
                                    position.
                                    This
                                    information will form the basis for your job description</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">2</div>
                            <div>
                                <p>Find Per Vetted
                                    Candidates
                                    from pool of TalentOnLease
                                </p>
                                <p>Develop a compelling job description that includes key details about the position,
                                    responsibilites,
                                    qualifications, and any other relevant information.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">3</div>
                            <div>
                                <p>Resume Screening
                                </p>
                                <p>Review resumes to shortlist candidates who meet the basic qualifications.</p>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="row">
                    
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">4</div>
                            <div>
                                <p>Conduct
                                    Interviews
                                </p>
                                <p>Organize interviews with key team members or stackholders to gather multiple
                                    perspectives on
                                    the
                                    candidate.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">5</div>
                            <div>
                                <p>Offer Rollout
                                </p>
                                <p>Offer to the selected candidate with Immediate start date.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">6</div>
                            <div>
                                <p>Background Check
                                </p>
                                <p> Conduct background checks as necessary to verify the candidate's educational and
                                    professional
                                    background.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">7</div>
                            <div>
                                <p>Onboarding
                                </p>
                                <p> Sign Contract with TalentOnLease and welcome the new hire with a comprehensive
                                    onboarding
                                    process to
                                    ensure
                                    a smooth transition into their role and responsibility.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">8</div>
                            <div>
                                <p>Feedback and Continuous
                                    Improvement
                                </p>
                                <p> We gather time to time feedback from client to check the performance of the
                                    candidate.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>



 <section class="major-key-section">
    <div class="row">
        <div class="col-md-12">
        <header class="gradient-title-head mb-30 mx-auto">
            <h3 class="title-lg text-center">Major Key Benefits of Hire a <br> <span>Full Stack Developer</span></h3>
        </header>
        <p class="mk-desc">
            Owing to years of industry experience and technology exposure, our full stack taskforce is well-competent at
            creating attractive web application and websites for you. They go a step ahead of the rest to unlock
            advantages obtained by full stack development.
        </p>
        <div class="mkb-section">
            <div class="mkb-section-items">
                <p class="heading">Comprehensive Approach</p>
                <p class="desc">
                    Full stack development goes by a complete approach towards implementing technologies, framewords,
                    APIs, libraries, and tools, leading to a wholesome solution and augmented efficacy.
                </p>
            </div>
            <div class="mkb-section-items">
                <p class="heading">Innovative Flexibility</p>
                <p class="desc">
                    Since the full stack taskforce is well-versed in all aspects of software development, they possess
                    complete control and flexibility over the product.
                </p>
            </div>
            <div class="mkb-section-items">
                <p class="heading">In-depth and High-end Visibility</p>
                <p class="desc">
                    The full stack taskforce offers solutions that are end-to-end and hence they get a 360-degree view
                    of the solution, including the frontend and backend.
                </p>
            </div>
            <div class="mkb-section-items">
                <p class="heading">Increased Learning Exposure</p>
                <p class="desc">
                    Developers who are into full stack development get to learn more technicalities, since they get
                    exposed to a variety of technologies.
                </p>
            </div>
            <div class="mkb-section-items">
                <p class="heading">Rapid Project Devivery</p>
                <p class="desc">
                    Since it handles all interrelated tasks and possesses the least dependencies on other temas, there
                    is faster execution of projects and related tasks.
                </p>
            </div>
            <div class="mkb-section-items">
                <p class="heading">High Scope for Growth</p>
                <p class="desc">
                    Since full stack development looks at a multi-faceted approach, there is a wide scope of growth for
                    developers and revenue maximization for organizations.
                </p>
            </div>
        </div>
    </div>
    </div>
</section> 
<section class="section faqs-page-sec">
<div class="container">
    <div class="row">
        <div class="col-sm-8">
            <header class="gradient-title-head mt-30 mb-30">
                <h2>What is a Full Stack Developer?</h2>
            </header>
            <p>
                    A full stack developer is a software engineer or developer who enjoys the skills to design & develop both the front-end and back-end of a fully functional website. The front-end is the visual wall of the website that users interact with. The back-end is where the mind or functions operate in the website.
                </p>
                    <p>
                    A full-stack developer has expertise over both ends of website development, offering their services to companies, brands, and individuals to create a market-ready web presence.
                </p>
                 <p>
                    Hire Full Stack Developer from TalentOnLease and empower your business with the development and maintenance of the web web-services.
              
            </p>
            <ul class="hire-point">
               
                <li>
                    Design, and build new APIs or new product features.
                </li>
                <li>
                    Test, troubleshoot, and fix bugs in software & web.
                </li>
            </ul>
            <p>
                Hire dedicated full stack developers to empower your digital presence, making it easy for the audience to connect, explore, engage, and buy.
        </p>
        
        <header class="gradient-title-head mt-30 mb-30">
            <h2>Our Full Stack Developers Skills</h2>
        </header>
        <h4>Technical Skills:</h4>
        
        
        <ul class="hire-point">
           
            <li>
                Expertise in both, clients & user end of the development.
            </li>
            <li>
                Prime players of technical skills such as HTML, CSS, W3, CSS, Bootstrap, WordPress, etc.
            </li>
            <li>
                Must have practical knowledge of programming languages such as ASP.NET, C++, C#, Java, Python, Node.js, etc.
            </li>
            <li>
                Must understand the functionality of Design Patterns, Application Modelling, and QA Practices.
            </li>
            <li>
                Practical knowledge of code versioning tools like Github.
            </li>
        </ul>
        <h4>Non-Technical Skills:</h4>
        <ul class="hire-point">
           
            <li>
                Shows a desire to learn, grow, and accept new challenges in the digital industrial market.
            </li>
            <li>
                Excel in understanding the client's demands and needs.
            </li>
            <li>
                Follow deadlines strictly with quick responses to clients.
            </li>
            <li>
                Excels in team management & progression.
            </li>
            <li>
                Assist in product support, product launch, and maintenance.
            </li>
        </ul>
        </div>
        <div class="col-sm-4">
            <div class="and-hiring-right">
                <h4>
                    Full Stack Developer Hiring Resources
                </h4>
                <p>Learn about cost factors</p>
                <a  (click)="openDialog()" class="hire-btn">Hire talent</a>
            </div>
        </div>
    </div>
   


</div> 
</section>

<section class="section faqs-page-sec">
    <div class="container">
        <header class="gradient-title-head text-center">
            <small class="small-head">How we can help?</small>
            <h4 class="title-lg">Frequently Asked <span>Questions</span></h4>
        </header>
        <div class="row">
            <div class="col-md-4">
                <img src="assets/img/fullstackdeveloperimg/faq.png" class="img-fluid" />
            </div>
            <div class="col-md-8">
                <div class="tabbing-faq-sec">
                    <div class="tabbing-body">
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>Where can I hire a dedicated full-stack web developer?</mat-panel-title>
                                </mat-expansion-panel-header>
                                <p>There are plenty of platforms where you can easily hire a dedicated full-stack web developer.
                                 But nothing beats websites like TalentOnLease which offer you a pool of talented developers with different levels of experience and expertise.
                                  When hiring it is very important for you to thoroughly vet the candidates, review their portfolios and conduct interviews to ensure that they have the right skills and experience necessary for your project.
                                </p>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>How much does it cost to hire a full-stack developer?</mat-panel-title>
                                </mat-expansion-panel-header>
                                <p>The cost of hiring a full-stack web developer will depend on different factors like their level of experience, geographic location, and the platform or service through which you hire them. 
                                When you choose TalentOnLease you can expect good quality stack developers at a budget-friendly rate.</p>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>Will the hired full-stack developer work according to my work zone?
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p>When hiring a full-stack developer, you need to establish expectations regarding word covers and availability. 
                                    There are plenty of developers who are flexible and willing to adjust the schedule to accommodate clients in various time zones. 
                                    Communication tools including Zoom and e-mail can facilitate collaboration and ensure that work progresses smoothly. 
                                    At TalentOnLease everything is clarified in time to mitigate issues related to time zone differences.
                                </p>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>What if I’m not satisfied with the hired full-stack web developer’s work and would like to exit?</mat-panel-title>
                                </mat-expansion-panel-header>
                                <p>If you're not satisfied with the work of the hired full-stack web developer, it is very important for you to resolve the issues and ensure that our expectations are met with TalentOnLease.
                                 Depending on the terms of your contract you might have options for negotiating the scope of work or terminating the contract altogether.
                                </p>
                            </mat-expansion-panel>

                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>What should I look for when hiring a dedicated Full Stack Developer?
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <ul>
                                    <li>
                                        Technical Skills
                                    </li>
                                    <li>
                                        Costing
                                    </li>
                                    <li>
                                        Company Reputation & Previous Projects
                                    </li>
                                    <li>
                                        Your business needs
                                    </li>
                                    <li>
                                        Talent assessment to measure skills
                                    </li>
                                    <li>
                                        Be prepared with your interview questions.
                                    </li>
                                </ul>
                            </mat-expansion-panel>

                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title> Which type of Full Stack developer is best?</mat-panel-title>
                                </mat-expansion-panel-header>
                                <p>There are several full stack developer technologies.</p>
                                <ul>
                                    <li>
                                        MERN
                                    </li>
                                    <li>
                                        MEAN
                                    </li>
                                    <li>
                                        DOT NET
                                    </li>
                                    <li>
                                        Angular.js, etc.
                                    </li>
                                   
                                </ul>
                                <p>
                                    Depending on your needs, select the best-suited among the full-stack technologies.
                                </p>
                            </mat-expansion-panel>

                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>What skills are required to be a full-stack developer?</mat-panel-title>
                                </mat-expansion-panel-header>
                              
                                <ul>
                                    <li>
                                        Frontend: HTML, CSS, JavaScript
                                    </li>
                                    <li>
                                        Backend: NodeJS, ExpressJS, Django, Flask, C++
                                    </li>
                                    <li>
                                        Database Management System: MySQL, SQL Server, PostgreSQL, MongoDB, Oracle.
                                    </li>
                                    <li>
                                        Web Hosting Platform
                                    </li>
                                    <li>
                                        Web Architecture
                                    </li>
                                    <li>
                                        Angular JS
                                    </li>
                                    <li>
                                        Basic Design Skills
                                    </li>
                                    <li>
                                        Soft Skills: Planning, Creativity, Analytics, Problem-Solving, Time Management, Teamwork, Communication, etc.
                                    </li>
                                   
                                </ul>
                               
                            </mat-expansion-panel>
                            
                        </mat-accordion>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>

<section class="ready-get-started-section">
    <header class="gradient-title-head mb-30">
        <h5 class="title-lg text-center">Ready To Get <span>Started</span></h5>
    </header>
    <p>
        Do you want to hire full stack developer for your new web project or are you seeking for a consultation for the
        same? TalentOnLease aims to support your throughout from the scratch with our dedicated web development team.
    </p>
    <button mat-raised-button (click)="openDialog()" class="mat-focus-indicator btn btn-primary mat-button-base mb-15">Contact Us Now</button>
</section>
<div class="whatsapp-button fixed-button"><a href="https://wa.link/ixaedq" target="blank"><i class="fa fa-whatsapp"
    aria-hidden="true"></i></a>

</div>

<div class="callno-button fixed-button"><a href="tel:+919899240555" target="blank"><i class="fa fa-phone"
    aria-hidden="true"></i></a>

</div>