import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.css'],
  providers:[HttpClient]
})
export class ResourcesComponent {
/**
 *
 */
data:any=[];
constructor(private http:HttpClient) {
 this._getBlog();
  
}
_getBlog(){
  this.http.get(environment.blogUrl).subscribe(x=>{
      this.data=x;
  })
}
}
