import { Component, OnInit } from '@angular/core';
import { RenderService } from 'src/app/_services/render.service';

@Component({
  selector: 'campaign-thankyou',
  templateUrl: './compaign.thankyou.component.html',
  styleUrls: ['./campaign.component.css']
})
export class CampaignThankyouComponent implements OnInit {
  constructor() { }
  ngOnInit(): void {
    
  }
}
