import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Location, LocationStrategy, HashLocationStrategy} from '@angular/common';
if (environment.production) {
  enableProdMode();
}

// platformBrowserDynamic(AppModule,[
//  {provide: LocationStrategy, useClass: HashLocationStrategy}
// ]);

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
