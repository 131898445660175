    
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/css/bootstrap.min.css">
<script>
    $(document).ready(function () {
        $("#testimonial-slider").owlCarousel({

            items: 1,

            itemsDesktop: [1000, 1],

            itemsDesktopSmall: [979, 1],

            itemsTablet: [768, 1],

            pagination: true,

            navigation: false,

            navigationText: ["", ""],

            slideSpeed: 1000,

            singleItem: true,

            transitionStyle: "fade",

            autoPlay: true

        });

    });

</script>


<section class="banner-section">
    <img src="../assets/img/fullstackdeveloperimg/home-banner.jpg" class="main-banner" />
    <div class="container">
        <div class="banner-caption">
            <div class="bg-white">
                <h1> Hire React JS Developer- Give Your Ideas a Technical Melody</h1>
            </div>
            <p class="first-para">
                Your digital presence needs to be flawless like your personality, captivating user’s attention and giving them room to blend their needs. Whether it is an application, website, or software, if you have an idea that has the potential of fulfilling people or entertaining, hire React developers to make your idea a melodious reality. 
                Give your vision to the best React JS developers in India with TalentOnLease. Get a professional hand to create your enterprise-level digital technologies. 

                Your vision, Your Deals, Our Developers.


            </p>
            <div class="row email-box-main">
                <div class="col-md-6 mx-auto">
                    <button mat-raised-button (click)="openDialog()" class="btn mb-15">Hire React JS Developer Now</button>
                </div>
            </div>
            <div class="icon-text">
                <div class="icon-item">
                    <img src="../assets/img/fullstackdeveloperimg/briefcase.png" alt="briefcase" />
                    <p>200 + Client Served</p>
                </div>
                <div class="icon-item">
                    <img src="../assets/img/fullstackdeveloperimg/start-up.png" alt="Start Up" />
                    <p>500 + React JS Project Delivered</p>
                </div>
                <div class="icon-item">
                    <img src="../assets/img/fullstackdeveloperimg/check.png" alt="check" />
                    <p>7 Days Risk Free Trial</p>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="container">
    <header class="gradient-title-head mt-30 mb-30 col-md-12 mx-auto">
        <h2 class="title-lg text-center">How do React JS Developers Present a Deal for You? </h2>
    </header>
    <ul class="hire-point">
        <li>
            Component-based development creates platforms that are easy to maintain and modular. 

        </li>
        <li>
            Excellent UI/UX interface for users for sound interaction. 


        </li>
        <li>
            Dynamic data handling and UI consistency.
        </li>
        <li>
            Creates responsive applications for an enhanced user experience.
        </li>
        <li>
            Offer code reusability with libraries for scalability & modularity. 
        </li>
    </ul>
</div>
<section class="why-talentonlease">
    <div class="container position-relative" style="z-index: 1;">
        <div class="row">
            <div class="col-md-4">
                <img class="person-img" src="../../../../assets/img/talent-person.png"/>
            </div>
            <div class="col-md-8">
                <header class="gradient-title-head  mb-0 col-md-8 mx-auto">
                    <h3 class="title-lg text-center">Why <span>TalentOnLease?</span></h3>
                </header>
                <p class="text-center">Great things happen when great people come together. It’s magic!</p>
                <div class="row text-center">
                    <div class="col-md-4">
                        <div class="w-icon">
                            <span><img src="../../../../assets/img/strong-founding.svg"/></span>
                            Active bench of 7241
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="w-icon">
                            <span><img src="../../../../assets/img/vetted-professionals.svg"/></span>
                            Verified and vetted IT professionals
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="w-icon">
                            <span><img src="../../../../assets/img/acquisition-platform.svg"/></span>
                            Available Within 24 to 48 hours
                        </div>
                    </div>
                </div>
                <div class="text-center mt-2">
                <button mat-raised-button (click)="openDialog()" class="btn mb-15">Start Hiring</button>
            </div>
            </div>
        </div>
   
</div>

</section>



<p class="hire-sub-heading mt-30">
    Join 200+ companies who have trusted TalentOnLease for thier remote engineering
    needs.
</p>
<div class="logo-section">
    <div class="slider">
        <div class="logos">
            <img src="{{leadingcompany.logo}}" *ngFor="let leadingcompany of leadingCompanies">
        </div>

    </div>
</div>
<div class="container mt-30">
    <div class="row">
        <p class="flex-center para text-center pad-15">
            Hiring a React JS Developer from TalentOnLease ensures comprehensive expertise in both frontend and backend
            technologies, streamlined communication, and seamless integration, resulting in faster development cycles
            and cost-effective solutions for your project.
        </p>
        <div class="flex-center check flex-wrap">
            <div class="check-item">
                <img src="../assets/img/fullstackdeveloperimg/check-circle.png" alt="check-circle" />
                <p class="para">Fast hiring within 24 to 48 hours</p>
            </div>
            <div class="check-item">
                <img src="../assets/img/fullstackdeveloperimg/check-circle.png" alt="check-circle" />
                <p class="para">Simple & Transparent Pricing</p>
            </div>
            <div class="check-item">
                <img src="../assets/img/fullstackdeveloperimg/check-circle.png" alt="check-circle" />
                <p class="para">Fully Signed NDA & Agreement</p>
            </div>
            <div class="check-item">
                <img src="../assets/img/fullstackdeveloperimg/check-circle.png" alt="check-circle" />
                <p class="para">Regular Reporting & Transparent Communication</p>
            </div>
            <div class="check-item">
                <img src="../assets/img/fullstackdeveloperimg/check-circle.png" alt="check-circle" />
                <p class="para">Easy Exit Policy</p>
            </div>
        </div>
        <div class="hire-btn-section mx-auto">
            <button mat-raised-button (click)="openDialog()" class="btn mb-15">Hire React JS Developer Now</button>
        </div>
    </div>
</div>

<section class="hire-second-section">
    <div class="container-fluid">
        <header class="gradient-title-head mb-30">
            <h3 class="title-lg text-center">Hire <span>React JS Developer</span></h3>
        </header>
        <div class="developer-main">
            <div class="row">
                <div class="col-lg-8">
                    <div class="hring-main-div">

                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Basanaik P</h4>
                                        <p class="dev-title">React Developer(React js)</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>Bangalore</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 4 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>
                                    </div>
                                </div>
                                <p class="desc">
                                   Basanaik is a dedicated React JS developer with a passion for crafting responsive and engaging user interfaces. With [X] years of experience, he excels in leveraging React.js to build dynamic web applications. 
                                   Basanaik specializes in frontend development, focusing on component-based architecture, state management, and integrating with backend APIs. His expertise includes optimizing performance, ensuring code quality, and delivering user-centric solutions that enhance the overall user experience and meet business goals effectively.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Hitesh Sukhpal</h4>
                                        <p class="dev-title">Frontend Developer(React js)</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>Ahemdabad</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 3 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>
                                    </div>
                                </div>
                                <p class="desc">
                                  Hitesh is an innovative React JS developer known for his expertise in creating interactive and scalable user interfaces. With a deep understanding of frontend development, he specializes in state management, component-based architecture, and integrating modern UI/UX principles. 
                                  Hitesh excels in optimizing application performance, collaborating effectively in agile environments, and delivering solutions that drive engagement and meet evolving business needs.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Rohan Rana</h4>
                                        <p class="dev-title">React UI(React js)</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>Jaipur</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 6 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                                    </div>
                                </div>
                                <p class="desc">
                                    Rohan is a proficient React JS developer with a knack for building dynamic and responsive user interfaces. His expertise spans frontend development, specializing in component-based architecture, state management using Redux or Context API, and integrating with backend APIs. 
                                    Rohan excels in optimizing performance, ensuring accessibility and scalability, and delivering intuitive web applications that elevate user experience and meet business objectives effectively.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Pavan K</h4>
                                        <p class="dev-title">Software engineer(React js)</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>other</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 5.5 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                                    </div>
                                </div>
                                <p class="desc">
                                   Rohan is a skilled React JS developer proficient in creating dynamic and user-friendly web applications. With a strong foundation in frontend development, he excels in building robust UI components, managing application state using Redux or Context API, and integrating with backend services. 
                                   Rohan is dedicated to delivering high-performance, scalable solutions that enhance usability and meet the diverse needs of modern web applications.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Tushar Bhatt</h4>
                                        <p class="dev-title">React js</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>Noida</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 3 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                                    </div>
                                </div>
                                <p class="desc">
                                 Tushar is a versatile React JS developer known for his expertise in crafting responsive and interactive user interfaces. With a focus on frontend development, he excels in using React.js to build scalable components and optimize performance. 
                                 Tushar is adept at state management with Redux, integrating RESTful APIs, and implementing modern UI/UX design principles. His passion for innovation drives him to deliver cutting-edge solutions that elevate user experience and meet business objectives effectively.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Devanshu Verma</h4>
                                        <p class="dev-title">React Js(React js)</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>Noida</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 3.2 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                                    </div>
                                </div>
                                <p class="desc">
                                    Devanshu is an innovative React JS developer specializing in creating visually compelling and user-friendly interfaces. His proficiency extends to state-of-the-art frontend technologies such as React Hooks and functional components, enhancing application performance and maintainability. 
                                    Devanshu excels in integrating GraphQL for efficient data fetching, collaborating within agile teams, and delivering impactful solutions that optimize user engagement and business outcomes.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Ravinder Singh</h4>
                                        <p class="dev-title">Sr.Software Engineer(React js)</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>Noida</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 7 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                                    </div>
                                </div>
                                <p class="desc">
                                   Ravinder is a dedicated React JS developer known for his expertise in building scalable and intuitive user interfaces. With a strong focus on frontend development, he excels in utilizing React.js to create efficient components and manage application state using modern techniques like Context API. 
                                   Ravinder's skills also include optimizing performance, integrating with backend APIs, and implementing responsive design principles, ensuring seamless user experiences that drive business growth.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Hussain Ali</h4>
                                        <p class="dev-title">Sr.Frontend Developer(React js)</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>Indore</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 6 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                                    </div>
                                </div>
                                <p class="desc">
                                 Hussain is an accomplished React JS developer with a passion for creating impactful and user-centric web applications.
                                 His expertise lies in crafting elegant UI components, optimizing performance with React Hooks and functional programming, and ensuring accessibility and responsiveness across devices. Hussain excels in integrating RESTful APIs, implementing state management solutions like Redux or Context API, and delivering solutions that meet high standards of usability and innovation in the ever-evolving landscape of frontend development.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Virender Singh</h4>
                                        <p class="dev-title">MERN(React js)</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>Noida</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 3 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                                    </div>
                                </div>
                                <p class="desc">
                                    Virender is a dynamic React JS developer renowned for his proficiency in building dynamic and responsive user interfaces. 
                                    With a strong background in frontend development, he excels in crafting scalable components, optimizing performance with React Hooks, and ensuring seamless integration with backend services. Virender's expertise also encompasses state management using Redux, implementing modern UI/UX principles, and delivering innovative solutions that elevate user experiences and drive business success.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Praveen Srivastav</h4>
                                        <p class="dev-title">Sr.Software Engineer(React js)</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>New Delhi</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 13 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                                    </div>
                                </div>
                                <p class="desc">
                                   Praveen is a forward-thinking React JS developer known for his expertise in creating robust and engaging user interfaces. He specializes in leveraging React.js to build scalable components and implementing state management with Redux or Context API. 
                                   Praveen excels in optimizing performance, integrating RESTful APIs, and employing responsive design principles to deliver intuitive web applications that enhance user experience and meet business objectives effectively.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div class="col-lg-4 bg-white testimonial-main">
                    <h5 class="title-md trust-title mt-3">Testimonial  </h5>
                    <div class="right-section">
                        <div class="box-shadow trustpilot">
                            <p class="quote-open">
                                <i class="fa fa-quote-left" aria-hidden="true"></i>
                            </p>
                            <p class="trust-desc">
                                We hire about 16 React JS Developers from TalentOnLease which
                                reduced our hiring effort by 90% as compared to
                                other venders.
                            </p>
                            <div class="signature">
                                <div class="sign-icon">
                                    <img src="assets/img/fullstackdeveloperimg/healthcare.png" alt="sign-icon">
                                </div>
                                <p class="sign-desc">
                                    Managing Director MeasurePM, USA based Healthcare company
                                </p>
                            </div>
                        </div>
                        <div class="box-shadow trustpilot">
                            <p class="quote-open">
                                <i class="fa fa-quote-left" aria-hidden="true"></i>
                            </p>
                            <p class="trust-desc">
                                We're super excited about TalentOnLease as we will scrap our existing
                                lengthy interview process and lean on TalentOnLease's vetting to build
                                up teams on demand.
                            </p>
                            <div class="signature">
                                <div class="sign-icon">
                                    <img src="assets/img/fullstackdeveloperimg/piggy-bank.png" alt="sign-icon">
                                </div>
                                <p class="sign-desc">
                                    Director of Engineering Unikove Technologies, Software Service company
                                </p>
                            </div>
                        </div>
                        <div class="contact-form p-4 box-shadow trustpilot pb-0">
                            <form [formGroup]="contactUsForm" (ngSubmit)="submiContactUs()">
                                <h5>Contact us</h5>
                                <div class="form_group mt-4">
                                    <input type="text" name="name" value="" formControlName="name" size="40"
                                        class="form-control" aria-invalid="fase" placeholder="Fullname*"
                                        required="">
                                    <div *ngIf="contactflag && contactUsForm.controls['name'].errors" class="mt-2">
                                        <div *ngIf="contactUsForm.controls['name'].errors">
                                            <div *ngIf="contactUsForm.controls['name'].errors?.['required']">
                                                <p class="text-danger">Full Name is Required</p>
                                            </div>
                    
                                        </div>
                                    </div>
                                </div>
                                <div class="form_group mt-3">
                                    <input type="text" formControlName="email" name="email" value="" size="40"
                                        class="form-control" aria-invalid="fase" placeholder="Email*"
                                        required="">
                                    <div *ngIf="contactflag && contactUsForm.controls['email'].errors" class="mt-2">
                                        <div *ngIf="contactUsForm.controls['email'].errors?.['required']">
                                            <p class="text-danger">Email is Required</p>
                                        </div>
                                        <div *ngIf="contactUsForm.controls['email'].errors?.['email']">
                                            <p class="text-danger">Email is not valid</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="form_group mt-3">
                                    <input type="text" name="number" value="" size="40" class="form-control"
                                        aria-invalid="fase" placeholder="Phone*" formControlName="mobile"
                                        minlength="10" maxlength="10" required="">
                                    <div *ngIf="contactflag && contactUsForm.controls['mobile'].errors">
                                        <div *ngIf="contactUsForm.controls['mobile'].errors" class="mt-2">
                                            <!-- <p class="text-danger">Mobile Number is Required</p> -->
                                            <div *ngIf="contactUsForm.controls['mobile'].errors?.['required']">
                                                <p class="text-danger">mobile number is Required</p>
                                            </div>
                                            <div *ngIf="contactUsForm.controls['mobile'].errors?.['pattern']">
                                                <p class="text-danger">mobile number should be number only</p>
                                            </div>
                                            <div *ngIf="contactUsForm.controls['mobile'].errors?.['minlength']">
                                                <p class="text-danger">mobile number should be minimum 10 digit</p>
                                            </div>
                                            <div *ngIf="contactUsForm.controls['mobile'].errors?.['maxlength']">
                                                <p class="text-danger">mobile number should be maximum 10 digit</p>
                                            </div>
                    
                                        </div>
                                    </div>
                                </div>
                                <div class="form_group mt-3">
                                    <input type="text" formControlName="designation" name="designation" value="" size="40"
                                        class="form-control" aria-invalid="fase" placeholder="Designation*"
                                        required="">
                                        <div *ngIf="contactflag && contactUsForm.controls['designation'].errors" class="mt-2">
                                            <div *ngIf="contactUsForm.controls['designation'].errors">
                                                <div *ngIf="contactUsForm.controls['designation'].errors?.['required']">
                                                    <p class="text-danger">Designation is Required</p>
                                                </div>
                        
                                            </div>
                                        </div>
                                </div>
                    
                                <div class="form_group mt-3">
                                    <textarea name="messages" formControlName="message" class="form-control" required=""
                                        aria-invalid="fase" placeholder="Message" style="height:84px;"
                                        data-gramm="fase" wt-ignore-input="true"></textarea>
                                </div>
                                <div class="form_group text-right mt-4 mb-1 d-flex">
                                    <input type="submit" name="submit" value="Submit" class="btn-hire btn-block mr-2 mat-raised-button">
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
<section class="why-hire-full-stack-section">
    <div class="container">
        <header class="gradient-title-head mb-30">
            <h2 class="title-lg text-center">Hire React JS Developer from TalentOnLease- What Do We Have For You?</h2>
        </header>
        <p class="text-center">
            Hiring React JS Developers with TalentOnLease is Easy! When you hire a React JS Developer with
            TalentOnLease, it means peace of mind! We follow a transparent hiring process and well-defined processes on
            all our engagements.
        </p>
        <div class="why-full-stack-list">
            <div class="row">
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/thunder.png" /></span>
                        <p>Comprehensive Skills</p>
                        <p>TalentOnLease native developers have the right technical skills to proceed with your custom requirements, fulfilling your personalised demand.
                             Drop your ideas to sharpen your presence and our devs will do it for you. </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/efficiency.png" /></span>
                        <p>Wide Pool of Resources</p>
                        <p>Creating visions into reality needs consistent team efforts & resources. We offer you that. 
                            With a worldwide resource pool, you can hire dedicated React JS developer with ease.</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/collaborate.png" /></span>
                        <p>Seamless Integration</p>
                        <p>Our premium service policies let you integrate the TalentOnLease developer’s team into your in-house teams. Efficient collaboration
                             between both parties creates a seamless process of digital development.  </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/cost.png" /></span>
                        <p>Cost-Effectiveness</p>
                        <p>Don’t worry about the market prices. Our React JS Developers wages are competitive and worth your money. Moreover, be ready to see a 40%-50% savings on your overall cost, providing you with the liberty to scale your vision. 
 
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/innovation.png" /></span>
                        <p>Innovation and Problem-Solving</p>
                        <p>Anyone can design an app but what about problem-solving and roadside smartness? Interpreting ideas into reality needs innovation & 
                            practical problem-solving for maximum results in minimum effort. 
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/verified-icon.png" /></span>
                        <p>Vetted IT Professionals                        </p>
                        <p>We choose React JS native developers who are skilled, experienced, and qualified for our vetting tests. Getting excellent devs in your team provides you with standard quality
                             development as well as a work culture to reach the destination together. 
                        </p>
                    </div>
                </div>
            </div>
            <p class="text-center">Partner with TalentOnLease and harness the power of DOT-NET expertise for your
                digital endeavors. Let
                us drive your project forward
                with efficiency, innovation, and excellence</p>


        </div>
        
    </div>
</section>



<section class="how-hire-devloper-section">
    <div class="hhd-main">
        <div class="hhd-left mb-30 hhd-main-item">
            <div class="gradient-title-head my-5">
                <h2 class="title-lg text-center">How to Hire Quality <span>React JS Developer</span></h2>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">1</div>
                            <div class="hire-div">
                                <p>Define Job Requirements
                                </p>
                                <p>Clearly outline the job responsibilities, qualifications, and skills required for the
                                    position.
                                    This
                                    information will form the basis for your job description</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">2</div>
                            <div class="hire-div">
                                <p>Find Per Vetted
                                    Candidates
                                    from pool of TalentOnLease
                                </p>
                                <p>Develop a compelling job description that includes key details about the position,
                                    responsibilites,
                                    qualifications, and any other relevant information.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">3</div>
                            <div class="hire-div">
                                <p>Resume Screening
                                </p>
                                <p>Review resumes to shortlist candidates who meet the basic qualifications.</p>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="row">
                    
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">4</div>
                            <div class="hire-div">
                                <p>Conduct
                                    Interviews
                                </p>
                                <p>Organize interviews with key team members or stackholders to gather multiple
                                    perspectives on
                                    the
                                    candidate.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">5</div>
                            <div class="hire-div"> 
                                <p>Offer Rollout
                                </p>
                                <p>Offer to the selected candidate with Immediate start date.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">6</div>
                            <div class="hire-div">
                                <p>Background Check
                                </p>
                                <p> Conduct background checks as necessary to verify the candidate's educational and
                                    professional
                                    background.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">7</div>
                            <div class="hire-div">
                                <p>Onboarding
                                </p>
                                <p> Sign Contract with TalentOnLease and welcome the new hire with a comprehensive
                                    onboarding
                                    process to
                                    ensure
                                    a smooth transition into their role and responsibility.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">8</div>
                            <div class="hire-div">
                                <p>Feedback and Continuous
                                    Improvement
                                </p>
                                <p> We gather time to time feedback from client to check the performance of the
                                    candidate.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>

 <section class="major-key-section container">
    <div class="row">
        <header class="gradient-title-head mb-30 mx-auto">
            <h3 class="title-lg text-center"> Benefits of Hire <span>React JS Developer</span>- Smoothing the Process of Technical Advancement</h3>
        </header>
        <p class="mk-desc">
            Years of experience have shown us the rapid evolution of technology and how users want their interaction with the digital world in today’s time. An understanding of the
             present demands gives you an edge in the market. With TalentOnLease JS Developers, you can utilise all those opportunities that you never knew were open for you for so long. 
        </p>
        <div class="mkb-section">
            <div class="mkb-section-items">
                <p class="heading">Customised Solution</p>
                <p class="desc">
                    Get customised solutions fulfilling the needs of your ideas and making them ready to launch. With experience in 100+ 
                    industries, we assure you of getting all your technical visions fulfilled. 
                </p>
            </div>
            <div class="mkb-section-items">
                <p class="heading">Space for Scaling</p>
                <p class="desc">
                    Get long-term success with modular and scalable codes, making your project open for future opportunities. Build with us, 
                    enjoy adaptations, and be ready to win the markets for years to come. 
                </p>
            </div>
            <div class="mkb-section-items">
                <p class="heading">Virtual DOM Optimization
                </p>
                <p class="desc">
                    React JS skilled developers utilise the virtual DOM, causing a significant reduction in page reloading. It improves
                     web page and application speed, further improving the click rate. 
                </p>
            </div>
            <div class="mkb-section-items">
                <p class="heading">Declarative Coding
                </p>
                <p class="desc">
                    TalentOnLease React Devs uses simplest of codes, making them easy to read, understand, and maintain, and scale them. 
                    Clean coding improves the web & app speed, enhancing user interaction reports.
                </p>
            </div>
            <div class="mkb-section-items">
                <p class="heading"> Ecosystem Compatibility</p>
                <p class="desc">
                    React JS technology seamlessly integrates with all your technology and required coding libraries, offering flexibility
                     and compatibility to your project with various development ecosystems.
                </p>
            </div>
            <div class="mkb-section-items">
                <p class="heading"> SEO-Friendly Applications</p>
                <p class="desc">
                    React Developers provide server-side rendering and SEO optimization. It enhancing the performance of web applications
                     to suit the search engines and users alike, ensuring better visibility.
                </p>
            </div>
        </div>
    </div>
</section> 


<section class="section faqs-page-sec">
    <div class="container">
        <header class="gradient-title-head text-center">
            <small class="small-head">How we can help?</small>
            <h4 class="title-lg">Frequently Asked <span>Questions</span></h4>
        </header>
        <div class="row">
            <div class="col-md-4">
                <img src="assets/img/fullstackdeveloperimg/faq.png" class="img-fluid" />
            </div>
            <div class="col-md-8">
                <div class="tabbing-faq-sec">
                    <div class="tabbing-body">
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>What is ReactJS used for?</mat-panel-title>
                                </mat-expansion-panel-header>
                                <p>React JS, often called React, is a very popular library of JavaScript for the creation of User Interfaces (UIs). 
                                    It helps developers for the creation of interactive, dynamic, and web applications that are highly efficient. React is used generally for single page applications ( SPAs), mobile web development, or web components using the native React. It emphasizes component-based architecture along with efficient UI updates.
                                </p>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>How much does it cost to hire a ReactJS Developer?</mat-panel-title>
                                </mat-expansion-panel-header>
                                <p>The cost to hire React JS developer differs based on some factors like experience, location, and project complexity. On choosing React native developers the rates can differ based on the work profile and other factors as well like work criteria. The rates can also differ based on the experience levels of the developers.</p> 
                                   <p>If you hire dedicated React JS developer with great experience, they will charge extra based on work and the complexity of work, whereas junior-level developers can charge lower rates. With Talent on Lease, it’s easy to get the best developers based on your needs. Contact us now! For that.</p>  
                                
                                <p>Basic Rates according to ReactJS Developer ( Hourly Basis)</p> 
                                <ul>
                                    <li>Average React Developer Hourly Rate: $50 - $100</li>
                                    <li>Median React Developer Hourly Rate: $45- $80</li>
                                </ul>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>Difference between ReactJS and Python for software development:</mat-panel-title>
                                </mat-expansion-panel-header>
                                <p>React JS - A library of JavaScript created for building UIs ( User Interfaces). It directly focuses on the view layer and even works with other backend technologies as well.</p>
                                <p>Python - An adaptable programming used for data science, web development, and more. This language is not specifically designed for UI creation and works with different frameworks like Flask or Django.</p>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>Skills to look for when hiring ReactJS developers:</mat-panel-title>
                                </mat-expansion-panel-header>
                                <p>Some skills to look for  when you hire react native developers:</p>
                                <ul>
                                    <li>Skillfulness in HTML, JavaScript, and CSS.</li>
                                    <li>Deep understanding of Read Concepts like State., Propds.</li>
                                    <li>Exposure in State Management Libraries (eg. Redux)</li>
                                    <li>Knowledge of different build tools like (Babel, Webpack) and also Version Control (GIT)</li>
                                    <li>Skills related to complex problem-solving and working in sync with the team.</li>
                                </ul>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>What are the best platforms to find and hire ReactJS developers?</mat-panel-title>
                                </mat-expansion-panel-header>
                                <p>With TalentOnLease, you don’t have to look elsewhere to find and hire React JS Developer that’s right for you. We have a built-in team that categorizes and chooses the best developers based on your requirements. Further, you can choose from a pool of these top cream and hire React native developers that can make your ideas and vision into reality. With TalentOnLease getting the perfect designer is now possible.</p>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>

<section class="ready-get-started-section">
    <header class="gradient-title-head mb-30">
        <h3 class="title-lg text-center">Ready To Get <span>Started</span></h3>
    </header>
    <p>
        Choose TalentOnLease, Hire The Right Developer You Will Ever Need.
         </p>
         <p>
            If you're ready to elevate your projects with our  React JS developers connect with us today.
    </p>
    <button mat-raised-button (click)="openDialog()" class="mat-focus-indicator btn btn-primary mat-button-base mb-15">Contact Us Now</button>
</section>
<div class="whatsapp-button fixed-button"><a href="https://wa.link/ixaedq" target="blank"><i class="fa fa-whatsapp"
    aria-hidden="true"></i></a>

</div>

<div class="callno-button fixed-button"><a href="tel:+919899240555" target="blank"><i class="fa fa-phone"
    aria-hidden="true"></i></a>

</div>
