<section class="section">
  <div class="container">
    <!--row -->
    <div class="row">
      <div class="col-12 col-md-6">
        <header>
          <h1 class="title-lg">
            Reach Out, Get In Touch
          </h1>
        </header>
        <p>
          Have questions about TalentOnLease? We’re here to help. Please reach out. We’d love to hear from
          you.
        </p>
        <div>
          <img src="assets/img/about-us/contact-us.png" class="img-fluid">
        </div>
        <h2 class="mt-2">
          Social Media
        </h2>

        <ul class="d-flex align-items-center ulsocialicon">
          <li class="lisocial"> <a class="socialicons"
              href="https://www.facebook.com/Talent-on-Lease-2181624008775260" target="_blank">
              <i class="fa fa-facebook"></i></a>
          </li>
          <li class="lisocial ml-2"> <a class="socialicons" href="https://www.linkedin.com/company/talentonlease/"
              target="_blank">
              <i class="fa fa-linkedin"></i></a>
          </li>
          <li class="lisocial ml-2"> <a class="socialicons" href="https://www.instagram.com/talentonlease/"
              target="_blank">
              <i class="fa fa-instagram"></i></a>
          </li>
        </ul>
      </div>
      <div class="col-12 col-md-6 searchtoolbar form-listing">
        <h2>
          Have A Question?
        </h2>
        <form [formGroup]="contactUsForm">
          <!--row -->
          <div class="row">
            <div class="col-12 col-md-12">
              <div class="form-group mb-4">
                <mat-form-field appearance="fill">
                  <mat-label>Full Name</mat-label>
                  <input type="text" placeholder="Full Name" formControlName="name"  matInput
                    >
                  <div *ngIf="contactflag && contactUsForm.controls['name'].errors" class="mt-3">
                    <div *ngIf="contactUsForm.controls['name'].errors">
                      <div *ngIf="contactUsForm.controls['name'].errors?.['required']">
                        <p class="text-danger">Full Name is Required</p>
                        </div>
                     
                    </div>
                  </div>
                </mat-form-field>
              </div>
              <div class="form-group mb-4 mt-3">
                <mat-form-field appearance="fill" class="mt-2">
                  <mat-label>Email</mat-label>
                  <input type="text" placeholder="Email" formControlName="email" aria-label="Email " matInput
                   >
                  <div *ngIf="contactflag && contactUsForm.controls['email'].errors" class="mt-3">
                    <div *ngIf="contactUsForm.controls['email'].errors?.['required']">
                      <p class="text-danger">Email is Required</p>
                    </div>
                    <div *ngIf="contactUsForm.controls['email'].errors?.['email']">
                      <p class="text-danger">Email is not valid</p>
                    </div>
                  </div>
                </mat-form-field>
              </div>
              <div class="form-group mb-4">
                <mat-form-field appearance="fill" class="mt-2">
                  <mat-label>Mobile Number</mat-label>
                  <input type="text" placeholder="Mobile Number" formControlName="mobile" aria-label="Mobile Number"
                    matInput >
                  <div *ngIf="contactflag && contactUsForm.controls['mobile'].errors">
                    <div *ngIf="contactUsForm.controls['mobile'].errors" class="mt-3">
                      <!-- <p class="text-danger">Mobile Number is Required</p> -->
                      <div *ngIf="contactUsForm.controls['mobile'].errors?.['required']">
                        <p class="text-danger">mobile number is Required</p>
                      </div>
                      <div *ngIf="contactUsForm.controls['mobile'].errors?.['pattern']">
                        <p class="text-danger">mobile number should be number only</p>
                      </div>
                      <div *ngIf="contactUsForm.controls['mobile'].errors?.['minlength']">
                        <p class="text-danger">mobile number should be minimum 10 digit</p>
                      </div>
                      <div *ngIf="contactUsForm.controls['mobile'].errors?.['maxlength']">
                        <p class="text-danger">mobile number should be maximum 10 digit</p>
                      </div>
                      
                      
                    </div>
                  </div>
                </mat-form-field>
              </div>
              <div class="form-group mb-4">
                <mat-form-field appearance="fill" class="mt-2">
                  <mat-label>Designation</mat-label>
                  <input type="text" placeholder="Designation" formControlName="designation" aria-label="Designation"
                    matInput>
                </mat-form-field>
              </div>
              <div class="form-group mb-4">
                <mat-form-field appearance="fill" class="mt-2">
                  <mat-label>Organisation</mat-label>
                  <input type="text" placeholder="Organisation" formControlName="organization" aria-label="Organisation"
                    matInput>
                </mat-form-field>
              </div>
              <div class="form-group mb-4">
                <mat-form-field appearance="fill" class="mt-2">
                  <mat-label>Your Message</mat-label>
                  <!-- <input type="text" placeholder="Your Message" aria-label="Your Message" matInput> -->
                  <textarea matInput rows="3" placeholder="Your Message" formControlName="message"></textarea>
                </mat-form-field>
              </div>

              <div class="form-group mb-4">
                <button mat-button class="btn btn-primary" (click)="submiContactUs()">Submit</button>
              </div>

            </div>
          </div>
          <!-- close row -->
        </form>
      </div>

    </div>
    <!-- close row -->
  </div>
</section>