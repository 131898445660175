<mat-drawer-container Backdrop="true">
  <div class="admin-header">
    <div id="searchtoolbar" class="d-flex searchtoolbar">
      <div class="form-group searchcol">
        <mat-form-field class="full-width" appearance="fill">
          <mat-label>Search here</mat-label>
          <input type="text" placeholder="Search here" aria-label="Search" matInput>
        </mat-form-field>
      </div>
     
      <!-- <div class=" header-notification dashboard-col  ml-auto">
        <a href="#" class="column-card mr-3">
          <header>
            <i class="ico notification-ico">
              <span class="badge">3</span>
            </i>
          </header> 
        </a>
      </div> -->
      <div class="right-sec d-flex align-items-center ml-auto">
        <div class="notification-sec">
          <button type="button" class="notilink"  id="btnNotification" (click)="drawer.toggle()">
            <img style="width: 73%;" src="assets/img/notification-ico.svg" alt="Notification"><span>{{notificationCount}}</span>
          </button>
        </div>
        
        <div class="user-sec">
          
          <div class="desk-nav">
            <button class="loginbtn" mat-button [matMenuTriggerFor]="beforeMenu">
              <span class="pic-col"><img src="assets/img/pic1.jpg" alt="User" ></span> 
              <span class="name-col">{{userName}}</span>
            </button>
            <mat-menu #beforeMenu="matMenu" xPosition="before">
              <a mat-menu-item routerLinkActive="active"(click)="profileClick()">
                <i class="fa fa-user-circle" aria-hidden="true"></i> &nbsp; My Profile</a>
              <a mat-menu-item routerLinkActive="active" (click)="logOut()"><i class="fa fa-sign-out" aria-hidden="true"></i> &nbsp;Logout</a>
            
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
  <mat-drawer  #drawer class="example-sidenav" mode="over">
    <div class="noti-head d-flex">
      <div class="title">All Notifications <span>{{notificationCount}}</span></div>
      <div class="noti-read ml-auto"><a style="cursor: pointer;" (click)="readAllNotification()">Mark all as read</a></div>
    </div>
    <div class="noti-list cus-scr">
      <div class="list-sec" *ngFor="let item of allUserNotification; let i=index;">
        <!-- <a href="#" class="columns unread"> -->
          <section class="columns unread">
          <div class="list-pic">
            <img src="assets/img/notification-ico.svg" alt="Notification" style="width: 73%;">
            <!-- <img src="assets/img/list-pic.svg" alt=""> -->
          </div>
          <div class="list-cont" >
            <!-- <dpiv class="notification-title" [innerHtml]="item.code"></div> -->
            <p (click)="readNotification(item)" [innerHtml]="item.description"></p>
            <div class="time">{{time_ago(item.notificationDate)}}</div>
          </div>
        <!-- </a> -->
      </section>
      </div>
      <div *ngIf="allUserNotification?.length==0" class="no-record" style="padding: 57px">
        <i>
            <svg version="1.1" width="80" height="80" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 80 80" style="enable-background:new 0 0 80 80;" xml:space="preserve">
                <circle style="fill:#EEEEEE;" cx="40" cy="40" r="40"></circle>
                <path style="fill-rule:evenodd;clip-rule:evenodd;fill:#989898;" d="M18,44.1l10.6-10.8c0.4-0.4,1-0.7,1.6-0.7h20.6c0.6,0,1.2,0.2,1.6,0.7l10.6,10.8v11.1c0,1.9-1.6,3.5-3.5,3.5
        H21.5c-1.9,0-3.5-1.6-3.5-3.5V44.1z M21.3,43.2l8.6-8.9H51l8.6,8.9h-11V45c0,2.2-1.8,4-4,4l0,0h-8.4c-2.2,0-4-1.8-4-4l0,0v-1.8H21.3
        z M19.8,45h10.9c0,3.1,2.5,5.5,5.5,5.5l0,0h8.6c3.1,0,5.5-2.5,5.5-5.5v0h10.9v9.8c0,1.2-0.9,2.1-2.1,2.1H21.9
        c-1.2,0-2.1-0.9-2.1-2.1L19.8,45z M41.3,28.2c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9v-9.3c0-0.5,0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9
        l0,0V28.2z M47,29c-0.1,0.5-0.6,0.8-1.1,0.7c-0.5-0.1-0.8-0.6-0.7-1.1c0,0,0,0,0-0.1l2.4-9c0.1-0.5,0.6-0.8,1.1-0.7
        c0.5,0.1,0.8,0.6,0.7,1.1c0,0,0,0,0,0.1L47,29z M35.8,28.5c0.1,0.5-0.1,1-0.6,1.1c-0.5,0.1-1-0.1-1.1-0.6c0,0,0,0,0-0.1l-2.4-9
        c-0.1-0.5,0.1-1,0.6-1.1c0.5-0.1,1,0.1,1.1,0.6c0,0,0,0,0,0.1L35.8,28.5z"></path>
            </svg>
        
        </i>
        <div class="contents">
            <strong style="color: #ed4542 !important;">No Notification found.</strong>
        </div>
        </div>
    </div>
  </mat-drawer>
</mat-drawer-container>


