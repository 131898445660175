


<section class="section">
    <div class="container">

<p style='margin-right:0in;margin-left:0in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:center;line-height:115%;'><strong><u><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'><span style="text-decoration: none;">&nbsp;</span></span></u></strong></p>
<p style='margin-right:0in;margin-left:0in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:center;line-height:115%;'><strong><u><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>TERMS OF USE</span></u></strong></p>
<p style='margin-right:0in;margin-left:0in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:center;line-height:115%;'><strong><u><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'><span style="text-decoration: none;">&nbsp;</span></span></u></strong></p>
<p style='margin-right:0in;margin-left:0in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;background:white;'>TalentOnLease Private Limited (&ldquo;<strong>TalentOnLease</strong>&rdquo;) is the author and publisher of the internet resource,&nbsp;www.talentonlease.com&nbsp;(&ldquo;<strong>Site</strong>&rdquo;) on the World Wide Web as well as other connected software and applications provided by TalentOnLease.</span></p>
<p style='margin-right:0in;margin-left:0in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;background:white;'>&nbsp;</span></p>
<ol style="margin-bottom:0in;margin-top:0in;" start="1" type="1">
    <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:     115%;border:none;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>NATURE AND APPLICABILITY&nbsp;</span></strong></li>
</ol>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;background:white;'>Please carefully go through these</span><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;terms and conditions (&ldquo;<strong>User Terms</strong>&rdquo;), and read with the <strong>Privacy Policy</strong> available on the Site [<em><a href="https://talentonlease.com/privacy" target="_blank">https://talentonlease.com/privacy</a></em>] <span style="background:white;">before you decide to access the Site or avail of any services. These User Terms and the Privacy Policy together constitute</span> a legally binding agreement (&ldquo;<strong>Agreement</strong>&rdquo;) between you and TalentOnLease and shall apply to and govern your visit to and use, of the Site and any of its products or services whether through a computer or a mobile phone as well as to all information, recommendations and or Services provided to you on or through the Site.&nbsp;</span></p>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>This Agreement defines the terms and conditions under which you are allowed to use the Site and describes the manner in which we shall treat your account while you are registered as a member with us. These User Terms apply to the services made available by TalentOnLease on the Site and other users/visitors of the Site.&nbsp;</span></p>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: decimal;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;'>By downloading or accessing the Site to use the Services, you irrevocably accept all the terms and conditions stipulated in this Agreement and agree to fully abide by them.</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.75in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: decimal;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;'>This Agreement supersedes all previous oral and written terms and conditions (if any) communicated to you. By availing of any service, you signify your agreement and acceptance of this Agreement. We reserve the right to modify or terminate any portion of the Agreement for any reason and at any time.&nbsp;</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: decimal;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;'>Your acceptance of the User Terms shall be deemed to include your acceptance of the Privacy Policy available at the Site.</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: decimal;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;'>You should read the Agreement at regular intervals. Your use of the Site following any such modification constitutes your agreement to follow and be bound by the Agreement so modified. Any additional terms and conditions, disclaimers, privacy policies and other policies applicable in general and/or to specific areas of this Site or to particular service are also considered as part of the Agreement.</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: decimal;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;'>You acknowledge that you will be bound by this Agreement for availing of any of the services offered by us. If you do not agree with any part of the Agreement, please do not use the Site or avail of any services.</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: decimal;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;'>Your access to the use of the Site and the Services will be solely at the discretion of TalentOnLease. The Agreement is published in compliance with, and is governed by the provisions of Indian law,&nbsp;including but not limited to:</span></li>
    </ol>
</div>
<ol style="list-style-type: undefined;margin-left:0.5in;">
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>the Indian Contract Act, 1872,</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>the (Indian) Information Technology Act, 2000, and</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>the rules, regulations, guidelines and clarifications framed there under, including SPI Rules, and the IG Rules<span style="background:yellow;">.</span></span></li>
</ol>
<p style='margin-right:0in;margin-left:.75in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></strong></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: decimal;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;'>We reserve the right to store information on a User&rsquo;s computer in the form of &ldquo;cookies&rdquo; or similar files for purposes of modifying the Site to reflect the User&rsquo;s preference. TalentOnLease shall try and ensure that all information that is provided on the Site with respect to policies/products/services is accurate in all respects and is kept up to date. However, TalentOnLease does not guarantee the timeliness, accuracy, completeness, reliability or content of the information and any changes that are made with respect to the same and the visitor/Customer/Vendor is required to check the accuracy of the same with the office of TalentOnLease at&nbsp;</span><a href="mailto:info@talentonlease.com"><span style='line-height:115%;font-family:"Times New Roman",serif;'>info@talentonlease.com</span></a><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>.&nbsp;</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: decimal;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;'>TalentOnLease&apos; performance to this Agreement is subject to existing laws and legal processes, and nothing contained in this Agreement is in derogation of TalentOnLease&apos; right to comply with governmental, court and law enforcement directions relating to the use of this Site or information provided to/gathered by TalentOnLease vis-&agrave;-vis such use.</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.75in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: decimal;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;'>TalentOnLease may introduce new services in relation to the Site from time to time. The existence and availability of the new services will be notified on the Site as and when they become available and any revisions in the Terms &amp; Conditions will take place in accordance with these Terms &amp; Conditions. In addition, TalentOnLease may also publish notices of general nature, which are applicable to all visitors or Customer/Vendors in a newspaper or on its Site. Such notices will have the same effect as a notice served individually to each visitor or Customer/Vendor.&nbsp;</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.75in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: decimal;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;'>This document is an electronic record in terms of the Information Technology Act, 2000 and rules thereunder as applicable and the provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<ol style="margin-bottom:0in;margin-top:0in;" start="2" type="1">
    <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:     115%;border:none;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>DEFINITIONS</span></strong></li>
</ol>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>All of the defined and capitalized terms in these User Terms will have the meaning assigned to them here below:</span></p>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<ol style="list-style-type: undefined;margin-left:0.5in;">
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;'>&ldquo;<strong>Account</strong>&rdquo; shall mean the account created by the Customer/Vendor on the Site for availing the Services provided/facilitated by TalentOnLease.</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;'>&ldquo;<strong>Applicable Laws</strong>&rdquo; shall mean and include all applicable statutes, enactments, acts of legislature or parliament, laws, ordinances, rules, by-laws, regulations, notifications, guidelines, policies, directions, directives and orders of any governmental authority, tribunal, or a court of India including but not limited to the Indian Contract Act, 1872, the (Indian) Information Technology Act, 2000, and the rules, regulations, guidelines and clarifications framed there under, including the (Indian) Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011 (the &ldquo;<strong>SPI Rules</strong>&rdquo;), and the Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021 (the &ldquo;<strong>IG Rules</strong>&rdquo;).</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;color:#191823;font-size:16px;color:#191823;'>&ldquo;<strong>Confidential Information</strong>&rdquo; means (a) any written information, materials and other documents supplied by us related to the Site which we do not disclose publicly, (b) The TalentOnLease Services themselves, excluding any data you upload to the TalentOnLease Services for processing; &nbsp;and (c) any other of our information that we may disclose in writing or orally and is designated as confidential or proprietary at the time of disclosure, or that due to the nature of the information a reasonable person would clearly understand it to be confidential information; and (d) any amendment to the terms and conditions of these Terms between you and us. Confidential Information shall not include any information that: (i) was or becomes generally known to the public through no fault or breach of these Terms you; (ii) was rightfully in your possession at the time of disclosure without restriction on use or disclosure; (iii) was independently developed by you without use of our Confidential Information; or (iv) was rightfully obtained by you from a third party not under a duty of confidentiality and without restriction on use or disclosure.</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;&ldquo;<strong>CustomerVendor/you/user/your</strong>&rdquo; shall mean and include: a user of the Site who has an Account on the Site or a visitor of the Site.</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;'>&ldquo;<strong>Force Majeure Event</strong>&rdquo; shall mean any and all events arising due to any cause beyond the reasonable control of TalentOnLease.</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;'>&ldquo;<strong>TalentOnLease</strong>&rdquo; or &ldquo;<strong>us</strong>&rdquo; or &ldquo;<strong>we</strong>&rdquo; or &ldquo;<strong>our</strong>&rdquo; shall mean TalentOnLease Private Limited, a company incorporated under the provisions of the Companies Act, 2013 and having its registered office at&nbsp;</span><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;color:#202124;background:white;'>C, 58, Sector 63 Rd, C Block, Sector 63, Noida, Uttar Pradesh 201301</span><span style="font-size:16px;">&nbsp;which expression shall, unless it is repugnant to the context or meaning thereof, be deemed to mean and include all its successors, affiliates and permitted assigns.</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;'>&ldquo;<strong>IG Rules</strong>&rdquo; shall mean Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021.</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>&ldquo;<strong>Information</strong>&rdquo; shall mean all information on the Site including but not limited to content, text, data, graphics, images, information, suggestions, and guidance.&nbsp;</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;&ldquo;<strong>Registration Data</strong>&rdquo; shall mean and may include the present, valid, true and accurate name, email ID, phone number, office addresses, and such other information as may be required by TalentOnLease from the Customer/Vendor from time to time for registration on the Site.</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;'>&ldquo;<strong>Services</strong>&rdquo; shall mean the various categories of Services as mentioned on the site.&nbsp;</span></li>
</ol>
<p style='margin-right:0in;margin-left:1.0in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;&ldquo;<strong>Site</strong>&rdquo; shall mean the Website operated by TalentOnLease, <span style="background:white;">www.TalentOnLease.com,</span> or any other software that enables the use of the Site or such other URL as may be specifically provided by TalentOnLease.</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;margin-left:0.5in;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;'>&ldquo;<strong>T&amp;Cs</strong>&rdquo; and &ldquo;<strong>User Terms</strong>&rdquo; shall mean these terms and conditions.</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:1.0in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<ol style="margin-bottom:0in;margin-top:0in;" start="3" type="1">
    <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:     115%;background:white;border:none;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;color:black;'>CONDITIONS OF USE</span></strong></li>
</ol>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;background:white;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;background:white;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;color:black;'>You must be 18 years of age or older to register, use the Services, or visit or use the Site in any manner. By registering, visiting and using the Site or accepting this Agreement, you represent and warrant to TalentOnLease that you are 18 years of age or older and that you have the right, authority and capacity to use the Site and the Services available through the Site, and agree to and abide by this Agreement.</span></p>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;background:white;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><strong><span style='line-height:115%;font-family:"Times New Roman",serif;color:black;'>AVAILABILITY OF WEBSITE</span></strong></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;background:white;border:none;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;background:white;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;color:black;background:#F9F9F9;'>You acknowledge that there may be interruptions in the delivery of the Website and app that are beyond our control. While we use reasonable efforts to keep the Website and app accessible, the Website and app may be unavailable from time to time for any reason including, but not limited to, routine maintenance. You understand and acknowledge that due to circumstances both within and outside of our control, access to the Website and app may be interrupted, suspended or terminated. retains the right at our sole discretion to deny access to the Website or app to any Customer or Vendor at any time and for any reason. Website&nbsp;is not required to disclose the reason for the denial of access to the Website to any of the previously mentioned parties</span></p>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;background:white;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<ol style="margin-bottom:0in;margin-top:0in;" start="5" type="1">
    <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:     115%;background:white;border:none;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;color:black;'>RESTRICTIONS ON THE USE OF THE SITE</span></strong></li>
</ol>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;background:white;border:none;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;font-size:16px;'>By using the Site, you specifically agree not to engage in any activity or transmit any information that, in our sole discretion:</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;background:white;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<ol start="1" style="list-style-type: lower-alpha;margin-left:0.5in;">
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;color:black;'>Is illegal, or violates any federal, state, or local law or regulation;</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;color:black;'>Advocates illegal activity or discusses illegal activities with the intent to commit them;</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;color:black;'>Violates any third-party right, including, but not limited to, right of privacy, right of publicity, copyright, trademark, patent, trade secret, or any other intellectual property or proprietary rights;</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;color:black;'>Is harmful, threatening, abusive, harassing, tortious, indecent, defamatory, sexually explicit or pornographic, discriminatory, vulgar, profane, obscene, libelous, hate speech, violent or inciting violence, inflammatory, or otherwise objectionable;</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;color:black;'>Interferes with any other party&rsquo;s use and enjoyment of the Site;</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;color:black;'>Attempts to impersonate another person or entity;</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;color:black;'>&nbsp;Is of a commercial nature in a way that violates these Terms, including but not limited to, using the Site for spam, surveys, contests, pyramid schemes, or other advertising materials;</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;color:black;'>Falsely states, misrepresents, or conceals your affiliation with another person or entity;</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;color:black;'>Accesses or uses TalentOnLease Services account of a Customer/Vendor without such party&rsquo;s permission;</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;color:black;'>Distributes computer viruses or other code, files, or programs that interrupt, destroy, or limit the functionality of any computer software or hardware or electronic communications equipment;</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;color:black;'>Interferes with, disrupts, disables, overburdens, or destroys the functionality or use of any features of the Site, or the servers or networks connected to the Site;</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;color:black;'>Hacks or accesses without permission our proprietary or confidential records, those of another user, or those of anyone else;</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;color:black;'>Improperly solicits personal or sensitive information from other users including without limitation address, credit card or financial account information, or passwords;</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;color:black;'>decompiles, reverse engineers, disassembles, or otherwise attempts to derive source code from the Site, except as expressly permitted in these Terms or by law, unless and then only to the extent permitted by applicable law without our consent;</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;color:black;'>&nbsp;removes, circumvents, disables, damages, or otherwise interferes with security-related features, or features that enforce limitations on use of the Site;</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;color:black;'>uses automated or manual means to violate the restrictions in any robot exclusion headers on the Site, if any, or bypasses or circumvents other measures employed to prevent or limit access, for example by engaging in practices such as &ldquo;screen scraping,&rdquo; &ldquo;database scraping,&rdquo; or any other activity with the purpose of obtaining lists of users or other information;</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;color:black;'>modifies, copies, scrapes or crawls, displays, distributes, publishes, licenses, sells, rents, leases, lends, transfers, or otherwise commercializes any materials or content on the Site;</span></li>
</ol>
<p style='margin-right:0in;margin-left:0in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;background:white;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;color:black;color:black;'>You acknowledge that we have no obligation to monitor your or anyone else&rsquo;s access to or use of the Site for violations of these Terms, or to review or edit any content. However, except as otherwise expressly provided herein, we have the right to do so for the purpose of operating and improving the Site (including without limitation for fraud prevention, risk assessment, investigation and for an support purposes, analytics, and advertising), to ensure your compliance with these Terms and to comply with applicable law or the order or requirement of a court, consent decree, administrative agency or other governmental body.</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.75in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
<ol class="decimal_type" style="list-style-type: undefined;margin-left:0in;">
    <li><strong><span style="color:#191823;">CONFIDENTIALITY</span></strong><span style="color:#191823;"><br>&nbsp;<br>&nbsp;</span>
        <ol class="decimal_type" style="list-style-type: undefined;">
            <li><strong><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;color:#191823;'>Restricted Use and Nondisclosure</span></strong></li>
        </ol>
    </li>
</ol>
<p style='margin-right:0in;margin-left:.75in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;color:#191823;'>During and after the term, with respect to our Confidential Information you will: (a) use our Confidential Information solely for the purpose for which we provided it; (b) not disclose such Confidential Information to a third party, except on a need-to-know basis to your affiliates, attorneys, auditors, consultants, and service providers who are under confidentiality obligations at least as restrictive as those contained herein; and (c) protect such Confidential Information from unauthorized use and disclosure to the same extent (but using no less than a reasonable degree of care) that you protect your own Confidential Information of a similar nature.</span></p>
<p style='margin-right:0in;margin-left:.75in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;color:#191823;'>&nbsp;&nbsp;</span></strong></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><strong><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;color:#191823;'>Required Disclosure</span></strong></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.75in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;color:#191823;'>If you are required by law to disclose our Confidential Information, you will give us prompt written notice before making the disclosure, unless prohibited from doing so by the legal or administrative process, and assist us to obtain where reasonably available an order protecting our Confidential Information from public disclosure.</span></p>
<p style='margin-right:0in;margin-left:.75in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;color:#191823;'>&nbsp;</span></strong></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><strong><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;color:#191823;'>Ownership</span></strong></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.75in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;color:#191823;'>Notwithstanding any other provision of these Terms, you acknowledge that, as between you and us, all our Confidential Information you receive from us, including all copies thereof in your possession or control, in any media, is proprietary to and exclusively owned by us. Nothing in these Terms grants you any right, title or interest in or to any of our Confidential Information, except as provided in these Terms. Any incorporation of our Confidential Information into any of your own materials will not render our Confidential Information non-confidential.</span></p>
<p style='margin-right:0in;margin-left:0in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;background:white;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<ol style="margin-bottom:0in;margin-top:0in;" start="7" type="1">
    <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:     115%;background:white;border:none;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;color:black;'>REGISTRATION/MEMBERSHIP AND MANNER OF USAGE OF ACCOUNT</span></strong></li>
</ol>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;background:white;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;'>You understand and acknowledge that you can register on the Site only after complying with the requirements of this Clause and by entering your Registration Data.</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.75in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;'>You shall ensure that the Registration Data provided by you is accurate, complete, current, valid and true and is updated from time to time. TalentOnLease shall bear no liability for false, incomplete, old or incorrect Registration Data provided by you.</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;'>You are solely responsible for maintaining the confidentiality of your Registration Data and will be liable for all activities and transactions that occur through your Account, whether initiated by you or any third party. Your Account cannot be transferred, assigned or sold to a third party. We shall not be liable for any loss that you may incur as a result of someone else using your password or Account, either with or without your knowledge.</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;'>TalentOnLease reserves the right to suspend or terminate your Account with immediate effect and for an indefinite period, if we have reason to believe that the Registration Data or any other data provided by you is incorrect or false, or that the security of your Account has been compromised in any way, or for any other reason TalentOnLease may find just or equitable.</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:0in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;'>We allow you to open only one Account in association with the Registration Data provided by you. In case of any unauthorized use of your Account please immediately reach us&nbsp;</span><a href="mailto:info@talentonlease.com"><span style="font-size:16px;">info@talentonlease.com</span></a><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>. In case, you are unable to access your Account, please inform us at&nbsp;</span><a href="mailto:info@talentonlease.com"><span style="font-size:16px;">info@talentonlease.com</span></a><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>. and make a written request for blocking your Account. We will not be liable for any unauthorised transactions made through your Account prior to the expiry of 72 (seventy-two) hours after you have made a request in writing for blocking your Account, and shall not have any liability in case of Force Majeure Events.</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;'>By using the Site or the Service, you further agree that: (i) you will only use the Site and Service for your sole, personal use and will not resell or assign it to a third party; (ii)</span><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>&nbsp;you will not use an account that is subject to any rights of a person other than you without appropriate authorization; (iii) you will not use the Services or Site for unlawful purposes; (iv) you will not try to harm the Service, Site or our network in any way whatsoever; (v) you will provide TalentOnLease with such information and documents which TalentOnLease may reasonably request; (vi) you will comply with all Applicable Law from your country of domicile and residence and the country, state and/or city in which you are present while using the Site or Service; and (vii) you are aware of and shall comply with &nbsp; the Information Technology Act, 2000 and the rules, regulations and guidelines notified thereunder as amended from time to time.</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;'>TalentOnLease assumes no responsibility, and shall not be liable for, any damages to, or viruses that may infect User&rsquo;s equipment on account of User&rsquo;s access to, use of, or browsing the Site or the downloading of any material, data, text, images, video content, or audio content from the Site. If a User is dissatisfied with the Site, the User&rsquo;s has the remedy is to discontinue using the Site.</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;'>TalentOnLease reserves the right to immediately terminate the Service and the use of the Site in the event of non-compliance with any of the above requirements. Further, subject to Applicable Laws, TalentOnLease may store the information provided by you or record your calls for contacting you for all Service-related matters including obtaining feedback in relation to the Services provided/facilitated through the Site. You shall promptly inform TalentOnLease of any change in the information provided by you.&nbsp;</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;'>User agrees to grant TalentOnLease a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sub-licensable (through multiple tiers) right to exercise the copyright, publicity, database rights or any other rights You have in your information, in any media now known or not currently known, with respect to your information. <u>User Agrees and permits TalentOnLease to share permitted information or any content created by the User and uploaded by the User, with third parties.</u>&nbsp;</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><u><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'><span style="text-decoration: none;">&nbsp;</span></span></u></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;'>You agree and permit TalentOnLease to share any information provided by you with third parties in order to facilitate the provision of Services including certain value-added/ancillary services offered by such third parties to you and/or to provide Services including certain value-added services to you by TalentOnLease. You hereby expressly consent to receive communications from TalentOnLease/ third parties offering Services/value-added services to you through your registered phone number and/or e-mail id and/or the Site. You agree that you will not hold TalentOnLease responsible for any such communications received from third parties, nor will any such communication amount to spam, unsolicited communication or a violation of your registration on the national do not call registry. Further, you agree that TalentOnLease may contact you through telephone, email, SMS, or any other electronic means of communication for the purpose of including but not limited to: &nbsp;</span></li>
    </ol>
</div>
<ol style="list-style-type: undefined;margin-left:0.5in;">
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;'>Obtaining feedback in relation to Site or TalentOnLease&rsquo; Services;&nbsp;</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;'>Connecting for any further details&nbsp;</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;color:black;'>Resolving any complaints, information, or queries&nbsp;</span></li>
</ol>
<p style='margin-right:0in;margin-left:.75in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<ol style="margin-bottom:0in;margin-top:0in;" start="8" type="1">
    <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:     115%;border:none;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>FORCE MAJEURE</span></strong></li>
</ol>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>TalentOnLease shall not be liable for any failure to perform any obligations under these User Terms, if the performance is prevented, hindered or delayed by a Force Majeure Event and in of a Force Majeure Event, our obligations under these User Terms shall be suspended for so long as the Force Majeure Event continues.</span></p>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<ol style="margin-bottom:0in;margin-top:0in;" start="9" type="1">
    <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:     115%;background:white;border:none;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;color:black;'>RIGHTS AND OBLIGATIONS RELATING TO CONTENT</span></strong></li>
</ol>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;background:white;border:none;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;'>As mandated by Regulation 3(2) of the IG Rules, TalentOnLease hereby inform Users that they are not permitted to host, display, upload, modify, publish, transmit, or share any information that:</span></li>
    </ol>
</div>
<ol style="list-style-type: undefined;margin-left:1in;">
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;color:black;'>belongs to another person and to which the User does not have any right;</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;color:black;'>is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libellous, invasive of another&apos;s privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever;</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;color:black;'>harm minors in any way;</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;color:black;'>infringes any patent, trademark, copyright or other proprietary rights;</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;color:black;'>violates any law for the time being in force;</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;color:black;'>deceives or misleads the addressee about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;color:black;'>impersonate another person;</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;color:black;'>contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource;</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;color:black;'>threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognisable offence or prevents investigation of any offence or is insulting any other nation.</span></li>
</ol>
<p style='margin-right:0in;margin-left:1.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;background:white;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;color:black;'>Users are also prohibited from:</span></li>
    </ol>
</div>
<ol style="list-style-type: undefined;margin-left:1in;">
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;color:black;'>violating or attempting to violate the integrity or security of the Site, or any content of TalentOnLease;</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;color:black;'>transmitting any information (including job posts, messages and hyperlinks) on or through the Site that is disruptive or competitive or prejudicial to the provision of Services</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;color:black;'>intentionally submitting any incomplete, false or inaccurate information;</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;color:black;'>making any unsolicited communications to other Users</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;color:black;'>using any engine, software, tool, agent or other device or mechanism (such as spiders, robots, avatars or intelligent agents) to navigate or search the Site;</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;color:black;'>attempting to decipher, decompile, disassemble or reverse engineer any part of the Site;</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;color:black;'>copying or duplicating in any manner any of the information available from the Site;</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;color:black;'>framing or hot linking or deep linking any of the information available from the Site.</span></li>
</ol>
<p style='margin-right:0in;margin-left:1.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;background:white;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;color:black;'>TalentOnLease, upon obtaining knowledge by itself or being brought to actual knowledge by an affected person in writing or through email signed with electronic signature about any such information (as mentioned above) generated by Users, or on being notified by the appropriate Government or its agency that the Site is being used by the User to commit any unlawful act and/or is being used in violation of Clauses 8.1 and 8.2 above, shall be entitled to remove or disable access to the material or information that is in contravention of such Clause 8.1. TalentOnLease is entitled to act, as required by the IG Rules, within thirty-six hours of obtaining such knowledge and, where applicable, work with Users to disable such information that is in contravention of applicable law. TalentOnLease shall also be entitled to preserve such information and associated records for at least 90 (ninety) days for production to governmental authorities for investigation purposes.</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.75in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;background:white;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;color:black;'>In case of non-compliance with any applicable laws, rules or regulations, or these User Terms (including the Privacy Policy) by a User, TalentOnLease has the right to immediately terminate the access or usage rights of the User to the Site and Services and to remove non-compliant information from the Site.</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;color:black;'>TalentOnLease may disclose or transfer User-generated information to its affiliates or governmental authorities in such manner as permitted or required by applicable law, and you hereby consent to such transfer. TalentOnLease will comply with any duly-issued government or court directions to disable access to the User-generated information, should it be found to be illegal by a competent governmental authority.</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
<ol style="margin-bottom:0in;margin-top:0in;" start="10" type="1">
    <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:     115%;border:none;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>INTELLECTUAL PROPERTY OWNERSHIP&nbsp;</span></strong></li>
</ol>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;'>You agree to abide by all applicable copyright and other intellectual property laws, as well as any additional proprietary rights notices or restrictions contained on the Site. You acknowledge that the Site contains valuable intellectual property of TalentOnLease and its customers/vendors. All present and future rights in and to any and all intellectual property or other proprietary rights of any type, including without limitation information, any improvements, design contributions, or derivative works thereto, and any knowledge or process related thereto, including rights in and to all applications and registrations relating to such intellectual property, shall, as between you and TalentOnLease, at all times be and remain the sole and exclusive property of TalentOnLease and its licensors. Any rights not expressly granted in these Terms or otherwise in writing between you and TalentOnLease, are reserved by TalentOnLease, and any unauthorised use of any intellectual property regarding the Site is strictly prohibited.</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.75in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;text-indent:-32.7pt;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>The trademarks, logos, taglines, and service marks displayed on the Site (collectively, the &ldquo;<strong>Trademarks</strong>&rdquo;) are registered and/or unregistered Trademarks of TalentOnLease and its customers/vendors. The Trademarks may not be used in any advertising or publicity, or otherwise to indicate TalentOnLease&rsquo; sponsorship of or affiliation with any product, service, event, or organization without TalentOnLease&rsquo; prior express written permission.</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:0in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;text-indent:-32.7pt;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;'>These User Terms do not constitute a sale and do not convey any rights of ownership in or related to the Site or the Service, or any intellectual property rights owned by TalentOnLease. You shall be solely responsible for any violations of any laws and for any infringements of any intellectual property rights caused by the use of the Services or the Site.</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:0in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<ol style="margin-bottom:0in;margin-top:0in;" start="11" type="1">
    <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:     115%;border:none;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>INDEMNITY</span></strong></li>
</ol>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>User agrees to indemnify and hold harmless TalentOnLease, its affiliates, officers, directors, employees, consultants, licensors, agents, representatives and partners from any and all third-party claims, losses, liability, damages, and/or costs (including reasonable attorney fees and costs) arising from User&rsquo;s access to or use of Service, infringement of any intellectual property or other right of any person or entity by you, the nature and substance of all documents, data, or other content uploaded by you to the Site</span><span style='font-size:12px;line-height:115%;font-family:"Helvetica",sans-serif;color:#191823;background:white;'>&nbsp;</span><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>, violation or breach of these User Terms, any Applicable Law or regulation, whether or not referenced herein or infringement by any other User of his/her/its account, of any intellectual property or other rights of any person or entity. TalentOnLease will notify you promptly of any such claim, loss, liability, or demand, and in addition to your foregoing obligations, you agree to provide us with assistance, at your expense, in defending any such claim, loss, liability, damage, or cost.</span></p>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<ol style="margin-bottom:0in;margin-top:0in;" start="12" type="1">
    <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:     115%;background:white;border:none;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;color:black;'>DISCLAIMER OF WARRANTIES</span></strong></li>
</ol>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;background:white;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;'>All information available on the Site is provided on the condition that the User will make an independent determination in respect of its accuracy, completeness or usefulness suitability prior to use or making any decision for any loss or damage in reliance hereof. TalentOnLease will not be responsible for the same. Further, TalentOnLease will not be responsible or liable in any manner for any data added, provided, stored or managed by the User including all personally identifiable information.&nbsp;</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.75in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;text-indent:-32.7pt;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;'>All Information on the Site is provided to you &quot;as is&quot; without warranty of any kind either express or implied including, but not limited to implied warranties of merchantability and fitness for a particular purpose, title, non-infringement, security or accuracy. All information available on a hyperlink site and any third party are subject to the terms and conditions of the legal notices contained therein.</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:8.0pt;font-size:11.0pt;text-indent:-32.7pt;'><span style='font-size:16px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;'>TalentOnLease does not warrant, endorse, guarantee, or assume responsibility for any product or service advertised or offered by a third party through the Site, and TalentOnLease will not be a party to or in any way responsible for monitoring any activities between you and third-party providers of products or services. You are solely responsible for all of your activities with other persons with whom you communicate or interact as a result of your use of the site.</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<ol style="margin-bottom:0in;margin-top:0in;" start="13" type="1">
    <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:     115%;border:none;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>LIMITATION OF LIABILITY</span></strong></li>
</ol>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;'>The information, recommendations and Services provided to you on or through the Site are for general information purposes only and do not constitute any advice.</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:0in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:8.0pt;font-size:11.0pt;text-indent:-32.7pt;'><span style='font-size:16px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;'>TalentOnLease shall not be liable for any damages resulting from the use of or inability to use the site, including damages caused by the wrong usage of the site,&nbsp;</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.75in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;text-indent:-32.7pt;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;'>The information available on the Site could include inaccuracies or typographic errors. TalentOnLease has endeavoured to ensure that all the information on the Site is correct, but TalentOnLease neither warrants nor makes any representations regarding the quality, accuracy or completeness of any data or information contained. TalentOnLease makes no warranty, express or implied, concerning the Site and/or its contents and disclaims all warranties of fitness for a particular purpose and warranties of merchantability in respect of Services, including any liability, responsibility or any other claim, whatsoever, in respect of any loss, whether direct or consequential, to any user or any other person, arising out of or from the use of the information contained in the Site.</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.75in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;text-indent:-32.7pt;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;In no event shall TalentOnLease shall be liable for the provision of or failure to provide all or any service by listed third parties to users contacted or managed through the Site;&nbsp;</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:0in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;text-indent:-32.7pt;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;'>Under no circumstances, including but not limited to negligence, shall TalentOnLease, its employees and directors, its suppliers and its third party advisors be liable for any indirect, incidental, special or consequential damages or any damages whatsoever including punitive or exemplary (including, but not limited to, damages caused by any virus, personal injury, loss of profits, data or other intangible, business interruption, loss of privacy, or any other pecuniary loss), arising out of or in any way connected with the use/delivery/performance of the Site, with the delay or inability to use this Site or any links or items on the Site, the provision of or failure to provide Services, or for any information, software, products, services and related graphics obtained through this Site, or otherwise arising out of the use of this Site, whether based on contract, tort, strict liability or otherwise, or for cost of procurement of substitute goods and repair &amp; correction services or resulting from the use of this Site or obtained or messages received or transactions entered into through or from the Site or resulting from unauthorized access to or alteration of your transmissions or data, even if we have been advised of the possibility of such damages. &nbsp;</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.75in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;text-indent:-32.7pt;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;'>You agree that we shall not be liable for any indirect, incidental, special, consequential or exemplary damages arising from interruption, suspension or termination of the Site, including but not limited to, whether such interruption, suspension or termination was justified or not, negligent or intentional, inadvertent or advertent. Applicable law may not allow the limitation or exclusion of liability or incidental or consequential damages. However, in no event shall our liability to you for all damages, losses and causes of action (whether in contract or tort, including but not limited to, negligence) exceed the amount paid by You, if any, for accessing the Site.&nbsp;</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.75in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;text-indent:-32.7pt;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;'>All transactions that are carried out by and on behalf of the User shall be subject to Government notifications, the rules, regulations and guidelines issued by regulatory bodies defining rules/regulations governing the offer of any service on the Site. TalentOnLease shall not be liable if any transaction does not fructify or may not be completed or for any failure on part of TalentOnLease to perform any of its obligations under these User Terms or those applicable specifically to its services/facilities if performance is prevented, hindered or delayed by a Force Majeure event and in such case, its obligations shall be suspended for so long as the Force Majeure event continues.</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-indent:-32.7pt;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>The User understands and agrees that any material and/or data downloaded or otherwise obtained through the Site is done entirely at his/her own discretion and risk and the User himself/herself will be solely responsible for any damage to their computer systems or loss of data that results from the download of such material and/or data.</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:0in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;text-indent:-32.7pt;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;color:#191823;background:white;'>Our total liability to you for any cause of action arising out of or related to these Terms or to your use of the Site or information we supply you (including without limitation warranty claims), regardless of the forum and regardless of whether any action or claim is based on contract, tort (including negligence), or any other legal or equitable theory, will not exceed &nbsp;the total amount paid by you to TalentOnLease. The existence of more than one claim shall not extend this limit.&nbsp;</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:8.0pt;font-size:11.0pt;text-indent:-32.7pt;'><span style='font-size:16px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;'>If Applicable Law does not permit the exclusion of certain warranties or the limitation or exclusion of liability, the scope and duration of such warranty exclusions and the extent of the liability of TalentOnLease shall be the minimum permitted under Applicable Law.</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.75in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<ol style="margin-bottom:0in;margin-top:0in;" start="14" type="1">
    <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:     115%;border:none;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>THIRD-PARTY WEBSITES</span></strong></li>
</ol>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>The Site may be linked to the website of third parties, affiliates and business partners. However, TalentOnLease is not, directly or indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with any linked website, unless specifically stated herein. We are not responsible for examining or evaluating, and we do not warrant the offerings of, any businesses or individuals or the content of their websites. We do not assume any responsibility or liability for the actions, products, services, and content of any other third parties. You should carefully review the legal statements and other conditions of use of any website which you access through a link from this Site. Your linking to any other off-site websites is at your own risk.</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.75in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;text-indent:-32.7pt;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>TalentOnLease has no control over and is not liable or responsible for the content, accuracy, validity, reliability, and quality of such websites or made available by/through our Site. TalentOnLease assumes no responsibility, and shall not be liable for, any damages to, or viruses that may infect your equipment or access to your account, use of, or browsing the Site or the downloading of any material, data, text, images, video content, or audio content from the Site. If you are dissatisfied with the Site, your sole remedy is to discontinue using the Site.&nbsp;</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;text-indent:-32.7pt;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>TalentOnLease shall not be liable, at any time, for any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communications line failure, theft or destruction or unauthorised access to, alteration of, or use of the information contained at the third party sites.</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>TalentOnLease reserves the right to display sponsored ads on the Site. Without prejudice to the status of other content, TalentOnLease shall not be liable for the accuracy of the information or the claims made in the sponsored listings. TalentOnLease does not encourage the Users to visit the sponsored listings page or to avail of any services from them. TalentOnLease will not be liable for the services of the providers of the sponsored listings.&nbsp;</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:0in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
<ol style="margin-bottom:0in;margin-top:0in;" start="15" type="1">
    <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:     115%;border:none;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>TERM AND TERMINATION OF USER TERMS</span></strong></li>
</ol>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>&nbsp;Unless terminated, the Agreement between TalentOnLease and the User is perpetual in nature upon browsing the Site and availing the Services through the Site.</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.75in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;text-indent:-32.7pt;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>TalentOnLease reserves the right to suspend or terminate a User&rsquo;s access to the Site and the Services with or without notice and to exercise any other remedy available under law, in cases where:</span></li>
    </ol>
</div>
<ol style="list-style-type: undefined;margin-left:1in;">
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;color:black;'>Such User breaches any terms and conditions of the Agreement;&nbsp;</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;color:black;'>A third party reports the violation of any of its rights as a result of your use of the Services.</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;color:black;'>TalentOnLease is unable to verify or authenticate any information provided to TalentOnLease by a User;&nbsp;</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;color:black;'>TalentOnLease has reasonable grounds for suspecting any illegal, fraudulent or abusive activity on part of such User; or;</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;color:black;'>TalentOnLease believes in its sole discretion that User&rsquo;s actions may cause legal liability for such User, other users or TalentOnLease or are contrary to the interests of the Site.</span></li>
</ol>
<p style='margin-right:0in;margin-left:1.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;text-indent:-32.7pt;line-height:115%;background:white;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>Once temporarily suspended, indefinitely suspended or terminated, the User may not continue to use the Site under the same account, a different account or re-register under a new account. On termination of an account due to the reasons mentioned herein, such User shall no longer have access to data, messages, files and other material kept on the Site by such User.</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.75in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;text-indent:-32.7pt;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>Termination of this agreement will not prejudice accrued rights of either TalentOnLease or the User.</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;text-indent:-32.7pt;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>Clauses pertaining to Indemnification, Liability Limitation of Liability, Intellectual Property Ownership, Term and Termination, Notice and Applicable Law and Dispute Resolution and such other provisions which are intended to survive the termination, shall survive the termination of these User Terms in accordance with their terms</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:0in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>.</span></p>
<ol style="margin-bottom:0in;margin-top:0in;" start="16" type="1">
    <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:     115%;border:none;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>AUTHORITY TO TALENTONLEASE</span></strong></li>
</ol>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>The User hereby authorizes TalentOnLease to carry out his instructions, effecting such transactions as may be permitted by TalentOnLease from time to time, in accordance with these User Terms and such other terms as may be specified.</span></p>
<p style='margin-right:0in;margin-left:0in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;line-height:115%;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<ol style="margin-bottom:0in;margin-top:0in;" start="17" type="1">
    <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;line-height:115%;border:none;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>APPLICABLE LAW AND DISPUTE RESOLUTION</span></strong></li>
</ol>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;line-height:115%;border:none;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>These User Terms are subject to the laws of India. Any dispute, claim or controversy arising out of or relating to these User Terms or the breach, termination, enforcement, interpretation or validity thereof or the use of the Site, the Service (collectively, &ldquo;Disputes&rdquo;) the parties shall attempt to settle the same amicably, through negotiation and consultation at such offices of TalentOnLease as TalentOnLease may designate. In the event the dispute is not resolved internally after at least 30 (thirty) days of negotiation, in good faith, the same shall be subject to binding and final arbitration in accordance with the Arbitration and Conciliation Act, 1996 as amended from time to time. The reference shall be made to a sole arbitrator appointed by TalentOnLease and the venue of arbitration shall be Noida</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.75in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><em><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></em></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>This mandatory Arbitration clause shall survive any termination of your use of the Site or information we supply.</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.75in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><em><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></em></p>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<ol style="margin-bottom:0in;margin-top:0in;" start="18" type="1">
    <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:     115%;border:none;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>CONTACT INFORMATION OF THE GRIEVANCE OFFICER</span></strong></li>
</ol>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>If a User has any questions concerning the Site, Services and this Agreement, or anything related to any of the foregoing, please write to us at&nbsp;</span><a href="mailto:info@talentonlease.com"><span style="font-size:16px;">info@talentonlease.com</span></a><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>.</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.75in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;text-indent:-32.7pt;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: undefined;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;'><span style='line-height:115%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>In accordance with the Information Technology Act, 2000, and the rules made there under, if you have any grievance with respect to the Site or the Service, including any discrepancies and grievances with respect to the processing of information, you can contact our Grievance Officer</span></li>
    </ol>
</div>
<p style='margin-right:0in;margin-left:.75in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>Details of the Grievance Officer&nbsp;</span></p>
<p style='margin-right:0in;margin-left:.75in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>Name &ndash; Mr. Pervesh Dhingra</span></p>
<p style='margin-right:0in;margin-left:.75in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>E-mail ID -&nbsp;</span><a href="mailto:pervesh.dhingra@talentonlease.com"><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>pervesh.dhingra@talentonlease.com</span></a></p>
<p style='margin-right:0in;margin-left:0in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><em><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></em></p>
<ol style="margin-bottom:0in;margin-top:0in;" start="19" type="1">
    <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:     115%;background:white;border:none;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;color:black;'>SEVERABILITY</span></strong></li>
</ol>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;background:white;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;background:white;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;color:black;'>If any provision of this agreement is held by a court of competent jurisdiction or arbitral tribunal to be unenforceable under applicable law, then such provision shall be excluded from this Agreement and the remainder of the Agreement shall be interpreted as if such provision were so excluded and shall be enforceable in accordance with its terms; provided however that, in such event, the Agreement shall be interpreted so as to give effect, to the greatest extent consistent with and permitted by applicable law, to the meaning and intention of the excluded provision as determined by such court of competent jurisdiction or arbitral tribunal.</span></p>
<p style='margin-right:0in;margin-left:0in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;background:white;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<ol style="margin-bottom:0in;margin-top:0in;" start="20" type="1">
    <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:     115%;background:white;border:none;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;color:black;'>CONFLICT</span></strong></li>
</ol>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;background:white;border:none;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;background:white;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;color:black;'>In the event of any contradiction or inconsistency between this Agreement and any other agreement executed between you and TalentOnLease, the terms of this Agreement shall prevail unless the exception has been expressly agreed to in writing by making reference to the relevant Clause sought to be modified under this Agreement.&nbsp;</span></p>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;background:white;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<ol style="margin-bottom:0in;margin-top:0in;" start="21" type="1">
    <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;text-align:justify;line-height:     115%;background:white;border:none;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;color:black;'>WAIVER</span></strong></li>
</ol>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;background:white;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;background:white;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;color:black;'>No provision of this Agreement shall be deemed to be waived and no breach excused unless such waiver or consent shall be in writing and signed by TalentOnLease. Any consent by TalentOnLease or a waiver by TalentOnLease for any breach by you, whether expressed or implied, shall not constitute consent to, waiver of, or excuse for any other different or subsequent breach.</span></p>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;background:white;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<ol style="margin-bottom:0in;margin-top:0in;" start="22" type="1">
    <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;font-size:11.0pt;font-family:"Poppins", sans-serif;line-height:115%;border:none;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>JURISDICTIONAL ISSUES</span></strong></li>
</ol>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;line-height:115%;border:none;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:8.0pt;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>Unless otherwise expressly set forth herein, TalentOnLease makes no representation that materials on this Site are appropriate or available for use in any location. Those who choose to access this Site agree to do so at their own initiative and are responsible for compliance with local laws. The Services which are part of the Site do not constitute an offer to sell or a solicitation of an offer to buy to any person in any jurisdiction where it is unlawful to make such an offer or solicitation. &nbsp;</span></p>
<p style='margin-right:0in;margin-left:.5in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:14.0pt;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
<p style='margin-right:0in;margin-left:.75in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;border:none;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin-right:0in;margin-left:0in;font-size:16px;font-family:"Poppins", sans-serif;margin-top:0in;margin-bottom:0in;font-size:11.0pt;text-align:justify;line-height:115%;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>

    </div>
</section>