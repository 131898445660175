import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../shared/material.module';
import { SearchtoolbarComponent } from './searchtoolbar.component';



@NgModule({
  declarations: [
    SearchtoolbarComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
  ],
  exports:[
    CommonModule,
    FormsModule,
    SearchtoolbarComponent
  ]
})
export class SearchToolbarModule { }
