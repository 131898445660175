import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { BehaviorSubject, Observable, map } from "rxjs";
import { Router } from "@angular/router";
import { CandidateService } from "./candidate.service";
@Injectable({ providedIn: 'root' })
export class JobService {
    private readonly jobEndPoint = 'Job/';
    constructor(private http: HttpClient,
      private router: Router, private candidateService:CandidateService) {
      }

      getlatestjobs(model: any) {
        return this.http.post<any>(`${environment.apiUrl}${this.jobEndPoint}findlatestjobs`, model)
          .pipe(map(res => {         
            return res;
          }));
      }

      getAll(model:any) {
        const httpOptions = { params: model }; 
        return this.http.get<any>(`${environment.apiUrl}${this.jobEndPoint}getAll`,httpOptions)
      }

      getAllQuestion(model:any) {
        const httpOptions = { params: model }; 
        return this.http.get<any>(`${environment.apiUrl}${this.jobEndPoint}GetAllQuestion`,httpOptions)
      }
      jobpublish(model:any) {
        const httpOptions = { params: model }; 
        return this.http.delete<any>(`${environment.apiUrl}${this.jobEndPoint}publish`,httpOptions)
      }

      blockJob(model:any) {
        const httpOptions = { params: model }; 
        return this.http.delete<any>(`${environment.apiUrl}${this.jobEndPoint}blockJob`,httpOptions)
      }
      downloadJob(model:any){ 
       return this.http.get(`${environment.apiUrl}${this.jobEndPoint}downloadJob`,{
          responseType: 'arraybuffer',params:model} 
         )
      }

      getJobdetails(jobId:any) {
        return this.http.get<any>(`${environment.apiUrl}Job?id=${jobId}`)
      }


      savejob(model: any) {
        return this.http.post<any>(`${environment.apiUrl}${this.jobEndPoint}`, model)
          .pipe(map(res => {         
            return res;
          }));
      }
      updatejob(model: any) {
        return this.http.put<any>(`${environment.apiUrl}${this.jobEndPoint}`, model)
          .pipe(map(res => {         
            return res;
          }));
      }
      saveQuestion(model: any) {
        return this.http.post<any>(`${environment.apiUrl}${this.jobEndPoint}Question`, model)
          .pipe(map(res => {         
            return res;
          }));
      }
      

      getAppliedCandidateDetails(model:any) {
        const httpOptions = { params: model }; 
        return this.http.get<any>(`${environment.apiUrl}${this.jobEndPoint}GetAppliedCandidateDetails`,httpOptions)
      }

      deleteJob(id:any){
        return this.http.delete<any>(`${environment.apiUrl}${this.jobEndPoint}?Id=${id}`)
       }
       questionDelete(id:any){
        return this.http.delete<any>(`${environment.apiUrl}${this.jobEndPoint}QuestionDelete?Id=${id}`)
       }
       
       updateCandidateStatus(model: any) {
        return this.http.post<any>(`${environment.apiUrl}${this.jobEndPoint}updateCandidateStatus`, model)
          .pipe(map(res => {         
            return res;
          }));
      }

      uploadJD(model:any){ 
        return  this.http.post<any>(`${environment.apiUrl}${this.jobEndPoint}uploadJD`,model)
          .pipe(map(res => {     
            return res;
          }));
        }
        createSearchJob(model:any){
          return this.http.post<any>(`${environment.apiUrl}${this.jobEndPoint}createSearchJob`, model)
          .pipe(map(res => {         
            return res;
          }));
        }
}
