<!-- <div
  (window:beforeunload)="doBeforeUnload()"
  (window:unload)="doUnload()"> -->


<section class="tolquestions">
  
  <div *ngIf="!isLoading" class="row">
    <div class="col-md-12">
      <div class="qname mb-2"><strong>{{candidateDetails?.fullName}}</strong><span
          class="qtech ml-2">{{candidateDetails?.designation}}</span></div>
      <!-- <div class="qdegi mb-2"><strong>Designation:</strong> {{candidateDetails?.designation}}</div> -->
      <div class="qtech mb-2"><strong>Technology:</strong> {{candidateDetails?.technology}}</div>
    </div>

    <div class="col-md-12">
      <div class="question-sec mt-4">
        <div class="timer">{{timeLeft}} Seconds Left....</div>
        <div class="questions-col mb-3"><strong>Q:{{currentQuestion}} {{question?.description}}</strong></div>
        <div class="answer-col mb-2 ml-4">
          <div *ngIf="question.questionType=='O'">
            <mat-radio-group aria-label="Select an option" [(ngModel)]="answer">
              <mat-radio-button value="{{questionAnswers?.answerA}}">{{questionAnswers?.answerA}}</mat-radio-button>
              <mat-radio-button value="{{questionAnswers?.answerB}}">{{questionAnswers?.answerB}}</mat-radio-button>
              <mat-radio-button value="{{questionAnswers?.answerC}}">{{questionAnswers?.answerC}}</mat-radio-button>
              <mat-radio-button value="{{questionAnswers?.answerD}}">{{questionAnswers?.answerD}}</mat-radio-button>
            </mat-radio-group>
          </div>
           <!--<div class="form-group" *ngIf="question.questionType!='O'">
             <mat-form-field class="field-round">
              <mat-label>Answer</mat-label>
             <textarea matInput placeholder="answer..." [(ngModel)]="answer"></textarea>
            </mat-form-field> 
          </div>-->
        </div>

        <div class="cta mt-5" style="text-align: center;">
          <button mat-button *ngIf="!isSubmit" (click)="nextQuestion(question)"
            class="btn btn-primary w-100">Next..</button>
          <button mat-button *ngIf="isSubmit" class="btn btn-primary w-100" (click)="submit()">Submit</button>
        </div>

        <div class="vedio_container_div mt-4">
          <!--start row-->
          <div class="row">
            <div class="col-md-1">

            </div>
            <div class="col-12 col-md-10" style="position: relative;">
              <div id="local_video_div" class="adjustvideo">

              </div>
              <!--add button-->
              <div class="controls" id="controls" style="position: absolute; bottom: 9px;
              width: 82%;
              text-align: center;">
                <div style="margin-left:140px;">
                  <!-- <img src="../../../../assets/enablex/mike.png" style="margin-right: 20px;cursor: pointer;"
                    class="cus_img_icon icon-confo-mute" (click)="audioMute()" title="mute audio" />
                  <img src="../../../../assets/enablex/video.png" style="margin-right: 20px;cursor: pointer;"
                    class="cus_img_icon icon-confo-video-mute" title="mute video" (click)="videoMute()" /> -->
                  <img src="../../../../assets/enablex/end-call.png" style="margin-right: 20px;cursor: pointer;"
                    class="cus_img_icon end_call" title="End_call" (click)="endCall()" />
                </div>
              </div>
              <!--close add button-->
            </div>
            <div class="col-md-1">

            </div>
          </div>
          <!--close row-->
          <!--start row-->
          <div class="row">
            <!-- <div class="col-md-6">
                <div id="local_video_div"></div>
            </div> -->
            <div class="col-md-6">
              <div id="multi_video_container_div">

              </div>
            </div>
          </div>
          <!--close row-->
        </div>

      </div>
    </div>


  </div>
</section>

<!-- </div> -->