<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/css/bootstrap.min.css">
<script>
    $(document).ready(function () {
        $("#testimonial-slider").owlCarousel({

            items: 1,

            itemsDesktop: [1000, 1],

            itemsDesktopSmall: [979, 1],

            itemsTablet: [768, 1],

            pagination: true,

            navigation: false,

            navigationText: ["", ""],

            slideSpeed: 1000,

            singleItem: true,

            transitionStyle: "fade",

            autoPlay: true

        });

    });

</script>


<section class="banner-section">
    <img src="../assets/img/fullstackdeveloperimg/home-banner.jpg" class="main-banner" />
    <div class="container">
        <div class="banner-caption">
            <div class="bg-white">
                <h1> Hire Mern Stack Developer- Let Your Digital Platform Talk for You. </h1>
            </div>
            <p class="first-para">
                Hire mern stack developers gives technologic wings to your ideas, making them realistic. With the latest technologies in hand, TalentOnLease offers you mern developers for faster, efficient, and reliable development of your modern-day web applications. 


                Hire Mern Stack Developer - Modern Technologies Bringing Future Applications. 


            </p>
            <div class="row email-box-main">
                <div class="col-md-6 mx-auto">
                    <button mat-raised-button (click)="openDialog()" class="btn mb-15">Hire Mern Stack Developer Now</button>
                </div>
            </div>
            <div class="icon-text">
                <div class="icon-item">
                    <img src="../assets/img/fullstackdeveloperimg/briefcase.png" alt="briefcase" />
                    <p>200 + Client Served</p>
                </div>
                <div class="icon-item">
                    <img src="../assets/img/fullstackdeveloperimg/start-up.png" alt="Start Up" />
                    <p>500 + Mern Stack Project Delivered</p>
                </div>
                <div class="icon-item">
                    <img src="../assets/img/fullstackdeveloperimg/check.png" alt="check" />
                    <p>7 Days Risk Free Trial</p>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="container">
    <header class="gradient-title-head mt-30 mb-30 col-md-12 mx-auto">
        <h2 class="title-lg text-center">Pouring Dynamics to the Application with Mern Stack Development </h2>
    </header>
    <ul class="hire-point">
        <li>
            Mern Stack Developers use the latest technologies to create faster, efficient, modern, and optimised web-applications, enhancing user interaction and boosting performance. 

        </li>
        <li>
            Say no to old monolithic web application designs and move to 21st century advanced user-interacted web applications. 


        </li>
        <li>
            It's time you stop using tons of coding languages. Mern covers it all. 

M- MongoDB
E- Express.js
R- React.js
N- Node.js

        </li>
        <li>
            MERN complementary technologies use JSON as the central data transmission format, Mern complementary technologies and optimize complex & scalable web applications easily with NoSQL databases. 
        </li>
    </ul>
</div>
<section class="why-talentonlease">
    <div class="container position-relative" style="z-index: 1;">
        <div class="row">
            <div class="col-md-4">
                <img class="person-img" src="../../../../assets/img/talent-person.png"/>
            </div>
            <div class="col-md-8">
                <header class="gradient-title-head  mb-0 col-md-8 mx-auto">
                    <h3 class="title-lg text-center">Why <span>TalentOnLease?</span></h3>
                </header>
                <p class="text-center">Great things happen when great people come together. It’s magic!</p>
                <div class="row text-center">
                    <div class="col-md-4">
                        <div class="w-icon">
                            <span><img src="../../../../assets/img/strong-founding.svg"/></span>
                            Active bench of 7241
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="w-icon">
                            <span><img src="../../../../assets/img/vetted-professionals.svg"/></span>
                            Verified and vetted IT professionals
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="w-icon">
                            <span><img src="../../../../assets/img/acquisition-platform.svg"/></span>
                            Available Within 24 to 48 hours
                        </div>
                    </div>
                </div>
                <div class="text-center mt-2">
                <button mat-raised-button (click)="openDialog()" class="btn mb-15">Start Hiring</button>
            </div>
            </div>
        </div>
   
</div>

</section>



<p class="hire-sub-heading mt-30">
    Join 200+ companies who have trusted TalentOnLease for thier remote engineering
    needs.
</p>
<div class="logo-section">
    <div class="slider">
        <div class="logos">
            <img src="{{leadingcompany.logo}}" *ngFor="let leadingcompany of leadingCompanies">
        </div>

    </div>
</div>
<div class="container mt-30">
    <div class="row">
        <p class="flex-center para text-center pad-15">
            Hiring a Mern Stack Developer from TalentOnLease ensures comprehensive expertise in both frontend and backend
            technologies, streamlined communication, and seamless integration, resulting in faster development cycles
            and cost-effective solutions for your project.
        </p>
        <div class="flex-center check flex-wrap">
            <div class="check-item">
                <img src="../assets/img/fullstackdeveloperimg/check-circle.png" alt="check-circle" />
                <p class="para">Fast hiring within 24 to 48 hours</p>
            </div>
            <div class="check-item">
                <img src="../assets/img/fullstackdeveloperimg/check-circle.png" alt="check-circle" />
                <p class="para">Simple & Transparent Pricing</p>
            </div>
            <div class="check-item">
                <img src="../assets/img/fullstackdeveloperimg/check-circle.png" alt="check-circle" />
                <p class="para">Fully Signed NDA & Agreement</p>
            </div>
            <div class="check-item">
                <img src="../assets/img/fullstackdeveloperimg/check-circle.png" alt="check-circle" />
                <p class="para">Regular Reporting & Transparent Communication</p>
            </div>
            <div class="check-item">
                <img src="../assets/img/fullstackdeveloperimg/check-circle.png" alt="check-circle" />
                <p class="para">Easy Exit Policy</p>
            </div>
        </div>
        <div class="hire-btn-section mx-auto">
            <button mat-raised-button (click)="openDialog()" class="btn mb-15">Hire Mern Stack Developer Now</button>
        </div>
    </div>
</div>

<section class="hire-second-section">
    <div class="container-fluid">
        <header class="gradient-title-head mb-30">
            <h3 class="title-lg text-center">Hire <span>Mern Stack Developer</span></h3>
        </header>
        <div class="developer-main">
            <div class="row">
                <div class="col-lg-8">
                    <div class="hring-main-div">
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Veeresh S</h4>
                                        <p class="dev-title">MERN STACK(MERN Stack)</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>Bangalore</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 4 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>
                                    </div>
                                </div>
                                <p class="desc">
                                    Veeresh is a skilled MERN stack developer proficient in MongoDB, Express.js, React.js, and Node.js. With expertise in full-stack web development, he excels in building modern and interactive applications. 
                                    Veeresh's capabilities span frontend design, backend implementation, RESTful API development, and database management, ensuring robust and scalable solutions across the entire MERN stack.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Ranjeet Singh</h4>
                                        <p class="dev-title">mern developer(MERN Stack)</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>Indore</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 6.5 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>
                                    </div>
                                </div>
                                <p class="desc">
                                    Ranjeet is an experienced MERN stack developer proficient in MongoDB, Express.js, React.js, and Node.js. His expertise spans frontend and backend development, specializing in creating responsive and efficient web applications. 
                                    Ranjeet excels in designing user-friendly interfaces, implementing RESTful APIs, and managing databases to deliver high-performance solutions using the MERN stack.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Arun C</h4>
                                        <p class="dev-title">MERN STACK(MERN Stack)</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>Bangalore</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 6.4 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                                    </div>
                                </div>
                                <p class="desc">
                                    Arun is a dedicated MERN stack developer with extensive experience in MongoDB, Express.js, React.js, and Node.js. 
                                    He excels in creating dynamic and scalable web applications, focusing on responsive design and user-centric interfaces. Arun's proficiency includes backend development, API integration, and database management, delivering robust solutions that meet business objectives effectively within the MERN stack environment.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Ayush Sharma</h4>
                                        <p class="dev-title">MERN STACK(MERN Stack)</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>Other</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 4.7 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                                    </div>
                                </div>
                                <p class="desc">
                                   Ayush stands out as a dynamic MERN Stack Developer proficient in MongoDB, Express.js, React, and Node.js. His expertise spans building scalable web applications with a strong emphasis on real-time data processing and integration. 
                                   Ayush excels in deploying cloud-based solutions, implementing microservices architecture, and integrating cutting-edge technologies to deliver robust and innovative software solutions that drive business growth.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">SriKrishna N</h4>
                                        <p class="dev-title">MERN STACK(MERN Stack)</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>Bangalore</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 4.5 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                                    </div>
                                </div>
                                <p class="desc">                                  
                                    SriKrishna is a skilled MERN stack developer with a deep understanding of MongoDB, Express.js, React.js, and Node.js. He specializes in creating robust, scalable web applications, focusing on frontend design and backend architecture. 
                                    SriKrishna excels in integrating APIs, managing databases, and ensuring optimal performance across all layers of the MERN stack to deliver innovative and efficient software solutions.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Bharat Goswami</h4>
                                        <p class="dev-title">MERN STACK(MERN Stack)</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>Indore</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 4.4 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                                    </div>
                                </div>
                                <p class="desc">
                                    Bharat is an innovative MERN stack developer proficient in MongoDB, Express.js, React.js, and Node.js. 
                                    He excels in architecting and deploying full-stack applications, emphasizing responsive design and seamless user experiences. Bharat's expertise includes optimizing backend performance, integrating third-party APIs, and implementing secure authentication protocols. He consistently delivers scalable solutions that meet modern business demands and enhance user engagement.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Abhinav Goyal</h4>
                                        <p class="dev-title">MERN STACK(MERN Stack)</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>Indore</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 4.4 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                                    </div>
                                </div>
                                <p class="desc">
                                   Abhinav is a versatile MERN stack developer adept at MongoDB, Express.js, React.js, and Node.js. He specializes in crafting scalable web applications with a keen focus on user experience and performance optimization. 
                                   Abhinav's skills encompass frontend development, backend architecture, API integration, and database management, ensuring robust and efficient solutions across the MERN stack.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Milan Vala</h4>
                                        <p class="dev-title">Senior Web Developer(MERN Stack)</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>Ahemdabad</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 5 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                                    </div>
                                </div>
                                <p class="desc">
                                   Milan stands out as a MERN stack developer with a strong command of MongoDB, Express.js, React.js, and Node.js. His expertise lies in creating intuitive and responsive web applications, leveraging extensive knowledge in frontend design and backend development. 
                                   Milan excels in optimizing application performance, implementing secure APIs, and delivering seamless user experiences that drive business success within the MERN stack framework.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Mukesh Maurya</h4>
                                        <p class="dev-title">Mernstack(MERN Stack)</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>Other</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 5 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                                    </div>
                                </div>
                                <p class="desc">
                                    Mukesh is an accomplished MERN stack developer proficient in MongoDB, Express.js, React.js, and Node.js. He excels in architecting scalable and performant web applications, emphasizing seamless user interfaces and efficient backend solutions. 
                                    Mukesh's expertise includes robust API integration, database management, and continuous optimization to deliver innovative and reliable software solutions within the MERN stack ecosystem.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Showkat Ahmed</h4>
                                        <p class="dev-title">Mernstack(MERN Stack)</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>Other</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 4 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                                    </div>
                                </div>
                                <p class="desc">
                                   Showkat is a skilled MERN stack developer proficient in MongoDB, Express.js, React.js, and Node.js. With over [X] years of experience, he specializes in designing and implementing robust web applications. 
                                   Showkat excels in frontend development, creating responsive user interfaces, and backend architecture, ensuring optimal performance and scalability. His expertise includes API integration, database management, and delivering high-quality, innovative solutions that meet business objectives effectively within the MERN stack environment.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div class="col-lg-4 bg-white testimonial-main">
                    <h5 class="title-md trust-title mt-3">Testimonial  </h5>
                    <div class="right-section">
                        <div class="box-shadow trustpilot">
                            <p class="quote-open">
                                <i class="fa fa-quote-left" aria-hidden="true"></i>
                            </p>
                            <p class="trust-desc">
                                We hire about 16 Mern Stack Developers from TalentOnLease which
                                reduced our hiring effort by 90% as compared to
                                other venders.
                            </p>
                            <div class="signature">
                                <div class="sign-icon">
                                    <img src="assets/img/fullstackdeveloperimg/healthcare.png" alt="sign-icon">
                                </div>
                                <p class="sign-desc">
                                    Managing Director MeasurePM, USA based Healthcare company
                                </p>
                            </div>
                        </div>
                        <div class="box-shadow trustpilot">
                            <p class="quote-open">
                                <i class="fa fa-quote-left" aria-hidden="true"></i>
                            </p>
                            <p class="trust-desc">
                                We're super excited about TalentOnLease as we will scrap our existing
                                lengthy interview process and lean on TalentOnLease's vetting to build
                                up teams on demand.
                            </p>
                            <div class="signature">
                                <div class="sign-icon">
                                    <img src="assets/img/fullstackdeveloperimg/piggy-bank.png" alt="sign-icon">
                                </div>
                                <p class="sign-desc">
                                    Director of Engineering Unikove Technologies, Software Service company
                                </p>
                            </div>
                        </div>
                        <div class="contact-form p-4 box-shadow trustpilot pb-0">
                            <form [formGroup]="contactUsForm" (ngSubmit)="submiContactUs()">
                                <h5>Contact us</h5>
                                <div class="form_group mt-4">
                                    <input type="text" name="name" value="" formControlName="name" size="40"
                                        class="form-control" aria-invalid="fase" placeholder="Fullname*"
                                        required="">
                                    <div *ngIf="contactflag && contactUsForm.controls['name'].errors" class="mt-2">
                                        <div *ngIf="contactUsForm.controls['name'].errors">
                                            <div *ngIf="contactUsForm.controls['name'].errors?.['required']">
                                                <p class="text-danger">Full Name is Required</p>
                                            </div>
                    
                                        </div>
                                    </div>
                                </div>
                                <div class="form_group mt-3">
                                    <input type="text" formControlName="email" name="email" value="" size="40"
                                        class="form-control" aria-invalid="fase" placeholder="Email*"
                                        required="">
                                    <div *ngIf="contactflag && contactUsForm.controls['email'].errors" class="mt-2">
                                        <div *ngIf="contactUsForm.controls['email'].errors?.['required']">
                                            <p class="text-danger">Email is Required</p>
                                        </div>
                                        <div *ngIf="contactUsForm.controls['email'].errors?.['email']">
                                            <p class="text-danger">Email is not valid</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="form_group mt-3">
                                    <input type="text" name="number" value="" size="40" class="form-control"
                                        aria-invalid="fase" placeholder="Phone*" formControlName="mobile"
                                        minlength="10" maxlength="10" required="">
                                    <div *ngIf="contactflag && contactUsForm.controls['mobile'].errors">
                                        <div *ngIf="contactUsForm.controls['mobile'].errors" class="mt-2">
                                            <!-- <p class="text-danger">Mobile Number is Required</p> -->
                                            <div *ngIf="contactUsForm.controls['mobile'].errors?.['required']">
                                                <p class="text-danger">mobile number is Required</p>
                                            </div>
                                            <div *ngIf="contactUsForm.controls['mobile'].errors?.['pattern']">
                                                <p class="text-danger">mobile number should be number only</p>
                                            </div>
                                            <div *ngIf="contactUsForm.controls['mobile'].errors?.['minlength']">
                                                <p class="text-danger">mobile number should be minimum 10 digit</p>
                                            </div>
                                            <div *ngIf="contactUsForm.controls['mobile'].errors?.['maxlength']">
                                                <p class="text-danger">mobile number should be maximum 10 digit</p>
                                            </div>
                    
                                        </div>
                                    </div>
                                </div>
                                <div class="form_group mt-3">
                                    <input type="text" formControlName="designation" name="designation" value="" size="40"
                                        class="form-control" aria-invalid="fase" placeholder="Designation*"
                                        required="">
                                        <div *ngIf="contactflag && contactUsForm.controls['designation'].errors" class="mt-2">
                                            <div *ngIf="contactUsForm.controls['designation'].errors">
                                                <div *ngIf="contactUsForm.controls['designation'].errors?.['required']">
                                                    <p class="text-danger">Designation is Required</p>
                                                </div>
                        
                                            </div>
                                        </div>
                                </div>
                    
                                <div class="form_group mt-3">
                                    <textarea name="messages" formControlName="message" class="form-control" required=""
                                        aria-invalid="fase" placeholder="Message" style="height:84px;"
                                        data-gramm="fase" wt-ignore-input="true"></textarea>
                                </div>
                                <div class="form_group text-right mt-4 mb-1 d-flex">
                                    <input type="submit" name="submit" value="Submit" class="btn-hire btn-block mr-2 mat-raised-button">
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
<section class="why-hire-full-stack-section">
    <div class="container">
        <header class="gradient-title-head mb-30">
            <h2 class="title-lg text-center">Hire Mern Stack Developers from TalentOnLease</h2>
        </header>
        <p class="text-center">
            Hiring Mern Stack Developers with TalentOnLease is Easy! When you hire a Mern Stack Developer with
            TalentOnLease, it Merns peace of mind! We follow a transparent hiring process and well-defined processes on
            all our engagements.
        </p>
        <div class="why-full-stack-list">
            <div class="row">
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/thunder.png" /></span>
                        <p>Comprehensive Skills</p>
                        <p>Get the right excellence of coding skills and the technical understanding of customs demands your ideal web-application with TalentOnLease. </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/efficiency.png" /></span>
                        <p>Wide Pool of Resources</p>
                        <p>We offer you a wide pool of the best 1% developers. Now choose the right one that fits 
                            with your requirements with no compromise on quality. </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/collaborate.png" /></span>
                        <p>Seamless Integration</p>
                        <p>Integrate our devs with your developer's space and rocket boost your ideal web-app development.
                             Work with clear policies and transparent models. </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/cost.png" /></span>
                        <p>Cost-Effectiveness</p>
                        <p>Sign up with TalentOnLease and save up to 40-50% of total market cost with the liberty
                             of personal assistance, modulation, and scaling when needed.  
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/innovation.png" /></span>
                        <p>Innovation and Problem-Solving</p>
                        <p>Anyone can develop an app, but very few add depth to modern-day innovation and 
                            problem-solving projects. That's where we become different.  
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/verified-icon.png" /></span>
                        <p>Vetted IT Professionals                        </p>
                        <p>We never compromise on the quality of our team. All devs working with us are professionals, skilled at 
                            core mern technology, and boast years of experience.
                        </p>
                    </div>
                </div>
            </div>
            <p class="text-center">Partner with TalentOnLease and harness the power of DOT-NET expertise for your
                digital endeavors. Let
                us drive your project forward
                with efficiency, innovation, and excellence</p>


        </div>
        
    </div>
</section>



<section class="how-hire-devloper-section">
    <div class="hhd-main">
        <div class="hhd-left mb-30 hhd-main-item">
            <div class="gradient-title-head my-5">
                <h2 class="title-lg text-center">How to Hire Quality <span>Mern Stack Developer</span></h2>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">1</div>
                            <div class="hire-div">
                                <p>Define Job Requirements
                                </p>
                                <p>Clearly outline the job responsibilities, qualifications, and skills required for the
                                    position.
                                    This
                                    information will form the basis for your job description</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">2</div>
                            <div class="hire-div">
                                <p>Find Per Vetted
                                    Candidates
                                    from pool of TalentOnLease
                                </p>
                                <p>Develop a compelling job description that includes key details about the position,
                                    responsibilites,
                                    qualifications, and any other relevant information.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">3</div>
                            <div class="hire-div">
                                <p>Resume Screening
                                </p>
                                <p>Review resumes to shortlist candidates who meet the basic qualifications.</p>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="row">
                    
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">4</div>
                            <div class="hire-div">
                                <p>Conduct
                                    Interviews
                                </p>
                                <p>Organize interviews with key team members or stackholders to gather multiple
                                    perspectives on
                                    the
                                    candidate.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">5</div>
                            <div class="hire-div">
                                <p>Offer Rollout
                                </p>
                                <p>Offer to the selected candidate with Immediate start date.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">6</div>
                            <div class="hire-div">
                                <p>Background Check
                                </p>
                                <p> Conduct background checks as necessary to verify the candidate's educational and
                                    professional
                                    background.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">7</div>
                            <div class="hire-div">
                                <p>Onboarding
                                </p>
                                <p> Sign Contract with TalentOnLease and welcome the new hire with a comprehensive
                                    onboarding
                                    process to
                                    ensure
                                    a smooth transition into their role and responsibility.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">8</div>
                            <div class="hire-div">
                                <p>Feedback and Continuous
                                    Improvement
                                </p>
                                <p> We gather time to time feedback from client to check the performance of the
                                    candidate.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>

 <section class="major-key-section container">
    <div class="row">
        <header class="gradient-title-head mb-30 mx-auto">
            <h3 class="title-lg text-center">Hire <span> Mern Stack Developers </span> for Your Web Application- What Benefits Are On the Table?</h3>
        </header>
        <p class="mk-desc">
            Owing to years of industry experience and technology exposure, our full stack taskforce is well-competent at
            creating attractive web application and websites for you. They go a step ahead of the rest to unlock
            advantages obtained by full stack development.
        </p>
        <div class="mkb-section">
            <div class="mkb-section-items">
                <p class="heading">Full-Stack Proficiency</p>
                <p class="desc">
                    Mern stack developers are skilled in both frontend & backend techs, bringing seamless integration,
                     dynamic UI/UX with enhanced layered communication. 
                </p>
            </div>
            <div class="mkb-section-items">
                <p class="heading">Single Language Mastery</p>
                <p class="desc">
                    MERN technology simplifies the development process by making everything a one-language game. 
                    It simplifies development and makes it consistent with improved UI/UX.  
                </p>
            </div>
            <div class="mkb-section-items">
                <p class="heading">Rapid Development
                </p>
                <p class="desc">
                    Hire Mern stack developers for your project to be done at the quickest and with flexible iteration. Reusable code, quicker
                     development cycles, and accelerated time for market use.
                </p>
            </div>
            <div class="mkb-section-items">
                <p class="heading">Isomorphic Code
                </p>
                <p class="desc">
                    Mern technology uses Javascript on both sides-servers and clients, offering to use isomorphic codes 
                    for better page speed, performance, and enhanced user-experience.
                </p>
            </div>
            <div class="mkb-section-items">
                <p class="heading">Cost-Effective Solutions</p>
                <p class="desc">
                    Mern stack development tools are available as open-sourced technologies.
                     They save your money, reduce the cost and offer better financial deals.
                </p>
            </div>
            <div class="mkb-section-items">
                <p class="heading">Real-time Capabilities</p>
                <p class="desc">
                    With Node.js as server side development, web applications analyse
                     the real-time responses throughout cross-platform web apps.
                </p>
            </div>
        </div>
    </div>
</section> 


<section class="section faqs-page-sec">
    <div class="container">
        <header class="gradient-title-head text-center">
            <small class="small-head">How we can help?</small>
            <h4 class="title-lg">Frequently Asked <span>Questions</span></h4>
        </header>
        <div class="row">
            <div class="col-md-4">
                <img src="assets/img/fullstackdeveloperimg/faq.png" class="img-fluid" />
            </div>
            <div class="col-md-8">
                <div class="tabbing-faq-sec">
                    <div class="tabbing-body">
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>What is a MERN Stack Developer?
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p>MERN Stack developers use MERN technologies to build dynamic website applications. Here MERN stands for MongoDB, Express, React, and Node.js. Each of these technologies plays a crucial role in the development process. 
                                </p>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>Why use MERN Stack Developer for your web application?
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p>Using MERN technology offers you quite many benefits in comparison to others. Such as:   </p>
                                <ul>
                                <li>They are handsomely experienced programmers. </li>
                                <li>MERN developers can build cross-platform technology which can sync well both- online & offline.</li>
                                </ul>
                                <p>   Hire Men stack developers to upgrade your web apps.   </p>
                                <ul>
                                <li>They offer on-time deliveries.</li>
                                <li>MERN stack developers are budget-friendly.</li>
                                <li>Maintenance of MERN developers is easy.</li>
                                <li>MERN developers offer more flexibility. </li>
                            </ul>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>  What skills should I look for when hiring a MERN Stack Developer?
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p>Check the below-listed skills in a MERN Stack developer.  </p>
                                <ul>
                                    <li>HTTP/HTTPS </li>
                                    <li>CSS</li>
                                    <li>JavaScript</li>
                                    <li>VS Code</li>
                                    <li>GIT</li>
                                    <li>Sass</li>
                                    <li>Bootstrap</li>
                                    <li>Web Designs Basic</li>
                                    <li>React</li>
                                    <li>Node.JS</li>
                                    <li>Express.JS</li>
                                    <li>MongoDB</li>
                                  </ul>
                              
                                   
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>Would you replace the developer if it doesn't perform well?
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p>Yes, customer feedback is always a priority and we will replace the developer if any such requirement comes to us. 
                                </p>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>  How much does it cost to hire a MERN developer?
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p>The average cost to hire Mern stack developers starts from rs/- 1000 hour only. 
                                </p>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>  What qualifications and experience do your MERN Stack developers possess?
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p>We hire developers through a rigorous procedure of tests & interviews. First, the candidates must have graduated from Computer Science or related streams.
                                     Then after the test & interview procedure starts. Moreover, the developers must be in the profession and actively working for more than 1+ years. 
                                </p>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>

<section class="ready-get-started-section">
    <header class="gradient-title-head mb-30">
        <h3 class="title-lg text-center">Ready To Get <span>Started</span></h3>
    </header>
    <p>
        Competitive Prices, Quality Development! 
         Hire Mern Developers from TalentOnLease.
         </p>
         <p>
            If you're ready to elevate your projects with our  Mern Stack developers connect with us today.
    </p>
    <button mat-raised-button (click)="openDialog()" class="mat-focus-indicator btn btn-primary mat-button-base mb-15">Contact Us Now</button>
</section>
<div class="whatsapp-button fixed-button"><a href="https://wa.link/ixaedq" target="blank"><i class="fa fa-whatsapp"
    aria-hidden="true"></i></a>

</div>

<div class="callno-button fixed-button"><a href="tel:+919899240555" target="blank"><i class="fa fa-phone"
    aria-hidden="true"></i></a>

</div>

