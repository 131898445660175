<section class="section faqs-page-sec">
  <div class="container">
    <header class="gradient-title-head text-center">
      <small class="small-head">Hello, How we can help?</small>
      <h4 class="title-lg">Frequently Asked <span>Questions</span></h4>
    </header>
    <div class="tabbing-faq-sec">
      <mat-tab-group>
        <mat-tab label="About Tatent on Lease">
          <div class="tabbing-body">
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>How do I create an employer account with TalenOnLease?</mat-panel-title>
                </mat-expansion-panel-header>
                <p>Creating an account with TalentOnLease is simple. You can start by posting your first job and authenticate your mobile number using OTP.</p>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>How do I start hiring from TalenOnLease?</mat-panel-title>
                </mat-expansion-panel-header>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab aut tempore consectetur vitae harum, fugit soluta exercitationem inventore earum aliquid! In magnam quis accusantium accusamus architecto quidem, eaque tempore temporibus!</p>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>Can I post a job for free?</mat-panel-title>
                </mat-expansion-panel-header>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab aut tempore consectetur vitae harum, fugit soluta exercitationem inventore earum aliquid! In magnam quis accusantium accusamus architecto quidem, eaque tempore temporibus!</p>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>When will I start receiving Candidate responses?</mat-panel-title>
                </mat-expansion-panel-header>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab aut tempore consectetur vitae harum, fugit soluta exercitationem inventore earum aliquid! In magnam quis accusantium accusamus architecto quidem, eaque tempore temporibus!</p>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>In which cities can I hire via TalenOnLease?</mat-panel-title>
                </mat-expansion-panel-header>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab aut tempore consectetur vitae harum, fugit soluta exercitationem inventore earum aliquid! In magnam quis accusantium accusamus architecto quidem, eaque tempore temporibus!</p>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </mat-tab>
        <mat-tab label="For Clients">
          <div class="tabbing-body">
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>How do I create an employer account with TalenOnLease?</mat-panel-title>
                </mat-expansion-panel-header>
                <p>Creating an account with TalentOnLease is simple. You can start by posting your first job and authenticate your mobile number using OTP.</p>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>How do I start hiring from TalenOnLease?</mat-panel-title>
                </mat-expansion-panel-header>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab aut tempore consectetur vitae harum, fugit soluta exercitationem inventore earum aliquid! In magnam quis accusantium accusamus architecto quidem, eaque tempore temporibus!</p>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>Can I post a job for free?</mat-panel-title>
                </mat-expansion-panel-header>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab aut tempore consectetur vitae harum, fugit soluta exercitationem inventore earum aliquid! In magnam quis accusantium accusamus architecto quidem, eaque tempore temporibus!</p>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>When will I start receiving Candidate responses?</mat-panel-title>
                </mat-expansion-panel-header>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab aut tempore consectetur vitae harum, fugit soluta exercitationem inventore earum aliquid! In magnam quis accusantium accusamus architecto quidem, eaque tempore temporibus!</p>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>In which cities can I hire via TalenOnLease?</mat-panel-title>
                </mat-expansion-panel-header>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab aut tempore consectetur vitae harum, fugit soluta exercitationem inventore earum aliquid! In magnam quis accusantium accusamus architecto quidem, eaque tempore temporibus!</p>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </mat-tab>
        <mat-tab label="For Partners">
          <div class="tabbing-body">
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>How do I create an employer account with TalenOnLease?</mat-panel-title>
                </mat-expansion-panel-header>
                <p>Creating an account with TalentOnLease is simple. You can start by posting your first job and authenticate your mobile number using OTP.</p>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>How do I start hiring from TalenOnLease?</mat-panel-title>
                </mat-expansion-panel-header>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab aut tempore consectetur vitae harum, fugit soluta exercitationem inventore earum aliquid! In magnam quis accusantium accusamus architecto quidem, eaque tempore temporibus!</p>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>Can I post a job for free?</mat-panel-title>
                </mat-expansion-panel-header>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab aut tempore consectetur vitae harum, fugit soluta exercitationem inventore earum aliquid! In magnam quis accusantium accusamus architecto quidem, eaque tempore temporibus!</p>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>When will I start receiving Candidate responses?</mat-panel-title>
                </mat-expansion-panel-header>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab aut tempore consectetur vitae harum, fugit soluta exercitationem inventore earum aliquid! In magnam quis accusantium accusamus architecto quidem, eaque tempore temporibus!</p>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>In which cities can I hire via TalenOnLease?</mat-panel-title>
                </mat-expansion-panel-header>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab aut tempore consectetur vitae harum, fugit soluta exercitationem inventore earum aliquid! In magnam quis accusantium accusamus architecto quidem, eaque tempore temporibus!</p>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</section>
