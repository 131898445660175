import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { BehaviorSubject, Observable, map } from "rxjs";
import { User } from "../_models/user";
import { Router } from "@angular/router";
import { LocalStorageService } from "ngx-webstorage";
@Injectable({ providedIn: 'root' })
export class HomeService {
    private readonly homeEndPoint = 'Home/';
   
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
    
    private readonly UserEndPoint = 'User/';
    constructor(private http: HttpClient, 
      private router: Router) {
      this.currentUserSubject = new BehaviorSubject<User>(new User());
      this.currentUser = this.currentUserSubject.asObservable();
      }

      getUserDetails(){
        return this.http.get<any>(`${environment.apiUrl}${this.UserEndPoint}details`);
      }
      getCandidates(model: any) {
        return this.http.post<any>(`${environment.apiUrl}${this.homeEndPoint}findCandidates`, model)
          .pipe(map(res => {         
            return res;
          }));
      }
      getJobs(model: any) {
        return this.http.post<any>(`${environment.apiUrl}${this.homeEndPoint}findJobs`, model)
          .pipe(map(res => {         
            return res;
          }));
      }

      getBanner(){
        return this.http.get<any>(`${environment.apiUrl}${this.homeEndPoint}banner`);
      }
      sendQuery(model: any) {
        return this.http.post<any>(`${environment.apiUrl}${this.homeEndPoint}SendQuery`, model)
          .pipe(map(res => {         
            return res;
          }));
      }
      
}
