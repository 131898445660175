<section class="service-section">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h3 class="ready-to-see-heading">Ready to see what <span class="dig-recruit-txt">Digi Recruitnet</span> can do for you?
                    </h3>
                </div>
                <div class="col-md-4">
                    <div class="service-item">
                        <div class="d-flex align-items-center">
                            <img src="assets/img/digi/objective.jpg" height="100" />
                            <span class="counter ms-auto">01</span>
                        </div>
                        <p>
                            Objectively assess candidates and employees on any skill with our 3000+ skills library
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="service-item">
                        <div class="d-flex align-items-center">
                            <img src="assets/img/digi/hire.jpg" height="100" />
                            <span class="counter ms-auto">02</span>
                        </div>
                        <p>
                            Hire the best candidates with pre-employment tests calibrated to your organization
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="service-item">
                        <div class="d-flex align-items-center">
                            <img src="assets/img/digi/inteview.jpg" height="100" />
                            <span class="counter ms-auto">03</span>
                        </div>
                        <p>
                            Conduct virtual interviews effortlessly with our Live Interview platform and coding
                            assessment platform
                        </p>
                    </div>
                </div>
            </div>
           
        </div>
    </section>
    <section>
        <div class="container">
            <div class="row text-center">
                <div class="col-md-4">
                    <div class="circle-wrap">
                        <div class="circle">
                            <div class="mask full-1">
                                <div class="fill-1"></div>
                            </div>
                            <div class="mask half">
                                <div class="fill-1"></div>
                            </div>
                            <div class="inside-circle"> 40% </div>
                        </div>
                    </div>
                    <p class="progress-content">Reduction in hiring cost</p>
                </div>
                <div class="col-md-4">
                    <div class="circle-wrap">
                        <div class="circle">
                            <div class="mask full-2">
                                <div class="fill-2"></div>
                            </div>
                            <div class="mask half">
                                <div class="fill-2"></div>
                            </div>
                            <div class="inside-circle"> 65% </div>
                        </div>
                    </div>
                    <p class="progress-content">Reduction in time-to-hire</p>
                </div>
                <div class="col-md-4">
                    <div class="circle-wrap">
                        <div class="circle">
                            <div class="mask full-3">
                                <div class="fill-3"></div>
                            </div>
                            <div class="mask half">
                                <div class="fill-3"></div>
                            </div>
                            <div class="inside-circle"> 75% </div>
                        </div>
                    </div>
                    <p class="progress-content">Increase in interview-to-selection ratio</p>

                </div>
            </div>
        </div>
    </section>
    <section class="brand-section">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h4>
                        Trusted by 500+ organisations worldwide
                    </h4>
                </div>
                <div class="col-12">
                    <div class="brands">
                        <div class="brand-logo">
                            <img src="assets/img/digi/financial-express.png"/>
                        </div>
                        <div class="brand-logo">
                            <img src="assets/img/digi/techgig.png"/>
                        </div>
                        <div class="brand-logo">
                            <img src="assets/img/digi/business-world.png"/>
                        </div>
                        <div class="brand-logo">
                            <img src="assets/img/digi/indian-express.png"/>
                        </div>
                        <div class="brand-logo">
                            <img src="assets/img/digi/express-computer.png"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

