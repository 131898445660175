import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { ContactusService } from 'src/app/_services/contactus.service';
import { RenderService } from 'src/app/_services/render.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

    contactUsForm:any;
    contactflag: boolean = false;
// metaName='IT Staffing and Recruiting Agency - Contact Us';
  metaName='Contact Us | TalentOnLease';
  constructor(private fb: FormBuilder,private meta:Meta, private contactusService: ContactusService,private service: RenderService,
    private toastrService: ToastrService) {

  }

  ngOnInit(): void {
    this.service.addLinkTag('https://talentonlease.com/contact');

    this.meta.addTag({name:'description',content: 'Are you seeking highly skilled IT developers to enhance your team? TalentOnLease offers top tech hiring solutions. Contact us to access the best resources.'});
    this.meta.addTag({name:"keywords",content: 'it staffing and recruiting agency'});
    
    
    this.checkValidation()
  }

  ngOnDestroy(): void {
    this.meta.removeTag('name="description"');
    this.meta.removeTag('name="keywords"');
  }

  checkValidation() {
    this.contactUsForm = this.fb.group({
      name: ["", [Validators.required]],
      email: ["", [Validators.required,Validators.email]],
      mobile: ["", [Validators.required,Validators.minLength(10),Validators.maxLength(10), Validators.pattern('[0-9]*')]],
      designation: [""],
      organization: [""],
      message: [""],
      subject:['Organic Search: ']
    })
  }

  submiContactUs() {
   
    this.contactflag = true
    if (this.contactUsForm.invalid) {
      return;
    }
    this.contactUsForm.patchValue({subject:'Organic Search: '});
    let json = this.contactUsForm.value;
    this.contactusService.submitContactUs(json).subscribe(result => {
      if (result) {
        this.toastrService.success(result.Message);
       setTimeout(() => {
        this.resetform();
       }, 1200);
       
      }
      else {
        this.toastrService.error(result.Message);
      }
    })
    
  }

  resetform() {
    // this.contactUsForm.reset();
    Object.keys(this.contactUsForm.controls).forEach(key => {
      this.contactUsForm.reset()
    this.contactUsForm.get(key).setErrors(null);
    this.contactUsForm.get(key).updateValueAndValidity(); 
    });
    this.contactflag = false
    this.contactUsForm.updateValueAndValidity()
    // this.contactUsForm.updateValueAndValidity(); 
    window.location.reload()
  }
}
