
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ContactusService {
  private readonly homeEndPoint = 'Home/';
  constructor(private http: HttpClient,
    private router: Router) { }

  submitContactUs(data: any) {
    return this.http.post<any>(`${environment.apiUrl}${this.homeEndPoint}ContactUs`, data);
  }
}
