import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { map } from "rxjs";
@Injectable({ providedIn: 'root' })
export class PartnerService {
    private readonly partenerEndPoint = 'PartnerRate/';
    private readonly masterEndPoint = 'Master/';
    constructor(private http: HttpClient) {
      
      }
      getTechnologyrate(model:any) {
        const httpOptions = { params: model }; 
        return this.http.get<any>(`${environment.apiUrl}${this.partenerEndPoint}`,httpOptions)
       }

      deletePartner(id:any){
        return this.http.delete<any>(`${environment.apiUrl}${this.partenerEndPoint}?id=${id}`)
       }

       getExperienceRange( ){
        return this.http.get<any>(`${environment.apiUrl}${this.masterEndPoint}ExpRange`)
       }

         
      save(model: any) {
        return this.http.post<any>(`${environment.apiUrl}${this.partenerEndPoint}`, model)
          .pipe(map(res => { 
            return res;
          }));
      }

      downloadTechnologyRateFormat(){
        return this.http.get(`${environment.apiUrl}${this.partenerEndPoint}downloadTechnologyRateFormat`,{
          responseType: 'arraybuffer'} 
         );

      }

      downloadTechnologyRate(model:any){
       return this.http.get(`${environment.apiUrl}${this.partenerEndPoint}downloadTechnologyRate`,{
          responseType: 'arraybuffer',params:model} 
         );

      }

        editPartner(model:any){
          return this.http.put<any>(`${environment.apiUrl}${this.partenerEndPoint}`, model)
          .pipe(map(res => {         
            return res;
          }));
        }
      UploadTechnologyRate(model:any){ 
      return  this.http.post<any>(`${environment.apiUrl}${this.partenerEndPoint}UploadTechnologyRate`,model)
        .pipe(map(res => {     
          return res;
        }));
      }

    //   downLoadFile(data: any, type: string,fileName:string) {
    //     let blob = new Blob([data], { type: type});
    //     let url = window.URL.createObjectURL(blob);         
    //     let downloadLink = document.createElement('a');
    //     downloadLink.href =url;
    //     downloadLink.setAttribute('download', fileName);
    //     document.body.appendChild(downloadLink);
    //     downloadLink.click();
       
    // }
}
    

