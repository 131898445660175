<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/css/bootstrap.min.css">
<div class="top_section">
    <div class="container">
        <div class="row">
            <div class="overlay">
                <div class="col-md-12">
                    <img src="assets/img/campaign-img/talent-on-lease-logo.png" alt="talentonelease" height="90"
                        width="180">
                </div>
                <div class="col-md-7 col-sm-12" style="float: left;">
                    <div class="contant_part mt-3">
                        <h2 class="mb-4">
                            Upgrade Your Team with TalentOnLease! Find the Best IT Professionals for Your Business
                            Success.

                        </h2>
                        <h3 style="  margin-bottom:20px; color:#fff;" class="lpi">Why Should You Choose TalentOnLease?
                        </h3>

                        <ul class="top_center ssmr">
                            <li>
                                <i class="wgl-icon fa fa-check"></i>Our advanced Al technology excels at matching and
                                filtering candidates to precisely fit your requirements.


                            </li>
                            <li>
                                <i class="wgl-icon fa fa-check"></i>Every professional on our platform is meticulously
                                vetted and skilled through 225+ verified partner ecosystems


                            </li>
                            <li>
                                <i class="wgl-icon fa fa-check"></i>Need talent ASAP? With a pool of 4800+ qualified
                                candidates, our platform offers talent on demand, within 24 to 48 hours.


                            </li>
                            <li>

                                <i class="wgl-icon fa fa-check"></i>From short-term contractors to long-term roles,
                                our platform offers diverse hiring models to suit your specific needs all through a
                                hassle- free experience.


                            </li>











                        </ul>


                    </div>
                </div>
                <div class="col-md-5" style="float: left;">
                    <div class="top_form">
                        <form [formGroup]="contactUsForm" (ngSubmit)="submiContactUs()">
                            <h5>Ready for IT Excellence? Contact TalentOnLease Today
                            </h5>
                            <div class="form_group">
                                <input type="text" name="name" value="" formControlName="name" size="40"
                                    class="form-control" aria-invalid="fase" placeholder="Enter Your Name Here*"
                                    required="">
                                <div *ngIf="contactflag && contactUsForm.controls['name'].errors" class="mt-3">
                                    <div *ngIf="contactUsForm.controls['name'].errors">
                                        <div *ngIf="contactUsForm.controls['name'].errors?.['required']">
                                            <p class="text-danger">Full Name is Required</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="form_group">
                                <input type="text" formControlName="email" name="email" value="" size="40"
                                    class="form-control" aria-invalid="fase" placeholder="Enter Your Email Id"
                                    required="">
                                <div *ngIf="contactflag && contactUsForm.controls['email'].errors" class="mt-3">
                                    <div *ngIf="contactUsForm.controls['email'].errors?.['required']">
                                        <p class="text-danger">Email is Required</p>
                                    </div>
                                    <div *ngIf="contactUsForm.controls['email'].errors?.['email']">
                                        <p class="text-danger">Email is not valid</p>
                                    </div>
                                </div>
                            </div>
                            <div class="form_group">
                                <input type="text" name="number" value="" size="40" class="form-control"
                                    aria-invalid="fase" placeholder="Enter Your Mobile Number*" formControlName="mobile"
                                    minlength="10" maxlength="10" required="">
                                <div *ngIf="contactflag && contactUsForm.controls['mobile'].errors">
                                    <div *ngIf="contactUsForm.controls['mobile'].errors" class="mt-3">
                                        <!-- <p class="text-danger">Mobile Number is Required</p> -->
                                        <div *ngIf="contactUsForm.controls['mobile'].errors?.['required']">
                                            <p class="text-danger">mobile number is Required</p>
                                        </div>
                                        <div *ngIf="contactUsForm.controls['mobile'].errors?.['pattern']">
                                            <p class="text-danger">mobile number should be number only</p>
                                        </div>
                                        <div *ngIf="contactUsForm.controls['mobile'].errors?.['minlength']">
                                            <p class="text-danger">mobile number should be minimum 10 digit</p>
                                        </div>
                                        <div *ngIf="contactUsForm.controls['mobile'].errors?.['maxlength']">
                                            <p class="text-danger">mobile number should be maximum 10 digit</p>
                                        </div>


                                    </div>
                                </div>
                            </div>



                            <div class="form_group">
                                <textarea name="messages" formControlName="message" class="form-control" required=""
                                    aria-invalid="fase" placeholder="Enter Your Message*" style="height:80px;"
                                    data-gramm="fase" wt-ignore-input="true"></textarea>
                            </div>
                            <div class="form_group">
                                <input type="submit" name="submit" value="Submit" class="btn-submit">
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-md-7 col-sm-12">
                    <div class="contant_part">
                        <h2 class="d_part2 d_part2_2">#1 Most Preferred Executive Level Resume Writing Company in 2021
                        </h2>
                        <ul class="top_center d_part2">
                            <li>
                                <i class="wgl-icon fa fa-check"></i>10000+ Happy Clients
                            </li>
                            <li>
                                <i class="wgl-icon fa fa-check"></i>Experienced Writers
                            </li>
                            <li>
                                <i class="wgl-icon fa fa-check"></i>Global Presence
                            </li>
                        </ul>
                        <p class="bottom_content d_part2">Most Preferred By CEO, CIO, CFO, CTO, CIO, CXO's, Presidents,
                            and Board Positions.</p>
                        <div class="benefits_box d_part2">
                            <div class="row">
                                <div class="col-12 col-md-12 col-sm-12 col-lg-6">
                                    <h4>TalentOnLease key highlights</h4>
                                    <ul>
                                        <li><span>✓</span> Core Expertise in Executive Level Resume</li>
                                        <li><span>✓</span> Assured Quality</li>
                                        <li><span>✓</span> Certified Writers</li>
                                        <li><span>✓</span> 4.9 Google Rating</li>
                                        <li><span>✓</span> Created 1200+ Executive Resume in 2021</li>
                                    </ul>
                                </div>
                                <div class="col-12 col-md-12 col-sm-12 col-lg-6">
                                    <img src="assets/img/campaign-img/web_icons.png" alt="web icons" height="225"
                                        width="400">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="program_stats">
            <ul>
                <li>
                    <div class="program_label"><span>690+</span> Verified clients</div>
                </li>
                <li>
                    <div class="program_label"><span>188+</span> Verified Partners </div>
                </li>
                <li>
                    <div class="program_label"><span>23+</span> Job Positions </div>
                </li>
                <li>
                    <div class="program_label"><span>6833+</span>Oualified Candidates </div>
                </li>
            </ul>
        </div>
    </div>
</div>
<div class="customised_section">
    <div class="container">
        <div class="common_heading pkli">
            <h3 style="padding-top:20px;" id="about">About TalentOnLease</h3>
        </div>
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="customised_box">
                    <p class="decor">TalentOnLease is a new age online platform backed by who’s who of IT industry,
                        created by industry veterans with a single motto “Making available Right Resources at Right Time
                        and at Right Cost”.TalentOnLease has access to a wide range of clients across the country who
                        have urgent IT requirements for critical projects on a regular basis.</p>
                    <p class="decor">TalentOnLease works with a wide range of partners (IT Services/Solution firms) to
                        fulfil these requirements. All the IT partners we have on the platform have been on-boarded
                        after careful verification and vetting.</p>
                    <p class="decor">TalentOnLease is aimed at addressing the problem faced by the industry by bringing
                        together Clients and Partners on a single platform to collaborate for mutual gains. The platform
                        here acts as a responsible medium to not just provide means to transact for its tenants but also
                        ensuring the trust building between them with the help of strong policy framework as well as
                        strict audit mechanism.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="download_section">
    <div class="container">
        <div class="outer_download">
            <div class="download_content">
                <h4>Empower Your Team with TalentOnLease</h4>
            </div>
            <div class="download_btn">
                <button type="button" class="download_button" (click)="onContactFrom()">
                    <span class="download_btn_text">Connect Now</span></button>
            </div>
        </div>
    </div>
</div>
<!-- <div class="modal fade download_modal_form" id="form" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="color_bg_outer"></div>
            <div class="modal-header border-bottom-0">
                <h2 class="modal-title" id="exampleModalLabel">Consult Our Resume Writing Expert</h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form action="" method="POST">
                    <div class="form_group">
                        <input type="text" name="namess" value="" size="40" class="form-control" aria-invalid="fase"
                            placeholder="Name*" required>
                    </div>
                    <div class="form_group">
                        <input type="text" name="emailss" value="" size="40" class="form-control" aria-invalid="fase"
                            placeholder="Email Id" required>
                    </div>
                    <div class="form_group">
                        <input type="text" name="numberss" value="" size="40" class="form-control" aria-invalid="fase"
                            placeholder="Mobile Number*" minlength="10" maxlength="10" required>
                    </div>
                    <div class="form_group">
                        <input type="submit" name="subm" value="Submit" class="submit_btn main_btn">
                    </div>
                </form>
            </div>
        </div>
    </div>
</div> -->
<!-- new section  -->

<div class="recent_section" style="margin: 50px 0px 15px !important;">
    <div class="container">
        <div class="common_heading">

            <h3> The Core of Our Tech Capabilities</h3>
            <div class="col-md-12 col-sm-12">
                <div class="customised_box">
                    <p class="decor text-center">Our focus is on making these digital products work really well and
                        ensuring they're safe when stored in the cloud. We're passionate about staying ahead in
                        technology, always looking for new and better ways to build awesome web </p>
                </div>

            </div>
            <div class="row tech-logo">

                <div>
                    <img src="assets/img/campaign-img/2.png" alt="flipkart_logo" width="200" height="auto">

                </div>

                <div>
                    <img src="assets/img/campaign-img/mern.png" alt="amazon" height="auto" width="200">
                </div>
                <div>
                    <img src="assets/img/campaign-img/php.png" alt="byjus_logo" width="200" height="auto">
                </div>
                <div>
                    <img src="assets/img/campaign-img/python.png" alt="flipkart_logo" width="200" height="auto">
                </div>
                <div>
                    <img src="assets/img/campaign-img/java.png" alt="flipkart_logo" width="200" height="auto">
                </div>

                <div>
                    <img src="assets/img/campaign-img/Mean.png" alt="mean" height="auto" width="200">
                </div>


            </div>
            <div class="row tech-logo">

                <div>
                    <img src="assets/img/campaign-img/3.png" alt="flipkart_logo" width="200" height="auto">

                </div>

                <div>
                    <img src="assets/img/campaign-img/4.png" alt="amazon" height="auto" width="200">
                </div>
                <div>
                    <img src="assets/img/campaign-img/5.png" alt="byjus_logo" width="200" height="auto">
                </div>
                <div>
                    <img src="assets/img/campaign-img/6.png" alt="flipkart_logo" width="200" height="auto">
                </div>
                <div>
                    <img src="assets/img/campaign-img/7.png" alt="flipkart_logo" width="200" height="auto">
                </div>

                <div>
                    <img src="assets/img/campaign-img/8.png" alt="mean" height="auto" width="200">
                </div>


            </div>
            <div class="row tech-logo">

                <div>
                    <img src="assets/img/campaign-img/9.png" alt="flipkart_logo" width="200" height="auto">

                </div>

                <div>
                    <img src="assets/img/campaign-img/10.png" alt="amazon" height="auto" width="200">
                </div>
                <div>
                    <img src="assets/img/campaign-img/11.png" alt="byjus_logo" width="200" height="auto">
                </div>
                <div>
                    <img src="assets/img/campaign-img/12.png" alt="flipkart_logo" width="200" height="auto">
                </div>
                <div>
                    <img src="assets/img/campaign-img/aws.png" alt="flipkart_logo" width="200" height="auto">
                </div>

                <div>
                    <img src="assets/img/campaign-img/react.png" alt="mean" height="auto" width="200">
                </div>


            </div>
        </div>

    </div>
</div>
<section class="pb-ksc border-ra5 border-black-ikf">
    <div class="container">
        <div class="row">
            <div class="col-md-6 mx-auto text-center col-dwy col-v9i mb-er7 text-ksl">
                <div class="upp-fio">
                    <h2 class="mb-4" id="how_do_we_work">
                        How We Work
                    </h2>
                </div>
                <div>
                    <p>
                        At TalentOnLease, our process is designed to seamlessly integrate with your business needs,
                        ensuring a smooth and efficient experience. Here's an overview of how we work:
                    </p>
                </div>
            </div>


            <div class="container">
                <div class="row col-dwy my-5">
                    <div class="col-md-3 col-dwy">
                        <div class="d-flex overopacity-hrd">
                            <span class="icon">
                                <img src="assets/img/campaign-img/1.svg" width="30px" alt="Serial Number Icon">
                            </span>
                            <div>
                                <h3 class="text-7ov mb-22s upp-fio">
                                    Define Job Requirements
                                </h3>
                                <p>
                                    Clearly outline the job responsibilities, qualifications, and skills
                                    required for the position. This information will form the basis for your job
                                    description.

                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-dwy">
                        <div class="d-flex hoveropacity-hrd">
                            <span class="icon">
                                <img src="assets/img/campaign-img/2.svg" width="30px" alt="Serial Number Icon">
                            </span>
                            <div>
                                <h3 class="text-7ov mb-22s upp-fio">
                                    Find Pre-Vetted Candidates from pool of TalentonLease

                                </h3>
                                <p>
                                    Develop a compelling job description that includes key details about the
                                    position, responsibilities, qualifications, and any other relevant
                                    information.

                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-dwy">
                        <div class="d-flex hoveropacity-hrd">
                            <span class="icon">
                                <img src="assets/img/campaign-img/3.svg" width="30px" alt="Serial Number Icon">
                            </span>
                            <div>
                                <h3 class="text-7ov mb-22s upp-fio">
                                    Screenings Conduct initial Interviews
                                </h3>
                                <p>
                                    Reach out to shortlisted candidates to conduct preliminary interviews using
                                    phone or videos,to assess candidate's communication skills, cultural fit,and
                                    basic qualifications.


                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-dwy">
                        <div class="d-flex hoveropacity-hrd">
                            <span class="icon">
                                <img src="assets/img/campaign-img/4.svg" width="30px" alt="Serial Number Icon">
                            </span>
                            <div>
                                <h3 class="text-7ov mb-22s upp-fio">
                                    Interview with hiring team
                                </h3>
                                <p>
                                    Organize interviews with key team members or stakeholders to gather multiple
                                    perspectives on the candidate.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-dwy">
                        <div class="d-flex hoveropacity-hrd">
                            <span class="icon">
                                <img src="assets/img/campaign-img/5.svg" width="30px" alt="Serial Number Icon">
                            </span>
                            <div>
                                <h3 class="text-7ov mb-22s upp-fio">
                                    Offer
                                </h3>
                                <p>
                                    Offer to the selected candidate with Immediate start date.

                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-dwy">
                        <div class="d-flex hoveropacity-hrd">
                            <span class="icon">
                                <img src="assets/img/campaign-img/6.svg" width="30px" alt="Serial Number Icon">
                            </span>
                            <div>
                                <h3 class="text-7ov mb-22s upp-fio">
                                    Background Check
                                </h3>
                                <p>
                                    Conduct background checks as necessary to verify the candidate's educational
                                    and professional background.


                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-dwy">
                        <div class="d-flex hoveropacity-hrd">
                            <span class="icon">
                                <img src="assets/img/campaign-img/7.svg" width="30px" alt="Serial Number Icon">
                            </span>
                            <div>
                                <h3 class="text-7ov mb-22s upp-fio">
                                    Onboarding

                                </h3>
                                <p>
                                    Sign Contract with TalentOnLease and welcome the new hire with a
                                    comprehensive onboarding process to ensure a smooth transition into their
                                    role and responsibility.


                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-dwy">
                        <div class="d-flex hoveropacity-hrd">
                            <span class="icon">
                                <img src="assets/img/campaign-img/8.svg" width="30px" alt="Serial Number Icon">
                            </span>
                            <div>
                                <h3 class="text-7ov mb-22s upp-fio">
                                    Feedback and Continuous Improvement
                                </h3>
                                <p>
                                    We gather time to time feedback from Client to check the performance of the
                                    candidate.

                                </p>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</section>

<section style="color: #000; background-color: #f3f2f2;">
    <div class="container py-5">
        <div class="row d-flex justify-content-center">
            <div class="col-md-12 text-center">
                <h2 class="fw-bold mb-4" id="testimonial">Testimonials</h2>
            </div>
        </div>
        <div class="row text-center">
            <div class="col-md-6 mb-4 mb-md-0">
                <div class="card">
                    <div class="card-body py-4 mt-2">
                        <div class="d-flex justify-content-center mb-4">
                            <img src="assets/img/campaign-img/testimonials.png" class="rounded-circle shadow-1-strong"
                                width="100" height="100" />
                        </div>
                        <h5 class="font-weight-bold">Mr. Karan Mehra</h5>
                        <!--<h6 class="font-weight-bold my-3">Founder at ET Company</h6>-->
                        <ul class="list-unstyled d-flex justify-content-center">
                            <li>
                                <i class="fa fa-star fa-sm text-info"></i>
                            </li>
                            <li>
                                <i class="fa fa-star fa-sm text-info"></i>
                            </li>
                            <li>
                                <i class="fa fa-star fa-sm text-info"></i>
                            </li>
                            <li>
                                <i class="fa fa-star fa-sm text-info"></i>
                            </li>
                            <li>
                                <i class="fa fa-star-half-alt fa-sm text-info"></i>
                            </li>
                        </ul>
                        <p class="mb-2">
                            <i class="fa fa-quote-left pe-2"></i> Finding the right IT staff was a daunting task until
                            we connected with TalentOnLease. Their team not only understood our specific needs but also
                            delivered top-notch professionals who seamlessly integrated into our projects. Thanks to
                            TalentOnLease, our tech team is now stronger than ever!

                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 mb-4 mb-md-0">
                <div class="card">
                    <div class="card-body py-4 mt-2">
                        <div class="d-flex justify-content-center mb-4">
                            <img src="assets/img/campaign-img/man.png" class="rounded-circle shadow-1-strong"
                                width="100" height="100" />
                        </div>
                        <h5 class="font-weight-bold">Mr. Manish Munjal</h5>
                        <!--<h6 class="font-weight-bold my-3">Photographer at Studio LA</h6>-->
                        <ul class="list-unstyled d-flex justify-content-center">
                            <li>
                                <i class="fa fa-star fa-sm text-info"></i>
                            </li>
                            <li>
                                <i class="fa fa-star fa-sm text-info"></i>
                            </li>
                            <li>
                                <i class="fa fa-star fa-sm text-info"></i>
                            </li>
                            <li>
                                <i class="fa fa-star fa-sm text-info"></i>
                            </li>
                            <li>
                                <i class="fa fa-star fa-sm text-info"></i>
                            </li>
                        </ul>
                        <p class="mb-2">
                            <i class="fa fa-quote-left pe-2"></i> As a business owner in the ever-evolving tech
                            industry, having a reliable IT team is crucial. TalentOnLease exceeded our expectations by
                            providing skilled professionals with the expertise we needed. Their seamless recruitment
                            process and commitment to client satisfaction make them our go-to agency for IT staffing.

                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 mb-4 mb-md-0">
                <div class="card">
                    <div class="card-body py-4 mt-2">
                        <div class="d-flex justify-content-center mb-4">
                            <img src="assets/img/campaign-img/person.png" class="rounded-circle shadow-1-strong"
                                width="100" height="100" />
                        </div>
                        <h5 class="font-weight-bold">Mr. Shubham Mangalani</h5>
                        <!--<h6 class="font-weight-bold my-3">Front-end Developer in NY</h6>-->
                        <ul class="list-unstyled d-flex justify-content-center">
                            <li>
                                <i class="fa fa-star fa-sm text-info"></i>
                            </li>
                            <li>
                                <i class="fa fa-star fa-sm text-info"></i>
                            </li>
                            <li>
                                <i class="fa fa-star fa-sm text-info"></i>
                            </li>
                            <li>
                                <i class="fa fa-star fa-sm text-info"></i>
                            </li>
                            <li>
                                <i class="fa fa-star fa-sm text-info"></i>
                            </li>
                        </ul>
                        <p class="mb-2">
                            <i class="fa fa-quote-left pe-2"></i> TalentOnLease played a pivotal role in transforming
                            our data management capabilities. Their meticulous approach to understanding our
                            requirements and matching us with highly qualified IT professionals has been impressive. Our
                            business has seen significant improvements in efficiency and innovation since partnering
                            with TalentOnLease.


                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 mb-0">
                <div class="card">
                    <div class="card-body py-4 mt-2">
                        <div class="d-flex justify-content-center mb-4">
                            <img src="assets/img/campaign-img/feedback.jpeg" class="rounded-circle shadow-1-strong"
                                width="100" height="100" />
                        </div>
                        <h5 class="font-weight-bold">Rajeev Bajaj</h5>
                        <!--<h6 class="font-weight-bold my-3">Front-end Developer in NY</h6>-->
                        <ul class="list-unstyled d-flex justify-content-center">
                            <li>
                                <i class="fa fa-star fa-sm text-info"></i>
                            </li>
                            <li>
                                <i class="fa fa-star fa-sm text-info"></i>
                            </li>
                            <li>
                                <i class="fa fa-star fa-sm text-info"></i>
                            </li>
                            <li>
                                <i class="fa fa-star fa-sm text-info"></i>
                            </li>
                            <li>
                                <i class="fa fa-star fa-sm text-info"></i>
                            </li>
                        </ul>
                        <p class="mb-2">
                            <i class="fa fa-quote-left pe-2"></i> In the fat-paced world of technology, having a
                            reliable IT team is crucial.TalentOnLease not only provided us with skilled professionals
                            but also demonstrated a deep understanding of our industry. Their commitment to quality and
                            client satisfaction makes them stand out. Highly recommended.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<div class="faq_section">
    <div class="container">
        <div class="common_heading">
            <h3 id="faq">FAQ</h3>
        </div>
        <div class="faq_info">
            <section class="accordion accordion--radio">
                <div class="tab">
                    <input type="radio" name="accordion-2" id="rd1">
                    <label for="rd1" class="tab__label">How soon can the developer join our team?</label>
                    <div class="tab__content">
                        <p>The procedure takes between two and five days to finish. This
                            time could be shortened with quick contract signing and interview turnaround.
                        </p>
                    </div>
                </div>
                <div class="tab">
                    <input type="radio" name="accordion-2" id="rd2">
                    <label for="rd2" class="tab__label">How can you ensure the candidate will be productive?</label>
                    <div class="tab__content">
                        <p>
                            TalentOnLease is in charge of scheduling and keeping track of employee timesheets.
                            In order to ensure effective engagement, each client has a PMO allocated to them
                            that manages communications between deployed candidates and the client s SPOC.
                        </p>
                    </div>
                </div>

                <div class="tab">
                    <input type="radio" name="accordion-2" id="rd3">
                    <label for="rd3" class="tab__label">Are they Freelancers?</label>
                    <div class="tab__content">
                        <p>
                            Freelancers are not employed by us. In order to ensure seamless
                            compliance, BGV is undertaken for all of the selected candidates. We work in a B2B
                            model and make use of the bench strength of our dependable partners (Software
                            Service Companies).
                        </p>
                    </div>
                </div>
                <div class="tab">
                    <input type="radio" name="accordion-2" id="rd4">
                    <label for="rd4" class="tab__label">Can we hire Candidate on a permanent basis?</label>
                    <div class="tab__content">
                        <p>Yes, you can hire them permanently mostly after 12 months of
                            contact or as per the signed contarct between client and TalentOnLease.
                        </p>
                    </div>
                </div>
                <div class="tab">
                    <input type="radio" name="accordion-2" id="rd5">
                    <label for="rd5" class="tab__label">How do I create an employer account with TalenOnLease?</label>
                    <div class="tab__content">
                        <p>Creating an account with TalentOnLease is simple. You can start
                            by posting your first job and authenticate your mobile number using OTP.
                        </p>
                    </div>
                </div>
            </section>
        </div>

    </div>
</div>

<div class="recent_section">
    <div class="container">
        <div class="common_heading">
            <h3> We Featured In</h3>
        </div>
        <div class="recent_slider">
            <div class="grid-x grid-margin-x slider">
                <owl-carousel-o [options]="owlOption">
                    <ng-template carouselSlide>

                        <div class="cell auto slides">
                            <img src="assets/img/campaign-img/express-computer.png" alt="Tata logo" height="auto"
                                width="200">
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>

                        <div class="cell auto slides">
                            <img src="assets/img/campaign-img/indian-express.png" alt="lenskart_logo" height="auto"
                                width="200">
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="cell auto slides">
                            <img src="assets/img/campaign-img/business-world.png" alt="amazon" height="auto"
                                width="200">
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="cell auto slides">
                            <img src="assets/img/campaign-img/techgig.png" alt="byjus_logo" width="200" height="auto">
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="cell auto slides">
                            <img src="assets/img/campaign-img/financial-express.png" alt="flipkart_logo" width="200"
                                height="auto">
                        </div>
                    </ng-template>
                </owl-carousel-o>

            </div>
        </div>
        <div class="recent_slider">
            <div class="grid-x grid-margin-x slider">
                <owl-carousel-o [options]="owlOption">
                    <ng-template carouselSlide>
                        <div class="cell auto slides">
                            <img src="assets/img/campaign-img/upload.png" alt="Tata logo" height="auto" width="200">
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="cell auto slides">
                            <img src="assets/img/campaign-img/cnbc.png" alt="lenskart_logo" height="auto" width="200">
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="cell auto slides">
                            <img src="assets/img/campaign-img/cxo.png" alt="amazon" height="auto" width="200">
                        </div>
                    </ng-template>
                    <div class="cell auto slides">
                        <img src="assets/img/campaign-img/mint.png" alt="byjus_logo" width="200" height="auto">
                    </div>
                    <ng-template carouselSlide>
                        <div class="cell auto slides">
                            <img src="assets/img/campaign-img/people.png" alt="flipkart_logo" width="200" height="auto">
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</div>
<div class="footer_area">

    <div class="footer_bg_section">

        <div class="svg-shape">

            <svg xmlns="http://www.w3.org/2000/svg" class="light" preserveAspectRatio="none" viewBox="0 0 1070 52">

                <path d="M0,0S247,91,505,32c261.17-59.72,565-13,565-13V0Z"></path>

            </svg>

        </div>

        <div class="container">

            <div class="common_heading">

                <h3 id="contact">Unlock IT Excellence – Partner with Talent on Lease!</h3>

                <p>&nbsp;</p>

            </div>

            <div class="row">

                <div class="col-md-6 col-sm-12">

                    <div class="left_info">

                        <img src="assets/img/campaign-img/talent-on-lease-logo.png" alt="talentonelease" height="90"
                            width="390">

                        <span>For Queries & assistance</span>

                        <h1>TalentOnLease Online Support</h1>





                        <h2><a href="tel:+919899240555">+919899240555</a></h2>

                    </div>

                </div>

                <div class="col-md-6 col-sm-12">

                    <div class="top_form rigth_info" id="contactFrom">

                        <form [formGroup]="frmInq" (ngSubmit)="submit()">


                            <h5>Call Back Request</h5>

                            <div class="form_group">

                                <input type="text" formControlName="name" name="name" value="" size="40"
                                    class="form-control" aria-invalid="fase" placeholder="Enter your name here*"
                                    required>
                                <div *ngIf="contactflag1 && frmInq.controls['email'].errors" class="mt-3">
                                    <div *ngIf="frmInq.controls['email'].errors?.['required']">
                                        <p class="text-danger">Email is Required</p>
                                    </div>
                                    <div *ngIf="frmInq.controls['email'].errors?.['email']">
                                        <p class="text-danger">Email is not valid</p>
                                    </div>
                                </div>
                            </div>

                            <div class="form_group">

                                <input type="text" formControlName="email" name="email" value="" size="40"
                                    class="form-control" aria-invalid="fase" placeholder="Enter your email id*"
                                    required>


                                <div *ngIf="contactflag1 && frmInq.controls['email'].errors" class="mt-3">
                                    <div *ngIf="frmInq.controls['email'].errors?.['required']">
                                        <p class="text-danger">Email is Required</p>
                                    </div>
                                    <div *ngIf="frmInq.controls['email'].errors?.['email']">
                                        <p class="text-danger">Email is not valid</p>
                                    </div>
                                </div>
                            </div>

                            <div class="form_group">

                                <input type="text" formControlName="mobile" name="number" value="" size="40"
                                    class="form-control" aria-invalid="fase" placeholder="Enter your mobile no*"
                                    minlength="10" maxlength="10" required>

                                <div *ngIf="contactflag1 && frmInq.controls['mobile'].errors">
                                    <div *ngIf="frmInq.controls['mobile'].errors" class="mt-3">
                                        <!-- <p class="text-danger">Mobile Number is Required</p> -->
                                        <div *ngIf="frmInq.controls['mobile'].errors?.['required']">
                                            <p class="text-danger">mobile number is Required</p>
                                        </div>
                                        <div *ngIf="frmInq.controls['mobile'].errors?.['pattern']">
                                            <p class="text-danger">mobile number should be number only</p>
                                        </div>
                                        <div *ngIf="frmInq.controls['mobile'].errors?.['minlength']">
                                            <p class="text-danger">mobile number should be minimum 10 digit</p>
                                        </div>
                                        <div *ngIf="frmInq.controls['mobile'].errors?.['maxlength']">
                                            <p class="text-danger">mobile number should be maximum 10 digit</p>
                                        </div>


                                    </div>
                                </div>

                            </div>




                            <div class="form_group">

                                <input type="submit" name="submits" value="Submit" class="submit_btn main_btn">

                            </div>

                        </form>

                    </div>

                </div>

            </div>

        </div>

    </div>

</div>



<div class="whatsapp-button fixed-button"><a href="https://wa.link/ixaedq" target="blank"><i class="fa fa-whatsapp"
            aria-hidden="true"></i></a>

</div>

<div class="callno-button fixed-button"><a href="tel:+919899240555" target="blank"><i class="fa fa-phone"
            aria-hidden="true"></i></a>

</div>