import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { finalize, Observable, tap } from 'rxjs';

import { environment } from "src/environments/environment";
import { LocalStorageService } from 'ngx-webstorage';
import { Router } from '@angular/router';
import { SharedService } from '../_services/shared.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private accountService: LocalStorageService, private router:Router,private shared:SharedService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to the api url
        const user = this.accountService.retrieve("user");
        const isLoggedIn = user && user.Data;
        
        const isApiUrl = request.url.startsWith(environment.apiUrl);
        if (isLoggedIn && isApiUrl) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${user.Data}`
                }
               
            });
            this.shared.showLoader(true);
        }
        // return next.handle(request);
        
    return next.handle(request).pipe( tap(() => {},
    (err: any) => {
        
    if (err instanceof HttpErrorResponse) {
      if (err.status !== 401) {
       return;
      }
      this.accountService.clear();
      this.router.navigate(['/login']);  
    }
  }), finalize(() => {
    setTimeout(() => {
      this.shared.showLoader(false); 
    }, 200);
  }));
    }
}
