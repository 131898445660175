<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/css/bootstrap.min.css">
<div class="container">
    <a _ngcontent-tmo-c151="" routerlink="/" class="navbar-brand" href="/"><img _ngcontent-tmo-c151="" src="assets/img/tol-logo.svg" alt="Logo" class="tollogo"></a>
    <div class="row">
        <div class="col-md-6 d-flex align-items-center mx-auto mt-5">
            <div class="card">
                <div class="card-body thank-you">
                     <i class="fa fa-check"></i>
                    <h1>Thank you</h1>
                    <p>Thanks for contacting with us our team will contact you as soon as possible. Thanks for being you.
                </div>
            </div>
        </div>
    </div>
</div>
