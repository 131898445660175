<div class="modal-sec">
  <header class="mb-4">
    <h2 class="title-sm">Apply Candidate(s)</h2>
    <div class="dialog-close">
      <button mat-dialog-close (click)="CloseDialog()"><img src="assets/img/close.svg" alt=""></button>
    </div>
  </header>
  
  <div class="candidate-name-col mb-4">
    <div class="searchtoolbar d-flex align-items-center cus-scr">
      <div class="form-group">
        <mat-form-field class="full-width" appearance="fill">
          <mat-label>Search here...</mat-label>
          <input matInput [(ngModel)]="candidateFilter.searchText" (keyup)="flterChange()" placeholder="Search Here..." #input>
        </mat-form-field>
      </div>
      <div class="form-group">
          <mat-form-field class="full-width" appearance="fill">
            <mat-label>Search Technology</mat-label>
            <input type="text"
            placeholder="Search"
            aria-label="Search"
            matInput    
      [formControl]="myControl"
            [matAutocomplete]="auto"  >
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
             <mat-option  (click)="jobClickEvent('All')" value="All">All</mat-option>
             <mat-option *ngFor="let option of filteredOptions | async" [value]="option.text"  (click)="jobClickEvent(option)">
               {{option.text}}
             </mat-option>
           </mat-autocomplete>
          </mat-form-field>
      
      </div>
      <div class="form-group">
        <mat-form-field appearance="fill">
          <mat-label>Min Exp.</mat-label>
          <mat-select [(ngModel)]="candidateFilter.minExp">
            <mat-option *ngFor="let exp of experimence" (click)="flterChange()" [value]="exp">{{exp}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field appearance="fill">
          <mat-label>Max Exp.</mat-label>
          <mat-select [(ngModel)]="candidateFilter.maxExp">
            <mat-option *ngFor="let exp of experimence" (click)="flterChange()" [value]="exp">{{exp}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    
    </div>
  </div>
 
  <div class="applicant-summary-sec modal-table">
    <div class="table-responsive">
      <table mat-table [dataSource]="dataSource" class="table">
        <ng-container matColumnDef="select_all">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(element) : null"
                          [checked]="selection.isSelected(element)">
            </mat-checkbox>
          </td>
        </ng-container>
        
        <ng-container matColumnDef="partnerName" *ngIf="positionType!='Permanent'">
          <th mat-header-cell *matHeaderCellDef>Partner Name</th>
          <td mat-cell *matCellDef="let element">{{element.partnerName}}</td>
        </ng-container>
        
        <ng-container matColumnDef="partnerName" *ngIf="positionType=='Permanent'">
          <th mat-header-cell *matHeaderCellDef>Candidate Details</th>
          <td mat-cell *matCellDef="let element">

              <p  class="mt-1 mb-1">
                <b>Candidate Name &nbsp; &nbsp; &nbsp;: </b>
                <span  class="job-title-sec modal-cal">{{element.fullName}}</span>
              </p>
                <p  class="mt-1 mb-1"><b >Partner Name : </b><span > {{element.partnerName}}</span></p>
                <p  class="mt-1 mb-1"><b >Technology : </b><span > {{element.technology}}</span></p>
                <p  class="mt-1 mb-1"><b >Experience : </b><span > {{element.experience}}</span></p>
           </td>
        </ng-container>
        <ng-container matColumnDef="candidate_name" *ngIf="positionType!='Permanent'">
          <th mat-header-cell *matHeaderCellDef>Candidate Name</th>
          <td mat-cell *matCellDef="let element">{{element.fullName}}</td>
        </ng-container>
        <ng-container matColumnDef="designation">
          <th mat-header-cell *matHeaderCellDef>Designation</th>
          <td mat-cell *matCellDef="let element">{{element.designation}}</td>
        </ng-container>
        <ng-container matColumnDef="location">
          <th mat-header-cell *matHeaderCellDef>Location</th>
          <td mat-cell *matCellDef="let element">{{element.city}}</td>
        </ng-container>
        <ng-container matColumnDef="experience" *ngIf="positionType!='Permanent'">
          <th mat-header-cell *matHeaderCellDef>Experience</th>
          <td mat-cell *matCellDef="let element">{{element.experience}}</td>
        </ng-container>
        <ng-container matColumnDef="technology" *ngIf="positionType!='Permanent'">
          <th mat-header-cell *matHeaderCellDef>Technology</th>
          <td mat-cell *matCellDef="let element">{{element.technology}}</td>
        </ng-container>

        <ng-container matColumnDef="current_ctc" *ngIf="positionType=='Permanent'">
          <th mat-header-cell *matHeaderCellDef>Current CTC</th>
          <td mat-cell *matCellDef="let element;let i = index">
            <mat-form-field class="full-width grid-text"  appearance="fill">
              <mat-label>CTC</mat-label>
              <input matInput  placeholder="Current CTC" (focusout)="onChange('ctc',$event,element)"  [value]="element.currentCtc" #input>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="exp_ctc" *ngIf="positionType=='Permanent'">
          <th mat-header-cell *matHeaderCellDef>Exp. CTC</th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field class="full-width grid-text" appearance="fill">
              <mat-label>Exp. CTC</mat-label>
              <input matInput  placeholder="Exp. CTC" (focusout)="onChange('ectc',$event,element)"  [value]="element.expectedCtc" #input>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="notice_period" *ngIf="positionType=='Permanent'">
          <th mat-header-cell *matHeaderCellDef>Notice Period</th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field class="full-width grid-text" appearance="fill">
              <mat-label>Notice Period(days)</mat-label>
              <input matInput  placeholder="Notice Period" (focusout)="onChange('np',$event,element)"  [value]="element.noticePeriod"  #input>
            </mat-form-field>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
     
    </div>
  </div>
  
  <footer>
    <div class="total-record p-0">
      <div class="total-column">
        <div class="record-col">Total Records : {{tableCount}}</div>
      </div>
      <div class="total-pagination ml-auto">
        <mat-paginator #paginator class="demo-paginator" (page)="handlePageEvent($event)" [length]="length"
        [pageSize]="pageSize" [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
        [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize"
        [pageIndex]="pageIndex" aria-label="Select page">
        </mat-paginator>
        <!-- <mat-paginator #paginator [length]="tableCount" [pageSize]="10" [pageSizeOptions]="[5, 10, 100]"></mat-paginator> -->
      </div>
    </div>
    <div class="cta mt-3 text-center">
      <a (click)="addCandidate()" class="btn btn-primary mr-3">Add Candidate</a>
      <button class="btn btn-primary" [disabled]="selection.selected.length==0" (click)="applyJobs()" mat-button>
       <!-- {{role!='executive'?'Apply':'Check Availability'}} -->
       Apply
      </button>
    </div>
  </footer>
</div>
