<mat-dialog-content>
    <div class="top_form" *ngIf="isContactForm">
        <div class="row align-items-center">
            <div class="col-md-5">
                <img src="../../../../assets/img/contact.jpg" class="img-fluid"/>
            </div>
            <div class="col-md-7 contact-form">
                <form [formGroup]="contactUsForm" (ngSubmit)="submiContactUs()">
                    <h5>Contact us</h5>
                    <div class="form_group mt-1">
                        <input type="text" name="name" value="" formControlName="name" size="40"
                            class="form-control" aria-invalid="fase" placeholder="Fullname*"
                            required="">
                        <div *ngIf="contactflag && contactUsForm.controls['name'].errors" class="mt-2">
                            <div *ngIf="contactUsForm.controls['name'].errors">
                                <div *ngIf="contactUsForm.controls['name'].errors?.['required']">
                                    <p class="text-danger">Full Name is Required</p>
                                </div>
        
                            </div>
                        </div>
                    </div>
                    <div class="form_group mt-2">
                        <input type="text" formControlName="email" name="email" value="" size="40"
                            class="form-control" aria-invalid="fase" placeholder="Email*"
                            required="">
                        <div *ngIf="contactflag && contactUsForm.controls['email'].errors" class="mt-2">
                            <div *ngIf="contactUsForm.controls['email'].errors?.['required']">
                                <p class="text-danger">Email is Required</p>
                            </div>
                            <div *ngIf="contactUsForm.controls['email'].errors?.['email']">
                                <p class="text-danger">Email is not valid</p>
                            </div>
                        </div>
                    </div>
                    <div class="form_group mt-2">
                        <input type="text" name="number" value="" size="40" class="form-control"
                            aria-invalid="fase" placeholder="Phone*" formControlName="mobile"
                            minlength="10" maxlength="10" required="">
                        <div *ngIf="contactflag && contactUsForm.controls['mobile'].errors">
                            <div *ngIf="contactUsForm.controls['mobile'].errors" class="mt-2">
                                <!-- <p class="text-danger">Mobile Number is Required</p> -->
                                <div *ngIf="contactUsForm.controls['mobile'].errors?.['required']">
                                    <p class="text-danger">mobile number is Required</p>
                                </div>
                                <div *ngIf="contactUsForm.controls['mobile'].errors?.['pattern']">
                                    <p class="text-danger">mobile number should be number only</p>
                                </div>
                                <div *ngIf="contactUsForm.controls['mobile'].errors?.['minlength']">
                                    <p class="text-danger">mobile number should be minimum 10 digit</p>
                                </div>
                                <div *ngIf="contactUsForm.controls['mobile'].errors?.['maxlength']">
                                    <p class="text-danger">mobile number should be maximum 10 digit</p>
                                </div>
        
                            </div>
                        </div>
                    </div>
                    <div class="form_group mt-2">
                        <input type="text" formControlName="designation" name="designation" value="" size="40"
                            class="form-control" aria-invalid="fase" placeholder="Designation*"
                            required="">
                            <div *ngIf="contactflag && contactUsForm.controls['designation'].errors" class="mt-2">
                                <div *ngIf="contactUsForm.controls['designation'].errors">
                                    <div *ngIf="contactUsForm.controls['designation'].errors?.['required']">
                                        <p class="text-danger">Designation is Required</p>
                                    </div>
            
                                </div>
                            </div>
                    </div>
        
                    <div class="form_group mt-2">
                        <textarea name="messages" formControlName="message" class="form-control" required=""
                            aria-invalid="fase" placeholder="Message" style="height:80px;"
                            data-gramm="fase" wt-ignore-input="true"></textarea>
                    </div>
                    <div class="form_group text-right mt-2 mb-1 d-flex">
                        <input type="submit" name="submit" value="Submit" class="btn-hire w-50 mr-2 mat-raised-button">
                        <button class="mat-raised-button w-50" (click)="closeDialog()">Close</button>
                    </div>
                </form>
            </div>
        </div>
        
    </div>

    <div class="top_form" *ngIf="isConfirm">
        <span style="float: right;
        font-weight: bold;
        background: #b3adad;
        border-radius: 50px;
        padding: 3px;
        width: 32px;
        text-align: center;
        cursor: pointer;"  (click)="closeDialog()">X</span>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/css/bootstrap.min.css">
<div class="container">
    <div class="row">
        <div class="col-md-12 d-flex align-items-center mx-auto mt-5">
            <div class="" style="text-align: center;">
                <div class="card-body thank-you">
                     <i class="fa fa-check" style="background: #25b925;
                     color: white;
                     border-radius: 50%;
                     padding: 12px "></i>
                    <h1>Thank you</h1>
                    <p>Thanks for contacting with TalentOnLease our team will contact you Soon. Thanks.
                </div>
            </div>
        </div>
    </div>
    
</div>

    </div>

</mat-dialog-content>
