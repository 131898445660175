import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
@Injectable({ providedIn: 'root' })
export class MasterService {

  private readonly masterEndPoint = 'Master/';
  constructor(private http: HttpClient, private router: Router) {

  }

  getcities(state: any) {
    return this.http.get<any>(`${environment.apiUrl}${this.masterEndPoint}Cities?stateId=${state}`);
  }


  getState(state: any) {
    return this.http.get<any>(`${environment.apiUrl}${this.masterEndPoint}States?countryId=${state}`);
  }

  getprimarySkills() {
    return this.http.get<any>(`${environment.apiUrl}${this.masterEndPoint}Skills`);
  }

  getMasterList(model: any) {
    const httpOptions = { params: model };
    return this.http.get<any>(`${environment.apiUrl}${this.masterEndPoint}MasterList`, httpOptions);
  }

  SaveUpdateMaster(model: any) {
    const httpOptions = { params: model };
    return this.http.get<any>(`${environment.apiUrl}${this.masterEndPoint}SaveUpdateMaster`, httpOptions);
  }

  getMasterType() {
    return this.http.get<any>(`${environment.apiUrl}${this.masterEndPoint}MasterType`);
  }
  getTechnologies() {
    return this.http.get<any>(`${environment.apiUrl}${this.masterEndPoint}Technologies`);
  }
  getqualifications() {
    return this.http.get<any>(`${environment.apiUrl}${this.masterEndPoint}Qualifications`);
  }

  getJobStatus(type = '') {

    return this.http.get<any>(`${environment.apiUrl}${this.masterEndPoint}JobStatus?type=` + type);
  }

  getIndustries() {
    return this.http.get<any>(`${environment.apiUrl}${this.masterEndPoint}Industries`);
  }

  getCountries() {
    return this.http.get<any>(`${environment.apiUrl}${this.masterEndPoint}Countries`);
  }
  getDeploymentModel() {
    return this.http.get<any>(`${environment.apiUrl}${this.masterEndPoint}DeploymentModel`);
  }

  getEmployeesStrength() {
    return this.http.get<any>(`${environment.apiUrl}${this.masterEndPoint}EmployeesStrength`);
  }

}
