import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/_services/user.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { MustMatch } from 'src/app/_helper/must-match.validator';
import { LocalStorageService } from 'ngx-webstorage';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  signupDisabled=environment.signUpDisabled;
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    nav: false,
    autoplay:true,
    autoplayTimeout:5000,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
  }

  loginForm!: FormGroup;
  loading = false;
  submitted = false;
  error = '';
  isLoggedIn = false;
  role=false;
  cities: any;
  companyUrl:any;
  constructor(private formBuilder: FormBuilder, private router: Router,
    private route: ActivatedRoute,
    private authenticationService: UserService,private toastrService: ToastrService, private sessionService :LocalStorageService) {
     
      if(this.signupDisabled){
        this.router.navigate(['/login']);
        return;
      }
      this.getAllCities();
     }

  ngOnInit(): void {
    this.role=true;
    const userDetail =  this.sessionService.retrieve('user');
    if(userDetail!=null && userDetail.Data !=undefined)
    {
      this.router.navigate(['/home']);
    }
    this.loginForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      userName:['',Validators.required],
      email: ['', [Validators.required,Validators.email]],
      roleId:['4',Validators.required],
      password: ['', Validators.required],
      mobileNo: ['', [Validators.required]],
      confirmPassword: ['',Validators.required],
      company:['',Validators.required],
      linkedinProfile:[''],
      tenantid:[1,Validators.required],
       cityId:['',Validators.required]
     
     },{
     validator: MustMatch('password', 'confirmPassword'),
    });
  }
  
  changeRole(e:any) {
  
    if(e.target.value=="2"){
      this.role=false;
    }
    else{
      this.role=true;
    }
    
  }
  get f() { return this.loginForm.controls; }
  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.submitted = false;
    const login = this.loginForm.value;
  if(login.roleId=="4")
  {
  login.roleId="3";
  }
    this.authenticationService.signUp(login)
    
    .subscribe({
      
      next: (res: any) => {   
        if(res.Data==null){
          this.toastrService.error(res.Message);   
        }
        else{
          this.toastrService.success(res.Message);  
          this.router.navigate(['/home']); 
        }
      
      },
      error: () => {
        this.toastrService.error("user name and password is not correct");
      
      }
    });
     
  }

  getAllCities(){
    this.authenticationService.getcities(0).subscribe(result => {
      if(result && result.Data!=null){
        this.cities = result.Data;  
      }
    })}

    validateNo(e:any): boolean {
      
      const charCode = e.which ? e.which : e.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false
      }
      return true
  }
}


