<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/css/bootstrap.min.css">
<script>
    $(document).ready(function () {
        $("#testimonial-slider").owlCarousel({

            items: 1,

            itemsDesktop: [1000, 1],

            itemsDesktopSmall: [979, 1],

            itemsTablet: [768, 1],

            pagination: true,

            navigation: false,

            navigationText: ["", ""],

            slideSpeed: 1000,

            singleItem: true,

            transitionStyle: "fade",

            autoPlay: true

        });

    });

</script>


<section class="banner-section">
    <img src="../assets/img/fullstackdeveloperimg/home-banner.jpg" class="main-banner" />
    <div class="container">
        <div class="banner-caption">
            <div class="bg-white">
                <h1> Hire Best Angular Developer</h1>
            </div>
            <p>
                Are you looking for the best angular developers? Well your search ends here as we specialise in offering the best and professional angular developers with expertise in creating dynamic scalable and feature rich web applications. Now you can elevate your projects with our skill developers.
                 Hire Angular Developers from TalentOnLease to give a boost to your back-end and front-end technologies.
            </p>
            <div class="row email-box-main">
                <div class="col-md-6 mx-auto">
                    <button mat-raised-button (click)="openDialog()" class="btn mb-15">Hire Angular Developer Now</button>
                </div>
            </div>
            <div class="icon-text">
                <div class="icon-item">
                    <img src="../assets/img/fullstackdeveloperimg/briefcase.png" alt="briefcase" />
                    <p>200 + Client Served</p>
                </div>
                <div class="icon-item">
                    <img src="../assets/img/fullstackdeveloperimg/start-up.png" alt="Start Up" />
                    <p>500 + Angular Project Delivered</p>
                </div>
                <div class="icon-item">
                    <img src="../assets/img/fullstackdeveloperimg/check.png" alt="check" />
                    <p>7 Days Risk Free Trial</p>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="container">
    <header class="gradient-title-head mt-30 mb-30 col-md-12 mx-auto">
        <h2 class="title-lg text-center">Hire Dedicated Angular Developer with Ease</h2>
    </header>
    <ul class="hire-point">
        <li>
            When you hire angular developer from us, you will be a gateway to excellence as we 
            ensure elevated standards in web application development.

        </li>
        <li>
            You can access top tier talent with expertise in angular bringing a wealth of experience
             to improve your development initiatives. 

        </li>
        <li>
            The Angular developers from TalentOnLease provide customised solutions aligning the skills with 
            your project requirements for optimum results
        </li>
        <li>
            You can now integrate our skilled angular developers in your team seamlessly fostering collaboration and project success.
        </li>
        <li>
            Your company can stay ahead at the forefront of technology by hiring our angular developers who bring the best innovations
             to every project.
        </li>
    </ul>
</div>
<section class="why-talentonlease">
    <div class="container position-relative" style="z-index: 1;">
        <div class="row">
            <div class="col-md-4">
                <img class="person-img" src="../../../../assets/img/talent-person.png"/>
            </div>
            <div class="col-md-8">
                <header class="gradient-title-head  mb-0 col-md-8 mx-auto">
                    <h3 class="title-lg text-center">Why <span>TalentOnLease?</span></h3>
                </header>
                <p class="text-center">Great things happen when great people come together. It’s magic!</p>
                <div class="row text-center">
                    <div class="col-md-4">
                        <div class="w-icon">
                            <span><img src="../../../../assets/img/strong-founding.svg"/></span>
                            Active bench of 7241
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="w-icon">
                            <span><img src="../../../../assets/img/vetted-professionals.svg"/></span>
                            Verified and vetted IT professionals
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="w-icon">
                            <span><img src="../../../../assets/img/acquisition-platform.svg"/></span>
                            Available Within 24 to 48 hours
                        </div>
                    </div>
                </div>
                <div class="text-center mt-2">
                <button mat-raised-button (click)="openDialog()" class="btn mb-15">Start Hiring</button>
            </div>
            </div>
        </div>
   
</div>

</section>



<p class="hire-sub-heading mt-30">
    Join 200+ companies who have trusted TalentOnLease for thier remote engineering
    needs.
</p>
<div class="logo-section">
    <div class="slider">
        <div class="logos">
            <img src="{{leadingcompany.logo}}" *ngFor="let leadingcompany of leadingCompanies">
        </div>

    </div>
</div>
<div class="container mt-30">
    <div class="row">
        <p class="flex-center para text-center pad-15">
            Hiring a Angular Developer from TalentOnLease ensures comprehensive expertise in both frontend and backend
            technologies, streamlined communication, and seamless integration, resulting in faster development cycles
            and cost-effective solutions for your project.
        </p>
        <div class="flex-center check flex-wrap">
            <div class="check-item">
                <img src="../assets/img/fullstackdeveloperimg/check-circle.png" alt="check-circle" />
                <p class="para">Fast hiring within 24 to 48 hours</p>
            </div>
            <div class="check-item">
                <img src="../assets/img/fullstackdeveloperimg/check-circle.png" alt="check-circle" />
                <p class="para">Simple & Transparent Pricing</p>
            </div>
            <div class="check-item">
                <img src="../assets/img/fullstackdeveloperimg/check-circle.png" alt="check-circle" />
                <p class="para">Fully Signed NDA & Agreement</p>
            </div>
            <div class="check-item">
                <img src="../assets/img/fullstackdeveloperimg/check-circle.png" alt="check-circle" />
                <p class="para">Regular Reporting & Transparent Communication</p>
            </div>
            <div class="check-item">
                <img src="../assets/img/fullstackdeveloperimg/check-circle.png" alt="check-circle" />
                <p class="para">Easy Exit Policy</p>
            </div>
        </div>
        <div class="hire-btn-section mx-auto">
            <button mat-raised-button (click)="openDialog()" class="btn mb-15">Hire Angular Developer Now</button>
        </div>
    </div>
</div>

<section class="hire-second-section">
    <div class="container-fluid">
        <header class="gradient-title-head mb-30">
            <h3 class="title-lg text-center">Hire <span>Angular Developer</span></h3>
        </header>
        <div class="developer-main">
            <div class="row">
                <div class="col-lg-8">

                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Amit Kumar</h4>
                                        <p class="dev-title">Angular Developer</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>Delhi</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 6 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>
                                    </div>
                                </div>
                                <p class="desc">
                                    Amit is a Angular Developer is a developer or engineer who can build both the
                                    front end and the back end of a website. The front end (the parts of a website a
                                    user sees and interacts with) and the back end (the behind-the-scenes data storage
                                    and processing) require different skill sets.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Arvind Kumar</h4>
                                        <p class="dev-title">Angular Developer</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>Mumbai</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 5 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>
                                    </div>
                                </div>
                                <p class="desc">
                                    Arvind is a Angular Developer is a developer or engineer who can build both the
                                    front end and the back end of a website. The front end (the parts of a website a
                                    user sees and interacts with) and the back end (the behind-the-scenes data storage
                                    and processing) require different skill sets.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Sumit Kumar</h4>
                                        <p class="dev-title">Angular Developer</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>Noida</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 4 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                                    </div>
                                </div>
                                <p class="desc">
                                    Sumit is a Angular Developer is a developer or engineer who can build both the
                                    front end and the back end of a website. The front end (the parts of a website a
                                    user sees and interacts with) and the back end (the behind-the-scenes data storage
                                    and processing) require different skill sets.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 bg-white">
                    <h5 class="title-md trust-title mt-3">Testimonial  </h5>
                    <div class="right-section">
                        <div class="box-shadow trustpilot">
                            <p class="quote-open">
                                <i class="fa fa-quote-left" aria-hidden="true"></i>
                            </p>
                            <p class="trust-desc">
                                We hire about 16 Angular Developers from TalentOnLease which
                                reduced our hiring effort by 90% as compared to
                                other venders.
                            </p>
                            <div class="signature">
                                <div class="sign-icon">
                                    <img src="assets/img/fullstackdeveloperimg/healthcare.png" alt="sign-icon">
                                </div>
                                <p class="sign-desc">
                                    Managing Director MeasurePM, USA based Healthcare company
                                </p>
                            </div>
                        </div>
                        <div class="box-shadow trustpilot">
                            <p class="quote-open">
                                <i class="fa fa-quote-left" aria-hidden="true"></i>
                            </p>
                            <p class="trust-desc">
                                We're super excited about TalentOnLease as we will scrap our existing
                                lengthy interview process and lean on TalentOnLease's vetting to build
                                up teams on demand.
                            </p>
                            <div class="signature">
                                <div class="sign-icon">
                                    <img src="assets/img/fullstackdeveloperimg/piggy-bank.png" alt="sign-icon">
                                </div>
                                <p class="sign-desc">
                                    Director of Engineering Unikove Technologies, Software Service company
                                </p>
                            </div>
                        </div>
                        <div class="contact-form p-4 box-shadow trustpilot pb-0">
                            <form [formGroup]="contactUsForm" (ngSubmit)="submiContactUs()">
                                <h5>Contact us</h5>
                                <div class="form_group mt-4">
                                    <input type="text" name="name" value="" formControlName="name" size="40"
                                        class="form-control" aria-invalid="fase" placeholder="Fullname*"
                                        required="">
                                    <div *ngIf="contactflag && contactUsForm.controls['name'].errors" class="mt-2">
                                        <div *ngIf="contactUsForm.controls['name'].errors">
                                            <div *ngIf="contactUsForm.controls['name'].errors?.['required']">
                                                <p class="text-danger">Full Name is Required</p>
                                            </div>
                    
                                        </div>
                                    </div>
                                </div>
                                <div class="form_group mt-3">
                                    <input type="text" formControlName="email" name="email" value="" size="40"
                                        class="form-control" aria-invalid="fase" placeholder="Email*"
                                        required="">
                                    <div *ngIf="contactflag && contactUsForm.controls['email'].errors" class="mt-2">
                                        <div *ngIf="contactUsForm.controls['email'].errors?.['required']">
                                            <p class="text-danger">Email is Required</p>
                                        </div>
                                        <div *ngIf="contactUsForm.controls['email'].errors?.['email']">
                                            <p class="text-danger">Email is not valid</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="form_group mt-3">
                                    <input type="text" name="number" value="" size="40" class="form-control"
                                        aria-invalid="fase" placeholder="Phone*" formControlName="mobile"
                                        minlength="10" maxlength="10" required="">
                                    <div *ngIf="contactflag && contactUsForm.controls['mobile'].errors">
                                        <div *ngIf="contactUsForm.controls['mobile'].errors" class="mt-2">
                                            <!-- <p class="text-danger">Mobile Number is Required</p> -->
                                            <div *ngIf="contactUsForm.controls['mobile'].errors?.['required']">
                                                <p class="text-danger">mobile number is Required</p>
                                            </div>
                                            <div *ngIf="contactUsForm.controls['mobile'].errors?.['pattern']">
                                                <p class="text-danger">mobile number should be number only</p>
                                            </div>
                                            <div *ngIf="contactUsForm.controls['mobile'].errors?.['minlength']">
                                                <p class="text-danger">mobile number should be minimum 10 digit</p>
                                            </div>
                                            <div *ngIf="contactUsForm.controls['mobile'].errors?.['maxlength']">
                                                <p class="text-danger">mobile number should be maximum 10 digit</p>
                                            </div>
                    
                                        </div>
                                    </div>
                                </div>
                                <div class="form_group mt-3">
                                    <input type="text" formControlName="designation" name="designation" value="" size="40"
                                        class="form-control" aria-invalid="fase" placeholder="Designation*"
                                        required="">
                                        <div *ngIf="contactflag && contactUsForm.controls['designation'].errors" class="mt-2">
                                            <div *ngIf="contactUsForm.controls['designation'].errors">
                                                <div *ngIf="contactUsForm.controls['designation'].errors?.['required']">
                                                    <p class="text-danger">Designation is Required</p>
                                                </div>
                        
                                            </div>
                                        </div>
                                </div>
                    
                                <div class="form_group mt-3">
                                    <textarea name="messages" formControlName="message" class="form-control" required=""
                                        aria-invalid="fase" placeholder="Message" style="height:84px;"
                                        data-gramm="fase" wt-ignore-input="true"></textarea>
                                </div>
                                <div class="form_group text-right mt-4 mb-1 d-flex">
                                    <input type="submit" name="submit" value="Submit" class="btn-hire btn-block mr-2 mat-raised-button">
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
<section class="why-hire-full-stack-section">
    <div class="container">
        <header class="gradient-title-head mb-30">
            <h2 class="title-lg text-center">Why Choose TalentOnLease?</h2>
        </header>
        <p class="text-center">
            Hiring Angular Developers with TalentOnLease is Easy! When you hire a Angular Developer with
            TalentOnLease, it means peace of mind! We follow a transparent hiring process and well-defined processes on
            all our engagements.
        </p>
        <div class="why-full-stack-list">
            <div class="row">
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/thunder.png" /></span>
                        <p>Top-of-the-Line Expertise</p>
                        <p>Our pool of angular developers boast the best expertise excelling in data structure libraries, 
                            state management and functional or reactive programming.</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/efficiency.png" /></span>
                        <p>Comprehensive Understanding</p>
                        <p>With strong fundamentals in web standards and rendering pipelines our developers ensure a thorough grasp of relevant concepts.
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/collaborate.png" /></span>
                        <p>Versatile Development Skills</p>
                        <p>Proficient in designing, developing and deploying enterprise grade apps using TypeScript and JavaScript.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/cost.png" /></span>
                        <p>JavaScript Proficiency</p>
                        <p>When you hire angular developers from TalentOnLease, they demonstrate strong programming skills in 
                            JavaScript like promises closures and they ensure effective and high quality codes.
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/innovation.png" /></span>
                        <p>Optimised Visual Effects</p>
                        <p>We have angular development experts skilled in CSS and JS methods for delivering performance visual
                             effects while maintaining a frame rate above 30 FPS.
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/verified-icon.png" /></span>
                        <p>API Integration Expertise                        </p>
                        <p>The developers bring valuable experience in integrating APIs facilitating seamless communication between 
                            various components of the application.
                        </p>
                    </div>
                </div>
            </div>
            <p class="text-center">Partner with TalentOnLease and harness the power of Angular expertise for your
                digital endeavors. Let
                us drive your project forward
                with efficiency, innovation, and excellence</p>


        </div>
        
    </div>
</section>



<section class="how-hire-devloper-section">
    <div class="hhd-main">
        <div class="hhd-left mb-30 hhd-main-item">
            <div class="gradient-title-head my-5">
                <h2 class="title-lg text-center">How to Hire Quality <span>Angular Developer</span></h2>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">1</div>
                            <div class="hire-div">
                                <p>Define Job Requirements
                                </p>
                                <p>Clearly outline the job responsibilities, qualifications, and skills required for the
                                    position.
                                    This
                                    information will form the basis for your job description</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">2</div>
                            <div class="hire-div">
                                <p>Find Per Vetted
                                    Candidates
                                    from pool of TalentOnLease
                                </p>
                                <p>Develop a compelling job description that includes key details about the position,
                                    responsibilites,
                                    qualifications, and any other relevant information.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">3</div>
                            <div class="hire-div">
                                <p>Resume Screening
                                </p>
                                <p>Review resumes to shortlist candidates who meet the basic qualifications.</p>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="row">
                    
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">4</div>
                            <div class="hire-div">
                                <p>Conduct
                                    Interviews
                                </p>
                                <p>Organize interviews with key team members or stackholders to gather multiple
                                    perspectives on
                                    the
                                    candidate.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">5</div>
                            <div class="hire-div">
                                <p>Offer Rollout
                                </p>
                                <p>Offer to the selected candidate with Immediate start date.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">6</div>
                            <div class="hire-div">
                                <p>Background Check
                                </p>
                                <p> Conduct background checks as necessary to verify the candidate's educational and
                                    professional
                                    background.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">7</div>
                            <div class="hire-div">
                                <p>Onboarding
                                </p>
                                <p> Sign Contract with TalentOnLease and welcome the new hire with a comprehensive
                                    onboarding
                                    process to
                                    ensure
                                    a smooth transition into their role and responsibility.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">8</div>
                            <div class="hire-div">
                                <p>Feedback and Continuous
                                    Improvement
                                </p>
                                <p> We gather time to time feedback from client to check the performance of the
                                    candidate.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>

 <section class="major-key-section container">
    <div class="row">
        <header class="gradient-title-head mb-30 mx-auto">
            <h3 class="title-lg text-center">The Benefits of Hiring an<br> <span>Angular Developer </span></h3>
        </header>
        <p class="mk-desc">
            Owing to years of industry experience and technology exposure, our full stack taskforce is well-competent at
            creating attractive web application and websites for you. They go a step ahead of the rest to unlock
            advantages obtained by full stack development.
        </p>
        <div class="mkb-section">
            <div class="mkb-section-items">
                <p class="heading">Angular Single-page App Development</p>
                <p class="desc">
                    You can rely on our professional developers to seamlessly build efficient and powerful single page applications
                     customised to your expectations.
                </p>
            </div>
            <div class="mkb-section-items">
                <p class="heading">Plug-in Development for angularjs</p>
                <p class="desc">
                    Our experienced programmers specialise in crafting custom plugins perfectly meeting your unique 
                    requirements for better functionality
                </p>
            </div>
            <div class="mkb-section-items">
                <p class="heading">Platform Migration & Re-Engineering
                </p>
                <p class="desc">
                    You trust our remote developers to re engineer and migrate applications to angular ensuring an
                     improved and modernised user experience..
                </p>
            </div>
            <div class="mkb-section-items">
                <p class="heading">API Development Services
                </p>
                <p class="desc">
                    Our Angular API development services are Completely scalable and advanced, 
                    carefully customised to fulfil your specific business requirements
                </p>
            </div>
            <div class="mkb-section-items">
                <p class="heading">Angular Ecosystem Expertise</p>
                <p class="desc">
                    Hire Our developers today as they're equipped in different tools like angular material
                     we can help you add better functionality and features to your applications.
                </p>
            </div>
            <div class="mkb-section-items">
                <p class="heading">Maintenance and Support</p>
                <p class="desc">
                    We have a team of experts to provide you comprehensive support including bug fixes, security updates, technical assistance 
                    troubleshooting and performance optimization for angular applications. 
                </p>
            </div>
        </div>
    </div>
</section> 


<section class="section faqs-page-sec">
    <div class="container">
        <header class="gradient-title-head text-center">
            <small class="small-head">How we can help?</small>
            <h4 class="title-lg">Frequently Asked <span>Questions</span></h4>
        </header>
        <div class="row">
            <div class="col-md-4">
                <img src="assets/img/fullstackdeveloperimg/faq.png" class="img-fluid" />
            </div>
            <div class="col-md-8">
                <div class="tabbing-faq-sec">
                    <div class="tabbing-body">
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>How soon can the developer join our team?</mat-panel-title>
                                </mat-expansion-panel-header>
                                <p>The procedure takes between two and five days to finish. This time could be shortened
                                    with quick contract signing and interview turnaround.
                                </p>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>How can you ensure the candidate will be
                                        productive?</mat-panel-title>
                                </mat-expansion-panel-header>
                                <p>TalentOnLease is in charge of scheduling and keeping track of employee timesheets. In
                                    order to ensure effective engagement, each client has a PMO allocated to them that
                                    manages communications between deployed candidates and the client s SPOC.</p>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>Are they Freelancers?</mat-panel-title>
                                </mat-expansion-panel-header>
                                <p>Freelancers are not employed by us. In order to ensure seamless compliance, BGV is
                                    undertaken for all of the selected candidates. We work in a B2B model and make use
                                    of the bench strength of our dependable partners (Software Service Companies).</p>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>Can we hire Candidate on a permanent basis?</mat-panel-title>
                                </mat-expansion-panel-header>
                                <p>Yes, you can hire them permanently mostly after 12 months of contact or as per the
                                    signed contarct between client and TalentOnLease.</p>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>

<section class="ready-get-started-section">
    <header class="gradient-title-head mb-30">
        <h3 class="title-lg text-center">Ready To Get <span>Started</span></h3>
    </header>
    <p>
        Unlock the full potential of your web applications with our expert Angular developers. Skilled professionals to elevate your projects and deliver 
        seamless user experiences. To hire Angular developer contact our team today! .
         </p>
         <p>
            If you're ready to elevate your projects with our  Angular developers connect with us today.
    </p>
    <button mat-raised-button (click)="openDialog()" class="mat-focus-indicator btn btn-primary mat-button-base mb-15">Contact Us Now</button>
</section>

<div class="whatsapp-button fixed-button"><a href="https://wa.link/ixaedq" target="blank"><i class="fa fa-whatsapp"
    aria-hidden="true"></i></a>

</div>

<div class="callno-button fixed-button"><a href="tel:+919899240555" target="blank"><i class="fa fa-phone"
    aria-hidden="true"></i></a>

</div>
