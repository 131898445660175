import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { BehaviorSubject, Observable, map } from "rxjs";
import { User } from "../_models/user";
import { Router } from "@angular/router";
import { LocalStorageService } from "ngx-webstorage";
import { CandidateService } from "./candidate.service";
@Injectable({ providedIn: 'root' })
export class UserService {

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private currentUserDetailSubject: BehaviorSubject<any>;
  public isUserNotification: BehaviorSubject<any>;

  public currentUserDetail: Observable<any>;

    private readonly AccountEndPoint = 'Account/';
    
    private readonly UserEndPoint = 'User/';
    private readonly UsersEndPoint = 'User';
    private readonly masterEndPoint = 'Master/';
    private readonly roleEndPoint = 'Role/';
    constructor(private http: HttpClient,    private sessionService: LocalStorageService,private candidateService:CandidateService,
      private router: Router) {
      this.currentUserSubject = new BehaviorSubject<any>(new User());
      this.currentUser = this.currentUserSubject.asObservable();
      this.currentUserDetailSubject = new BehaviorSubject<any>("");
      this.isUserNotification = new BehaviorSubject<any>({status:false,data:{}});


      this.currentUserDetail = this.currentUserDetailSubject.asObservable();
      }

   
      UploadProfilePic(model:any, candidate:any){ 
        return  this.http.post<any>(`${environment.apiUrl}${this.AccountEndPoint}UploadProfilePic?userId=${candidate}`,model)
          .pipe(map(res => {  
                   
            return res;
          }));
        }
      login(model: any) {
        return this.http.post<any>(`${environment.apiUrl}${this.AccountEndPoint}login`, model)
          .pipe(map(res => { 
            if(res.Data !=null){
             
              this.currentUserSubject.next(res);  
              
              this.sessionService.store('user', res);
             //this.sessionService.observe(res);  
            //  setTimeout(() => {
            //   this.sessionService.clear();
            // }, 3600000);
            }
           
            return res;
          }));
      }

      loginViaPhone(mobileNo: string) {
        return this.http.get<any>(`${environment.apiUrl}${this.AccountEndPoint}GetOtp/${mobileNo}`);
      }

      getcities(state:any){
        return this.http.get<any>(`${environment.apiUrl}${this.masterEndPoint}Cities?stateId=${state}`);
      }
      getRoles(){
        return this.http.get<any>(`${environment.apiUrl}${this.roleEndPoint}getAll?isInternalRole=false`);
      }
      signUp(model: any) {
        return this.http.post<any>(`${environment.apiUrl}${this.AccountEndPoint}signup`, model)
          .pipe(map(res => {         
            return res;
          }));
      }

      updateUser(model: any) {
        return this.http.post<any>(`${environment.apiUrl}${this.UserEndPoint}updateUser`, model)
          .pipe(map(res => {         
            return res;
          }));
      }

      getprimarySkills(){
        return this.http.get<any>(`${environment.apiUrl}${this.masterEndPoint}Skills`);
      }
      getUserDetails(){
        return this.http.get<any>(`${environment.apiUrl}${this.AccountEndPoint}GetLoggedInUserDetails`).pipe(map(res => {
          if(res.StatusCode!=0){
            this.router.navigate(['/login']);   
          }
          else{
          this.currentUserDetailSubject.next(res);  
          this.sessionService.store('userDetail', res);
          return res;
          }
          // this.sessionService.userSession(res);  
        }));
      }
      getTechnologies(){
        return this.http.get<any>(`${environment.apiUrl}${this.masterEndPoint}Technologies`);
      }

      getJobStatus(){
        return this.http.get<any>(`${environment.apiUrl}${this.masterEndPoint}JobStatus`);
      }

      
      addAdditionalContact(model: any) {
        return this.http.post<any>(`${environment.apiUrl}${this.UserEndPoint}addAdditionalContact`, model)
          .pipe(map(res => {         
            return res;
          }));
      }

      deleteUser(id:any){
        return this.http.delete<any>(`${environment.apiUrl}${this.UsersEndPoint}?Id=${id}`)
       }

      forgetPassword(model: any) {
        return this.http.post<any>(`${environment.apiUrl}${this.AccountEndPoint}ForgotPassword`, model)
          .pipe(map(res => {         
            return res;
          }));
      }
      getDashboardDetails(){
        return this.http.get<any>(`${environment.apiUrl}${this.UserEndPoint}dashboard`);
      }
      getDashboardDetail(model:any){
        const httpOptions = { params: model }; 
        return this.http.get<any>(`${environment.apiUrl}${this.UserEndPoint}dashboard`,httpOptions);
      }
      getallClient(model:any){
        const httpOptions = { params: model }; 
        return this.http.get<any>(`${environment.apiUrl}${this.UserEndPoint}getAllclient`,httpOptions);
      }

      getAllpartner(model:any){
        const httpOptions = { params: model }; 
        return this.http.get<any>(`${environment.apiUrl}${this.UserEndPoint}getAllpartner`,httpOptions);
      }
      getUser(model:any) {
        return this.http.get<any>(`${environment.apiUrl}${this.UserEndPoint}?id=${model}`)
       }

       
      getAllUser(model:any){
        const httpOptions = { params: model }; 
        return this.http.get<any>(`${environment.apiUrl}${this.UserEndPoint}GetAllInternalUser`,httpOptions);
      }

      UserActiveDeactive(Useridinfo:any){
                
        return this.http.get<any>(`${environment.apiUrl}${this.UserEndPoint}UserActiveDeactive?Useractive=${Useridinfo}`);
      }
       
             
      getInternalUser(){
        return this.http.get<any>(`${environment.apiUrl}${this.UserEndPoint}InternalUser`);
      }
      getassignManager(model:any){
        const httpOptions = { params: model }; 
        return this.http.get<any>(`${environment.apiUrl}${this.UserEndPoint}assignManager`,httpOptions);
      }
       updatePartner(model: any) {
        return this.http.post<any>(`${environment.apiUrl}${this.UserEndPoint}updatePartner`, model)
          .pipe(map(res => {         
            return res;
          }));
      }

      
      downloadPartner(model:any){ 
       return this.http.get(`${environment.apiUrl}${this.UserEndPoint}downloadPartner`,{
          responseType: 'arraybuffer',params:model} 
         );
      }
      getAlltenantmaster(){
        return this.http.get<any>(`${environment.apiUrl}${this.roleEndPoint}getAlltenantmaster`);
      }
}
