import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { APIConfig } from 'src/app/_config/api.config';
import { ApiService } from 'src/app/_config/api.service';
import { CandidateService } from 'src/app/_services/candidate.service';

@Component({
  selector: 'app-apply-matched-candidate',
  templateUrl: './apply-matched-candidate.component.html',
  styleUrls: ['./apply-matched-candidate.component.css']
})
export class ApplyMatchedCandidateComponent implements OnInit {

  jobid:any;
  candidateid:any;
  isApplied:any;
  onNoClick=false;
  onYesClick=false;
  constructor( private _route: ActivatedRoute, private router: Router,private api: ApiService, private toastrService: ToastrService,private candidateService: CandidateService) {
    _route.params.subscribe(params => {
      this.jobid = params['job'];
      this.candidateid = params['candidate'];
    });

   }


  ngOnInit(): void {
    this.isApplied = false;
    this._isApplied();
  }

  _applycandidate(){
    const candidateStatus = {
      candidateId: this.candidateid,
      jobId: this.jobid
    }
    this.api._get(APIConfig.ApplyMatchedCandidate, candidateStatus).subscribe(result => {
      if (result.StatusCode != 200) {
      }
      else {
        this.onYesClick=true;
        setTimeout(() => {
          location.href='https://talentonlease.com/';
        }, 3000);
      }
    })
    
  }
  onClickNo(){
    this.onNoClick=true;
    setTimeout(() => {
      location.href='https://talentonlease.com/';
    }, 3000);
  }
  _isApplied(){
    
    const candidateStatus = {
      candidateId: this.candidateid,
      jobId: this.jobid
    }
    this.api._get(APIConfig.isAlreadyApplied, candidateStatus).subscribe(result => {
      if (result.StatusCode == 200) {
        this.isApplied = result.Message == "Applied" ? true : false;
      }
    })
    
  }

}
