
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { ContactusService } from 'src/app/_services/contactus.service';


@Component({
  selector: 'app-contact-dialog',
  templateUrl: './contact-dialog-component.html',
  styleUrls: ['./contact-dialog-component.css']
})
export class ContactDialog implements OnInit {
    isConfirm=false;
    isContactForm = true;
    contactUsForm:any;
    frmInq:any;
    contactflag: boolean = false;
    contactflag1: boolean = false;
    type:any;

    constructor(private fb: FormBuilder, private router: Router, private contactusService: ContactusService, public dialogRef: MatDialogRef<ContactDialog>,@Inject(MAT_DIALOG_DATA) public data:any){
    }
  

  ngOnInit(): void {
    this.contactUsForm = this.fb.group({
      name: ["", [Validators.required]],
      email: ["", [Validators.required,Validators.email]],
      mobile: ["", [Validators.required,Validators.minLength(10),Validators.maxLength(10), Validators.pattern('[0-9]*')]],
      message: [""],
      designation: ["", [Validators.required]],
      organization: [""],
      subject:[''],
    })
  }
  
  submiContactUs() {
    this.contactflag = true
    if (this.contactUsForm.invalid) {
      return;
    }

    
    if(this.data.type=='campaign')
    {
      this.contactUsForm.patchValue({subject:'Google Ad Search: '});
    }
   else
    {
      this.contactUsForm.patchValue({subject:'SEO Search: '});
    }
    let json = this.contactUsForm.value;
    if(this.data.type=='campaign')
    {
      this.contactusService.submitContactUs(json).subscribe(result => {
        this.router.navigate(['/thank-you']);
        if (result) {
         // this.isConfirm=true;
          this.closeDialog();
          //console.log(result)
        }
        else {
          console.log("Something went wrong contact page!")
        }
      })
      
    }
   else
    {
      this.contactusService.submitContactUs(json).subscribe(result => {
        if (result) {
          this.router.navigate(['/thank-you']);
          this.isContactForm = false;
          // this.isConfirm=true;
          this.closeDialog();
          console.log(result)
        }
        else {
          console.log("Something went wrong contact page!")
        }
      })
      
    }


  }

    closeDialog() {
        this.dialogRef.close();
      }
}
