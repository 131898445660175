export class Search {
    pageNumber?:number;
  pageSize?:number;
  partnerId?:string;
  jobId?:string;
  technologyId?: string;
  type?:string;
  minExp?:number;
  maxExp?:number;
  date?:string;
  status?:string;
  cityId?:number;
  appliedStatus?: string;
  masterTypeId?:string;
  clientId?:string;
  fromDate?:string;
  toDate?:string;
  mode?:string;
  priority?:string;
  probability?:string;
  recruiterId?:string;
  searchText?:string;
  publishStatus?:string;
  workNature?:string;
  positionType?:string;
  EngagementType?:number;
  CandidateType?:string;
  fulfilmentType?:string;
  saleUserId?:string;
  createdBy?:string;
  remarks?:string;
}