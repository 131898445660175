import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd  }  from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { onlyBlankSpacenotAllowed } from 'src/app/_helper/blank-space.validator';
import { HomeService } from 'src/app/_services/home.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  sendQuery!: FormGroup;
  submitted: boolean=false;
  timeLeft: number = 10;
  interval:any;


  constructor(private formBuilder: FormBuilder, public router: Router,
    private homeService: HomeService,private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.scrollToTop();
      }
    });
    this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
            return;
        }
        window.scrollTo(0, 0)
    });
    this.sendQuery = this.formBuilder.group({
      body: ['', [Validators.required,onlyBlankSpacenotAllowed]]
    })
  }
  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  get f() { return this.sendQuery.controls; }
  submitMessage(){
  this.submitted=true;
  if (this.sendQuery.invalid) {
    this.startTimer();
    return;

  }
  this.submitted=false;
  this.homeService.sendQuery(this.sendQuery.value).subscribe(result => {
    if(result){
      if(result.StatusCode!=0){
        this.toastrService.error(result.Message); 
     
      }
      else{
        this.toastrService.success(result.Message);
        this.sendQuery.reset();
      }
    
    }
  })
  }

  startTimer() {
    this.timeLeft=10;

      this.interval = setInterval(() => {
        if(this.timeLeft!= 0) {
          this.timeLeft--;
          //this.startTimer();
        }
    
       else if(this.timeLeft==0){
         this.submitted=false;
         this.sendQuery.reset();
        clearInterval(this.interval);
        }
      },1000)
    }
  
}


