export class User {
    firstName?: string;
    lastName?: string;
    userName?: string;
    email?: string;
    mobileNo?: string;
    password?: string;
    confirmPassword?: string;
    company?: string;
    companyUrl?: string;
    roleId?: 0;
    cityId?: 0
}
export class UserDetails{
    role:string;
    id:number;
    firstName?: string;
    lastName?: string;
    userName?: string;
    email?: string;
    mobileNo?: string;
}
