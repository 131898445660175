import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-conference',
  templateUrl: './conference.component.html',
  styleUrls: ['./conference.component.css']
})
export class ConferenceComponent implements OnInit {
  roomId='';
  apiKey='';
  type='';
  constructor(private _activatedroute: ActivatedRoute) { 
  }

  ngOnInit(): void {
    this._activatedroute.params.subscribe(params => {
      
      this.roomId = params['i'];
      this.apiKey = params['r'];
      this.type = params['type'];
      if(this.type=='moderator'){
      let token=btoa(this.roomId +'-'+this.apiKey);
       location.href = 'https://talentonlease.yourvideo.live/host/'+token;
      }else{
        location.href ='https://talentonlease.yourvideo.live/'+this.roomId;
      }
   })
  }

}
