import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { APIConfig } from 'src/app/_config/api.config';
import { ApiService } from 'src/app/_config/api.service';
import { UserService } from 'src/app/_services';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.css'],
})
export class SidemenuComponent implements OnInit {
  dropdownStates: { [key: string]: boolean } = {};
  sideId: any;
  submenus: any=[];
  toggleDropdown(key: string) {
    if (this.dropdownStates[key]) {
      var subfilter = this.submenus.filter((x:any)=>x.parentId==key && x.id==this.sideId);
      if(subfilter !=null){
        this.dropdownStates[key] = true;
      }else
      {
       this.dropdownStates[key] = false; 
      }
      // Close the clicked dropdown
    } else {
      // Close all other open dropdowns
      Object.keys(this.dropdownStates).forEach((dropdownKey) => {
        this.dropdownStates[dropdownKey] = false;
      });

      this.dropdownStates[key] = true; // Open the clicked dropdown
    }
  };
  siteLogo=''
  sideMenu:any=[];
  adminmenu: any=[];
  constructor( private sessionService: LocalStorageService,private userService: UserService, private apiService:ApiService,
    private router:Router,private activateRoute:ActivatedRoute) {
      this.siteLogo=this.activateRoute.snapshot.data['data'].logoUrl;
  
   }
   
  isActive = false;
  isOpen = false;
  userId: any=0;
  toggleClasses(event:any) {
    
    event.linkactive= !event.linkactive;
   // this.isActive = !this.isActive;
   this.sideId = event.id;
    this.isOpen = !this.isOpen;
    this.router.navigate([event.url]);
  }
  
  ngOnInit(): void {
    const userDetail = this.sessionService.retrieve('userDetail');
    if (userDetail != null) {
      this.sideMenu=userDetail.Data?.navigation;
      this.adminmenu= this.sideMenu.filter((x:any)=>x.parentId==0 || x.parentId==null);
      this.submenus =this.sideMenu.filter((x:any)=>x.parentId!=0 || x.parentId!=null);
  
      this.adminmenu.forEach((item:any) => {
        item['submenus']=[];
        if(this.submenus.length>0 ){
        this.submenus.forEach((submenu:any) => {
          if(submenu.parentId == item.id){
            item.submenus.push(submenu);
           }
          });
        }
        });
    }
    else {
      this.router.navigate(['/login']);
    }
  }

  logOut(){
    const userDetail = this.sessionService.retrieve('userDetail');
    this.apiService._get(APIConfig.logout,{userId:1}).subscribe(()=>{});
    this.sessionService.clear();
    this.router.navigate(['/home']);
  }
}
