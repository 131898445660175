import { Injectable } from "@angular/core";
import * as signalR from '@microsoft/signalr';
import { environment } from "src/environments/environment";
import { APIConfig } from "../_config/api.config";
import { BehaviorSubject, Subject } from "rxjs";
@Injectable({ providedIn: 'root' })
export class NotificationHub {
  public dashboardNotificationListner: Subject<any>= new Subject<any>();
  public _notificationListner: Subject<any>= new Subject<any>();
  public notificationData: Subject<any>= new Subject<any>();
  hubUrl: string;
  hubConnection: any;
  constructor() {
    this.hubUrl = environment.notificationPath;
  }
  public startConnection() {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(this.hubUrl, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
      })
      .build();
    this.hubConnection
      .start()
      .then(() => { this.addListner(); })
      .catch(err => console.log('Error while starting connection: ' + err))
  }
  public addListner() {
    this.hubConnection.on('SendNotification', (notification: any) => {
      
      switch (notification.type) {
        case 'CandidateApplied':
          this.dashboardNotificationListner.next(true);
          break;

          case 'CandidateAppliedByPartner':
            this.dashboardNotificationListner.next(true);
            break;
            case 'Matching':
              this._notificationListner.next({type:notification.type,jobId:notification.item});
              break;
              case 'Matched':
                this._notificationListner.next({type:notification.type,jobId:notification.item});
                break;
      
        default:
          break;
      }
    });
  }
}