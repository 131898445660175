import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, from, of } from 'rxjs';
import { AdminService } from 'src/app/_services/admin.service';
import { ApiService } from '../_config/api.service';
import { APIConfig } from '../_config/api.config';
import { SharedService } from './shared.service';

@Injectable({
    providedIn: 'root'
})
export class TenantResolver implements Resolve<Observable<any>> {
    constructor(private _appService: ApiService,private shared:SharedService) {
        this.shared.showLoader(true);

    }
    data: [];
    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const data: Promise<any> =this._appService._get(APIConfig.GetTenantByHostName,{hostName:location.hostname}).toPromise().then((res: any) => {
            this.data = res.Data;
            setTimeout(() => {
                this.shared.showLoader(false);
            }, 500);
            return this.data;
        });
        return from(data);
    }
}

