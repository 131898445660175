import { Injectable, Renderer2, Inject, ElementRef } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Meta } from '@angular/platform-browser';
@Injectable({ providedIn: 'root' })
export class RenderService {

  constructor(private meta:Meta, private renderer: Renderer2,private el: ElementRef, @Inject(DOCUMENT) private document: any
   ) {

  }

addLinkTag(url){
  const link: HTMLLinkElement = document.createElement('link');
  link.setAttribute('rel', 'canonical');
  link.setAttribute('href', url);
  this.el.nativeElement.ownerDocument.head.appendChild(link);
}


  // addMetaTag(name: string, content: string): void {
  //   this.meta.addTag({ name: name,content: content });
  //   // const metaTag: HTMLMetaElement = this.renderer.createElement('meta');
  //   // this.renderer.setAttribute(metaTag, 'name', name);
  //   // this.renderer.setAttribute(metaTag, 'content', content);
  //   // Append the meta tag to the head of the document
  //  // this.renderer.appendChild(this.document.head, metaTag);
  // }
  // addScriptTag(content: string): void {
  //   const script: HTMLScriptElement = this.renderer.createElement('script');
  //   this.renderer.setAttribute(script, 'content', content);
  //   // Append the meta tag to the head of the document
  //   this.renderer.appendChild(this.document.head, script);
  // }
  // addContentBody(content: string): void {
  //   const body: HTMLScriptElement = this.renderer.createElement('body');
  //   this.renderer.setAttribute(body, 'content', content);
  //   // Append the meta tag to the head of the document
  //   this.renderer.appendChild(this.document.body, body);
  // }
  // removeMetaTag(metaName: string): void {
  //   this.meta.removeTag('name="'+metaName+'"');
  //   // const metaTag = this.document.head.querySelector(`meta[name="${name}"]`);
  //   // if (metaTag) {
  //   //   this.renderer.removeChild(this.document.head, metaTag);
  //   // }
  // }

}
