import { ViewportScroller } from "@angular/common";
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ToastrService } from 'ngx-toastr';
import { ContactusService } from 'src/app/_services/contactus.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.css', './slider.css']
})
export class CampaignComponent implements OnInit {
  contactUsForm:any;
  frmInq:any;
  contactflag: boolean = false;
  contactflag1: boolean = false;
  constructor(private fb: FormBuilder, private contactusService: ContactusService,
    private toastrService: ToastrService, private router: Router, private scroller: ViewportScroller,) { }

  ngOnInit(): void {
    this.contactUsForm = this.fb.group({
      name: ["", [Validators.required]],
      email: ["", [Validators.required,Validators.email]],
      mobile: ["", [Validators.required,Validators.minLength(10),Validators.maxLength(10), Validators.pattern('[0-9]*')]],
      message: [""],
      designation: [""],
      organization: [""],
      subject:['Google Ad Search:']

    })

    this.frmInq = this.fb.group({
      name: ["", [Validators.required]],
      email: ["", [Validators.required,Validators.email]],
      mobile: ["", [Validators.required,Validators.minLength(10),Validators.maxLength(10), Validators.pattern('[0-9]*')]],
      message: [""],
      designation: [""],
      organization: [""],
      subject:['Google Ad Search:']
    })
  }
  
  submiContactUs() {
   
    this.contactflag = true
    if (this.contactUsForm.invalid) {
      return;
    }
    this.contactUsForm.patchValue({subject:'Google Ad Search: '});

    let json = this.contactUsForm.value;
    

    this.contactusService.submitContactUs(json).subscribe(result => {
      this.router.navigate(['/thank-you']);
      if (result) {
        //this.toastrService.success(result.Message);
        // setTimeout(() => {
        //   this.resetform();
        //  }, 1000);
      }
      else {
       // this.toastrService.error(result.Message);
      }
    })
    
  }

  
  submit() {
   
    this.contactflag1 = true
    if (this.frmInq.invalid) {
      return;
    }
    this.frmInq.patchValue({subject:'Google Ad Search: '});

    let json = this.frmInq.value;

    this.contactusService.submitContactUs(json).subscribe(result => {
      this.router.navigate(['/thank-you']);
      if (result) {
       // this.toastrService.success(result.Message);
        // this.router.navigate(['/campaign-thankyou']);
      }
      else {
        //this.toastrService.error(result.Message);
      }
    })
    
  }

  resetform() {
    // this.contactUsForm.reset();
    Object.keys(this.contactUsForm.controls).forEach(key => {
      this.contactUsForm.reset()
    this.contactUsForm.get(key).setErrors(null);
    this.contactUsForm.get(key).updateValueAndValidity(); 
    });
    this.contactflag = false
    this.contactUsForm.updateValueAndValidity()
    // this.contactUsForm.updateValueAndValidity(); 
    window.location.reload()
  }

  
  owlOption: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    nav: false,
    autoplay:true,
    autoplayTimeout:5000,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 2
      },
      940: {
        items: 3
      }
    },
  }
  onContactFrom(){
    this.scroller.scrollToAnchor("contactFrom");
  }
}
