<section class="sectionabout aboutusbgimg">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-12 text-center">
                <!-- <header class="d-flex align-items-center">
                    <h1 class="title-lg m-0 d-flex align-items-center">
                        About Us
                    </h1>
                </header> -->
                <header>
                    <h1>
                        About Us
                    </h1>
                </header>

                <p class="paraadjust">
                    <b>TalentOnLease</b> is a new age online platform backed by who’s who of IT industry, created by
                    industry
                    veterans with a single motto “Making available <b>Right Resources at Right Time</b> and at <b>Right
                        Cost”</b>.
                    TalentOnLease has access to a wide range of clients across the country who have urgent IT
                    requirements for critical projects on a regular basis. TalentOnLease works with a wide range of
                    partners (IT Services/Solution firms) to fulfil these requirements. All the IT partners we have on
                    the platform have been on-boarded after careful verification and vetting.
                </p>
                <p class="paraadjust">
                    TalentOnLease is aimed at addressing the problem faced by the industry by bringing together Clients
                    and Partners on a single platform to collaborate for mutual gains. The platform here acts as a
                    responsible medium to not just provide means to transact for its tenants but also ensuring the trust
                    building between them with the help of strong policy framework as well as strict audit mechanism.
                </p>
            </div>
        </div>
    </div>

</section>

<section class="section">
    <div class="container">
        <!--row -->
        <div class="row">
            <div class="col-12 col-md-12">
                <header>
                    <h2 class="text-center">
                        Our Founders
                    </h2>
                </header>
            </div>
        </div>
        <!-- close row -->
        <!--row -->
        <div class="row">
            <div class="col-12 col-md-3 text-center pb-4 aboutsidepic">
                <div>
                    <img src="assets/img/about-us/daya-prakash.png" class="img-fluid adjustimg">
                </div>
                <header>
                    <h3 class="title-md text-center">
                        Daya Prakash
                    </h3>
                    <h3 class="text-center mb-2">Founder</h3>
                    <div class="socailicondiv">
                        <a class="socialicons" href="https://www.linkedin.com/in/dayaprakash/" target="_blank"><i class="fa fa-facebook"></i></a>
                    </div>
                </header>
            </div>
            <div class="col-12 col-md-9">
                <p class="paraadjust">
                    I have been in the enterprise and digital technology domains for a little over two decades and have
                    vast experience at a global level.
                </p>
                <p class="paraadjust">
                    Before starting my entrepreneurial journey as Founder www.TalentOnLease.com which is into providing
                    “IT Talent On Demand”, I served LG India as the CIO (Chief Information Officer).
                </p>
                <p class="paraadjust">
                    My stint at LG between 2001 and 2013 helped me transform from a mere IT Leader to an evolved and
                    matured Business Leader with in-depth knowledge on various aspects of Management of Business,
                    leveraging the power of IT both at strategic and tactical levels.
                </p>
                <p class="paraadjust">
                    My contribution at LG not only fetched me recognition internally but also in the industry by way of
                    winning several prestigious awards and accolades for the path-breaking work I’d done over the years.
                </p>
                <p class="paraadjust">
                    The credit for this exceptional growth solely goes to LG which grew from a few millions to a
                    whopping 3.5 Billion USD during my tenure with it.
                </p>
                <p class="paraadjust">
                    I feel privileged to be part of the Thought Leadership of the country as my views and articles got
                    published in all the leading magazines and national dailies such as CIO, Economic Times, Financial
                    Express, Data Quest, Network Computing, Express Computer and many more.
                </p>
                <p class="paraadjust">
                    I have been part of several prestigious national and international summits and conferences as expert
                    panelist/speaker.
                </p>
            </div>
        </div>
        <!-- close row -->

        <!--row -->
        <div class="row mt-5">
            <div class="col-12 col-md-3 text-center pb-4 aboutsidepic">
                <div>
                    <img src="assets/img/about-us/pervesh-dhingra.png" class="img-fluid adjustimg">
                </div>
                <header>
                    <h3 class="title-md text-center">
                        Pervesh Dhingra
                    </h3>
                    <h3 class="text-center mb-2">Co-Founder</h3>
                    <div class="socailicondiv">
                        <a class="socialicons" href="https://www.linkedin.com/in/perveshdhingra/"
                            target="_blank"><i class="fa fa-facebook"></i></a>
                    </div>
                </header>
            </div>
            <div class="col-12 col-md-9">
                <p class="paraadjust">
                    16+ years of experience in different domains from Cloud technologies Azure/AWS, Land Records,
                    Hospitality, Banking etc.
                </p>
                <p class="paraadjust">
                    Significant experience in Solution Design architecting and implementing large scale applications.
                    I have been playing role of a SharePoint/NET Application Architect or Lead Consultant for various
                    projects based Microsoft technologies across different customers.
                </p>
                <p class="paraadjust">
                    I utilize my knowledge and domain expertise to address the next generation requirement of IT
                    solution and business consulting. Wearing the board member’s cap in RemielSoft my contribution apart
                    from managing the IT deliverables is also to handle the entire gamut of business operation. Ever
                    since my joining, I closely monitored and nurtured a team of talented professional and delivered
                    custom solution for fortune 1000 companies like Microsoft, HCL, LG, Apollo Munich, Baxter, Perfetti,
                    Jetsave and international clients such as Open Solution Inc. (CT, USA), eye4Talent (Denmark),
                    Trident (Washington, USA), and Attunix (Washington, USA) and mane more.
                    During my previous engagement, I have gained expertise in:
                </p>
                <p class="paraadjust">
                    Implementing end to end Web/Windows based application systems using ASP.NET /MVC/ C#/ VB.NET/ MS
                    Share point portal server 2001/2003/2007/2010/2013/2016, Office 365, MS SQL Server 2000/2005 or
                    later ,SQL Server Reporting Service (SSRS).
                </p>
                <p class="paraadjust">
                    While I enjoy all aspects of my job, I think my favorite stage of a project is working with the
                    client or business unit leader to understand his or her business objectives. As we go through that
                    collaborative process, the ideas start to flow and that’s always the fun part.
                </p>

            </div>
        </div>
        <!-- close row -->

        <!--row -->
        <div class="row mt-5">
            <div class="col-12 col-md-3 text-center pb-4 aboutsidepic">
                <div>
                    <img src="assets/img/about-us/sanjeev-matta.png" class="img-fluid adjustimg">
                </div>
                <header>
                    <h3 class="title-md text-center">
                        Sanjeev Matta
                    </h3>
                    <h3 class="text-center mb-2">Founder</h3>
                    <div class="socailicondiv">
                        <a class="socialicons" href="https://www.linkedin.com/in/sanjeevmatta/" target="_blank"><i class="fa fa-facebook"></i></a>
                    </div>
                </header>
            </div>
            <div class="col-12 col-md-9">
                <p class="paraadjust">
                    A dynamic professional with 27 years of rich IT Industry experience in Application Outsourcing,
                    Client Management, Global Service Delivery, Leadership and Program Management.
                </p>
                <p class="paraadjust">
                    Proven track record in building high performance delivery teams from Zero, transitioning of accounts
                    to offshore from US, APAC and EMEA; formulating strategic/tactical business strategies and managing
                    complex IT operations – proven excellence in onshore/offshore global practices.
                </p>
                <p class="paraadjust">
                    Extensive track record of stakeholder management, developing and maintaining executive level
                    relationships based on innovative, cost effective solutions that achieve true business value.
                </p>
                <p class="paraadjust">
                    Head of Software Development, having built, managed and delivered multi-service, multi-million
                    dollar engagements and programs across global locations, practices, cultures and platform using
                    multiple technologies, for global organizations.
                </p>
                <p class="paraadjust">
                    Record of success in building the programs and engagements, with an ability to recognize business
                    objectives, foresee the solutions, develop approaches, estimate resource and complete them on time
                    and within budget.
                </p>
                <p class="paraadjust">
                    High customer orientation and ability to work effectively with multiple stakeholders, demanding and
                    contradictory requirements and apt at building relationships with Client including leadership &
                    delegations – experience of working with customers/stakeholders from countries like USA, UK,
                    Australia and New Zealand.
                </p>
                <p class="paraadjust">
                    Experience of working in Project and Product based organizations.
                </p>

            </div>
        </div>
        <!-- close row -->

    </div>
</section>

