import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { APIConfig } from 'src/app/_config/api.config';
import { ApiService } from 'src/app/_config/api.service';
import { NotificationHub } from 'src/app/_services/notification.hub.service';

@Component({
  selector: 'app-adminheader',
  templateUrl: './adminheader.component.html',
  styleUrls: ['./adminheader.component.css']
})
export class AdminheaderComponent implements OnInit {
  userDetail: any;
  userName: any;
notifications:any=[];
notificationCount=0;
allUserNotification:any=[];
  constructor(private sessionService: LocalStorageService,
    private _notificationHub:NotificationHub,
    private router:Router,private apiService:ApiService) { }

  ngOnInit(): void {
    this._getNotifications();
    this.userDetail = this.sessionService.retrieve('userDetail');
    this.userName=this.userDetail.Data?.userDetails?.firstName+' '+this.userDetail.Data?.userDetails?.lastName ;
    this._notificationHub.dashboardNotificationListner.subscribe(x=>{
      
      if(x==true){
        this._getNotifications();
      }
    })
  }
  logOut(){
    const userDetail = this.sessionService.retrieve('userDetail');
    this.apiService._get(APIConfig.logout,{userId:userDetail.Data.userDetails.id}).subscribe(()=>{});
    this.sessionService.clear();
    this.router.navigate(['/home']);
  }
  profileClick() {
    const role = this.userDetail.Data?.roles;
    let currentRole = role[0].code.toLowerCase();
    let route = '/' + currentRole + '/my-profile';
    this.router.navigate(['/my-profile']);
  }
  _getNotifications(){
    this.apiService._get(APIConfig.userNotification,{}).subscribe(x=>{
      if(x.data.length>0){
        this._notificationHub.notificationData.next(x.data);
      }else{
        this._notificationHub.notificationData.next([]);
      }
      
      this.notificationCount = x.data.length;
      this.allUserNotification = x.data;
    })
  }

  readNotification(notification){
    document.getElementById('btnNotification').click();
    notification.isRead=!notification.isRead;
    this.apiService._get(APIConfig.readNotification,{notificationId:notification.id}).subscribe(x=>{
     
      this._getNotifications();
    })
  }
  readAllNotification(){
    document.getElementById('btnNotification').click();
    let notiId=this.allUserNotification.map(x=>x.id).toString();
    this.apiService._post(APIConfig.readAllNotification,{notificationIds:notiId}).subscribe(x=>{
      
      this._getNotifications();
    })
  }

   time_ago(time) {

    switch (typeof time) {
      case 'number':
        break;
      case 'string':
        time = +new Date(time);
        break;
      case 'object':
        if (time.constructor === Date) time = time.getTime();
        break;
      default:
        time = +new Date();
    }
    var time_formats = [
      [60, 'seconds', 1], // 60
      [120, '1 minute ago', '1 minute from now'], // 60*2
      [3600, 'minutes', 60], // 60*60, 60
      [7200, '1 hour ago', '1 hour from now'], // 60*60*2
      [86400, 'hours', 3600], // 60*60*24, 60*60
      [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
      [604800, 'days', 86400], // 60*60*24*7, 60*60*24
      [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
      [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
      [4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
      [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
      [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
      [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
      [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
      [58060800000, 'centuries', 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
    ];
    var seconds = (+new Date() - time) / 1000,
      token = 'ago',
      list_choice = 1;
  
    if (seconds == 0) {
      return 'Just now'
    }
    if (seconds < 0) {
      seconds = Math.abs(seconds);
      token = 'from now';
      list_choice = 2;
    }
    var i = 0,
      format;
    while (format = time_formats[i++])
      if (seconds < format[0]) {
        if (typeof format[2] == 'string')
          return format[list_choice];
        else
          return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
      }
    return time;
  }
}
